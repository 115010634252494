import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocalePlugin from 'dayjs/plugin/updateLocale';
import { IntlShape } from 'react-intl';

export const init = (intl: IntlShape) => {
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocalePlugin);
  // Using universal time units in the relative plugin (eg. time since, time from etc.)
  dayjs.updateLocale('en', {
    relativeTime: {
      future: intl.formatMessage({
        id: 'DayJS.Relative.Future',
        defaultMessage: 'in %s',
      }),
      past: intl.formatMessage({
        id: 'DayJS.Relative.Past',
        defaultMessage: 'seit %s',
      }),
      s: intl.formatMessage({
        id: 'DayJS.Relative.SmallS',
        defaultMessage: 'vor < 1 Minute',
      }),
      m: intl.formatMessage({
        id: 'DayJS.Relative.SmallM',
        defaultMessage: 'vor einer Minute',
      }),
      mm: intl.formatMessage({
        id: 'DayJS.Relative.SmallMM',
        defaultMessage: 'vor %d Minuten',
      }),
      h: intl.formatMessage({
        id: 'DayJS.Relative.SmallH',
        defaultMessage: 'vor einer Stunde',
      }),
      hh: intl.formatMessage({
        id: 'DayJS.Relative.SmallHH',
        defaultMessage: 'vor %d Stunden',
      }),
      d: intl.formatMessage({
        id: 'DayJS.Relative.SmallD',
        defaultMessage: 'vor einem Tag',
      }),
      dd: intl.formatMessage({
        id: 'DayJS.Relative.SmallDD',
        defaultMessage: 'vor %d Tagen',
      }),
      M: intl.formatMessage({
        id: 'DayJS.Relative.CapitalM',
        defaultMessage: 'vor einem Monat',
      }),
      MM: intl.formatMessage({
        id: 'DayJS.Relative.CapitalMM',
        defaultMessage: 'vor %d Monaten',
      }),
      y: intl.formatMessage({
        id: 'DayJS.Relative.SmallY',
        defaultMessage: 'vor einem Jahr',
      }),
      yy: intl.formatMessage({
        id: 'DayJS.Relative.SmallYY',
        defaultMessage: 'vor %d Jahren',
      }),
    },
  });
};

// Convert date from YYYY-MM-DD to DD.MM.YYYY format
export const dateConverter = (value?: string) => {
  if (!!value) {
    const [y, m, d] = value.split('-');
    return d.concat('.', m, '.', y);
  }
};

// Functions for validating dates (restrict to 99 years into future or past)
export const pastDateValidator = (value: string | undefined) => {
  if (!value) return false;
  const [y, m, d] = value.split('-');
  const date = new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
  return (
    !Number.isNaN(date.getTime()) &&
    date.getDate() === Number(d) &&
    date.getMonth() === Number(m) - 1 &&
    date.getFullYear() >= dayjs().year() - 99 &&
    (date.getFullYear() < dayjs().year() ||
      (date.getFullYear() === dayjs().year() && dayjs(value).diff(dayjs()) < 0))
  );
};

export const getTimeElapsed = (origin: Date) => {
  return dayjs(origin).fromNow(true);
};
