import React, { forwardRef, useContext } from 'react';
import styled from 'styled-components';

import theme from 'styles/theme';

import HorizontalSpacer from 'atoms/HorizontalSpacer';
import Circle from 'atoms/Circle';

import IsMobileContext from 'contexts/IsMobileContext';

type StatusBarWrapperProps = {
  gridArea?: any;
  color?: any;
  ref: any;
  isMobile: boolean;
};

const StatusBarWrapper = styled.div<StatusBarWrapperProps>`
  grid-area: ${(p) => p.gridArea || ''};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${(p) => p.theme.sizes.extraSmall};
  background-color: ${(p) => p.color || p.theme.colors.bambusBlue10};
  border-radius: ${({ theme }) => theme.borderRadii.small};

  & span {
    color: ${(p) => p.theme.colors.bambusBlue100};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.text};
  }
`;

type StatusBarProps = {
  color?: string | null;
  text?: string;
  icon?: React.ReactNode | null;
  gridArea?: string | null;
};

/**
 * Renders a Status Bar with a white circle and text in a variable background color wrapper.
 */
const StatusBar = forwardRef(
  ({ color = null, text = '', gridArea = null }: StatusBarProps, ref) => {
    const isMobile = useContext(IsMobileContext);
    return (
      <StatusBarWrapper
        isMobile={isMobile}
        ref={ref}
        color={color}
        gridArea={gridArea}
      >
        <Circle color={theme.colors.actionBlue} />
        <HorizontalSpacer space={theme.sizes.extraSmall} />
        <span>{text}</span>
      </StatusBarWrapper>
    );
  }
);

export default StatusBar;
