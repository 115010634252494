import styled from 'styled-components';

const TextArea = styled.textarea`
  padding: ${({ theme }) => theme.sizes.extraSmall};
  border: 1px solid ${({ theme }) => theme.colors.bambusBlue25};
  border-radius: ${({ theme }) => theme.borderRadii.small};
  outline: none;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.bambusBlue100};

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.bambusBlue75};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.bambusBlue50};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.bambusBlue10};
    color: ${({ theme }) => theme.colors.bambusBlue50};
  }
`;

export default TextArea;
