import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Check, Repeat as OrderIcon } from 'react-feather';
import theme from 'styles/theme';
import { Button } from 'bambus-ui-components';
import Mobile from 'atoms/Mobile';

import LoadingIndicator from 'molecules/LoadingIndicator';

export type PDFDocumentViewerProps = {
  url: string;
  onMobileReorderPagesIntention?: () => void;
  onMobileReviewClose?: () => void;
};

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  /* Setting a z-index to create a new stacking context */
  z-index: 0;
`;

const ActionsButtonHolder = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
  user-select: none;
`;

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

/**
 * PDF Document Visualizer (uses Mozilla's PDF.JS).
 * The height and width are equal to that of the container
 * (the parent element this component is placed into).
 * @param props.url The URL of the PDF document
 * @param props.onMobileReviewClose Callback for when the viewer is closed. Only applies to Mobile.
 */
const PDFDocumentViewer = ({
  url,
  onMobileReviewClose,
  onMobileReorderPagesIntention,
  ...rest
}: PDFDocumentViewerProps) => {
  const iFrameRef = useRef<any>();
  const pdfJSMutationObserver = useRef<MutationObserver | null>(null);
  const [isPDFLoading, setIsPDFLoading] = useState(true);

  /**
   * Callback that runs when the <iframe> is loaded (NOT when the PDF is ready).
   *
   * The reason for this setup is for tracking when the PDF is still loading.
   * For this we are tracking the <body> element of the PDF.js viewer via
   * a MutationObserver. When the PDF is still loading in PDF.js, the <body>
   * in the <iframe> has a className of "loadingInProgress".
   *
   * Based on this we set the local component state that shows/hides
   * the loading indicator.
   */
  const onPDFJSLoad = useCallback(() => {
    const iFrame = iFrameRef.current;
    if (iFrame) {
      const iFrameContent = iFrame.contentDocument
        ? iFrame.contentDocument
        : iFrame.contentWindow?.document;
      const pdfjsBody = iFrameContent.body;

      const observer = new MutationObserver((mutations) => {
        if (
          mutations.find(
            (mutationRecord) => mutationRecord.attributeName === 'class'
          )
        ) {
          if (
            [...pdfjsBody.classList].find(
              (className) => className === 'loadingInProgress'
            )
          ) {
            setIsPDFLoading(true);
          } else {
            setIsPDFLoading(false);
          }
        }
      });
      pdfJSMutationObserver.current = observer;
      // Only tracking the attribute (like "class") changes
      observer.observe(pdfjsBody, {
        subtree: false,
        childList: false,
        attributes: true,
      });
    }
  }, []);

  /**
   * At this component's unmount we disconnect the MutationObserver.
   */
  useEffect(() => {
    return () => {
      pdfJSMutationObserver.current?.disconnect();
    };
  }, []);

  return (
    <MainWrapper>
      <StyledIFrame
        ref={iFrameRef}
        title="PDF"
        src={`/pdfjs-2.5.207-es5-dist/web/viewer.html?file=${encodeURIComponent(
          url
        )}&locale=de-DE`}
        onLoad={onPDFJSLoad}
        {...rest}
      ></StyledIFrame>
      <Mobile>
        <ActionsButtonHolder>
          <Button
            muted
            icon={<OrderIcon />}
            // @ts-ignore
            style={{
              minWidth: '80vw',
            }}
            onClick={onMobileReorderPagesIntention}
          >
            <FormattedMessage
              id="Pages.Document.Review.ReorderPages"
              defaultMessage="Seiten anordnen"
            />
          </Button>
          <Button
            onClick={onMobileReviewClose}
            icon={<Check />}
            normal
            // @ts-ignore
            style={{
              backgroundColor: theme.colors.green,
              minWidth: '80vw',
              marginLeft: 0,
              marginTop: theme.sizes.extraSmall,
            }}
          >
            <FormattedMessage id="Confirm" defaultMessage="Bestätigen" />
          </Button>
        </ActionsButtonHolder>
      </Mobile>
      {isPDFLoading && <LoadingIndicator />}
    </MainWrapper>
  );
};

export default PDFDocumentViewer;
