import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Download, Activity as ActivityIcon } from 'react-feather';
import styled, { css } from 'styled-components';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Button, ValidationMessage } from 'bambus-ui-components';
import IsMobileContext from 'contexts/IsMobileContext';

import theme from 'styles/theme';

import VerticalSpacer from 'atoms/VerticalSpacer';
import Paragraph from 'atoms/Paragraph';

import UploadTool from 'organisms/UploadTool';

import wait from 'utils/WaitAsPromised';

import { AlreadyUploadedFile } from 'models/Document';

import routes from 'routes';

import {
  sendLandedOnUploadStepAnalytics,
  sendDownloadedDocumentToSignAnalytics,
  sendCancelDocumentUploadProcessAnalytics,
  sendWaitingAfterConcatenationReadyAnalytics,
  sendConcatenationReadyAnalytics,
  sendConcatenationStartedAnalytics,
  sendRestartDocumentUploadProcessAnalytics,
} from 'analytics/DocumentAnalytics';

import {
  requestConcatenation,
  getConcatenationStatus,
  notifyFileForSigningDownloaded,
} from 'api/requests';

const UploadScreenWrapper = styled.div<any>`
  ${({ isMobile }) => !isMobile && 'min-width: 42rem;'}
`;

const ButtonsHolderMobileStyles = css`
  ${({ theme }) => `
    grid-template-columns: 1fr;
    grid-template-rows: auto ${theme.sizes.mini} auto;
    grid-template-areas: 
      'upload-start-button upload-start-button upload-start-button'
      '. . .'
      'upload-cancel-button upload-cancel-button upload-cancel-button';
`}
`;

const ButtonsHolderDesktopStyles = css`
  ${({ theme }) => `
    grid-template-columns: auto ${theme.sizes.small} auto 1fr;
    grid-template-areas: 
      'upload-start-button . upload-cancel-button .';
`}
`;

const ActionButtonsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ButtonsHolder = styled.div<any>`
  display: grid;

  ${({ isMobile }) =>
    isMobile ? ButtonsHolderMobileStyles : ButtonsHolderDesktopStyles};
`;

enum UploadStatus {
  NOT_STARTED,
  IN_PROGRESS,
  DONE,
  CONCATENATION_ERROR,
  UPLOAD_ERROR,
}

const UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS = 150;

const getButtonText = (uploadStatus: UploadStatus, intl: IntlShape) => {
  switch (uploadStatus) {
    case UploadStatus.NOT_STARTED:
      return intl.formatMessage({
        id: 'Pages.Document.Upload.StartUpload',
        defaultMessage: 'Dateien hochladen',
      });
    case UploadStatus.IN_PROGRESS:
      return intl.formatMessage({
        id: 'Pages.Document.Upload.StartUpload',
        defaultMessage: 'Dateien hochladen',
      });
    case UploadStatus.UPLOAD_ERROR:
      return intl.formatMessage({
        id: 'Pages.Document.Upload.StartUpload',
        defaultMessage: 'Dateien hochladen',
      });
    case UploadStatus.CONCATENATION_ERROR:
      return intl.formatMessage({
        id: 'Pages.Document.Upload.StartUpload',
        defaultMessage: 'Dateien hochladen',
      });
    case UploadStatus.DONE:
      return intl.formatMessage({
        id: 'Pages.Document.Upload.OrderDocument',
        defaultMessage: 'Dokumente Ordnen',
      });
  }
};

export type UploadScreenProps = {
  documentId: string;
  onFinishUpload: () => void;
  downloadableDocumentURLs?: string[];
  initialFiles?: AlreadyUploadedFile[];
  onFileListChange?: (newNumberOfFiles: number) => any;
  onRestartDocumentUploadIntention: () => void;
  onUploadOrConcatenationError: () => any;
};

const Upload = React.memo(
  ({
    documentId,
    downloadableDocumentURLs = [],
    onFinishUpload,
    onFileListChange: onFileListChangeProp,
    initialFiles = [],
    onUploadOrConcatenationError,
    onRestartDocumentUploadIntention,
    history,
  }: RouteComponentProps & UploadScreenProps) => {
    const isMobile = useContext(IsMobileContext);

    const [isUploadPossible, setIsUploadPossible] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
      UploadStatus.NOT_STARTED
    );
    const [failedToUploadFileNames, setFailedToUploadFileNames] = useState<
      string[]
    >([]);

    const uploadTrigger = useRef<() => Promise<any>>();

    const intl: IntlShape = useIntl();

    useEffect(() => {
      if (documentId) {
        sendLandedOnUploadStepAnalytics({ documentId });
      }
    }, [documentId]);

    useEffect(() => {
      setIsUploadPossible(initialFiles.length > 0);
    }, [initialFiles]);

    const onFileListChange = (numberOfFiles: number) => {
      setIsUploadPossible(numberOfFiles > 0);
      onFileListChangeProp && onFileListChangeProp(numberOfFiles);
    };

    const setUploadTrigger = useCallback((uploadTriggerToSet) => {
      uploadTrigger.current = uploadTriggerToSet;
    }, []);

    const onStartUpload = useCallback(async () => {
      const triggerUpload = uploadTrigger.current;
      if (triggerUpload) {
        setUploadStatus(UploadStatus.IN_PROGRESS);
        try {
          await triggerUpload();
          /**
           * Requesting concatenation and waiting for it to be ready (polling)
           */
          sendConcatenationStartedAnalytics({ documentId });
          await requestConcatenation({ documentId });
          let numberOfWaitCycles: number = 0;
          let inbetweenAnalytics: any = {};
          do {
            //Waiting before checking for concatenation status
            await wait(UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS);
            numberOfWaitCycles++;
            const { status } = await getConcatenationStatus({
              documentId,
            });
            if (status === 'done') {
              sendConcatenationReadyAnalytics({
                documentId,
                cyclesWaited: numberOfWaitCycles,
                durationOfWaitingCycleMs:
                  UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS,
              });
              break;
            } else {
              if (status === 'error') {
                setUploadStatus(UploadStatus.CONCATENATION_ERROR);
                onUploadOrConcatenationError();
              }
            }

            /**
             * Sending wait analytics at: 30s, 60s, 2mins, 5mins, 10mins of wait time
             */
            if (
              !inbetweenAnalytics['30000'] &&
              numberOfWaitCycles *
                UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS >=
                30000
            ) {
              //waited more than 30s
              sendWaitingAfterConcatenationReadyAnalytics({
                documentId,
                cyclesWaited: numberOfWaitCycles,
                durationOfWaitingCycleMs:
                  UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS,
              });
              inbetweenAnalytics['30000'] = true;
            }
            if (
              !inbetweenAnalytics['60000'] &&
              numberOfWaitCycles *
                UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS >=
                60000
            ) {
              //waited more than 60s
              sendWaitingAfterConcatenationReadyAnalytics({
                documentId,
                cyclesWaited: numberOfWaitCycles,
                durationOfWaitingCycleMs:
                  UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS,
              });
              inbetweenAnalytics['60000'] = true;
            }
            if (
              !inbetweenAnalytics['120000'] &&
              numberOfWaitCycles *
                UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS >=
                120000
            ) {
              //waited more than 2 mins
              sendWaitingAfterConcatenationReadyAnalytics({
                documentId,
                cyclesWaited: numberOfWaitCycles,
                durationOfWaitingCycleMs:
                  UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS,
              });
              inbetweenAnalytics['120000'] = true;
            }
            if (
              !inbetweenAnalytics['300000'] &&
              numberOfWaitCycles *
                UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS >=
                300000
            ) {
              //waited more than 5 mins
              sendWaitingAfterConcatenationReadyAnalytics({
                documentId,
                cyclesWaited: numberOfWaitCycles,
                durationOfWaitingCycleMs:
                  UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS,
              });
              inbetweenAnalytics['300000'] = true;
            }
            if (
              !inbetweenAnalytics['600000'] &&
              numberOfWaitCycles *
                UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS >=
                600000
            ) {
              //waited more than 10 mins
              sendWaitingAfterConcatenationReadyAnalytics({
                documentId,
                cyclesWaited: numberOfWaitCycles,
                durationOfWaitingCycleMs:
                  UPLOAD_CONCATENATION_DONE_PERIODIC_CHECK_INTERVAL_MS,
              });
              inbetweenAnalytics['600000'] = true;
            }
          } while (true);
          setUploadStatus(UploadStatus.DONE);
          onFinishUpload();
        } catch (error: any) {
          if (error.networkError) {
            setUploadStatus(UploadStatus.UPLOAD_ERROR);
            setFailedToUploadFileNames(error.failedToUploadFileNames);
            onUploadOrConcatenationError();
          } else {
            onUploadOrConcatenationError();
            setUploadStatus(UploadStatus.CONCATENATION_ERROR);
          }
        }
      }
    }, [onFinishUpload, documentId, onUploadOrConcatenationError]);

    const onDownloadDocumentToSignPress = useCallback(() => {
      sendDownloadedDocumentToSignAnalytics({ documentId });
      notifyFileForSigningDownloaded({ documentId });
    }, [documentId]);

    const onCancelUploadPress = useCallback(() => {
      sendCancelDocumentUploadProcessAnalytics({ documentId, step: 'upload' });
      history.push(routes.documentsRoute);
    }, [documentId, history]);

    const onRestartDocumentUpload = useCallback(() => {
      sendRestartDocumentUploadProcessAnalytics({
        documentId,
        step: 'review',
      });
      onRestartDocumentUploadIntention();
    }, [documentId, onRestartDocumentUploadIntention]);

    return (
      <UploadScreenWrapper isMobile={isMobile}>
        {downloadableDocumentURLs.length > 0 && (
          <>
            {downloadableDocumentURLs.map((url) => (
              <React.Fragment key={url}>
                <Button
                  as="a"
                  // @ts-ignore
                  href={url}
                  target="_blank"
                  rel="noreferrer noopener"
                  muted
                  icon={<Download />}
                  style={{
                    width: isMobile ? '100%' : 'auto',
                  }}
                  onClick={onDownloadDocumentToSignPress}
                >
                  <FormattedMessage
                    id="DownloadDocument"
                    defaultMessage="Dokument herunterladen"
                  />
                </Button>
                <VerticalSpacer space={theme.sizes.medium} />
              </React.Fragment>
            ))}
            <Paragraph
              color={theme.colors.bambusBlue100}
              size={3}
              lineHeight={theme.lineHeights.longParagraph}
            >
              <FormattedMessage
                id="Pages.Document.Upload.Instructions"
                defaultMessage="Laden Sie die Erklärung herunter, unterschreiben diese und stellen uns einen Scan oder Foto bereit."
              />
            </Paragraph>
            <VerticalSpacer space={theme.sizes.large} />
          </>
        )}
        <ActionButtonsHolder>
          <Button
            outline
            icon={<ActivityIcon />}
            // @ts-ignore
            style={{
              width: isMobile ? '100%' : 'auto',
              marginLeft: 0,
            }}
            onClick={onRestartDocumentUpload}
          >
            <FormattedMessage
              id="ResetUpload"
              defaultMessage="Zurücksetzen"
            />
          </Button>
        </ActionButtonsHolder>
        <VerticalSpacer space={theme.sizes.medium} />
        <UploadTool
          documentId={documentId}
          onFileListChange={onFileListChange}
          uploadTriggerSetter={setUploadTrigger}
          alreadyUploadedFiles={initialFiles}
          showLoadingIndicator={uploadStatus === UploadStatus.IN_PROGRESS}
        />
        <VerticalSpacer space={theme.sizes.large} />
        {uploadStatus === UploadStatus.CONCATENATION_ERROR && (
          <>
            <ValidationMessage hasError style={{ display: 'block' }}>
              <FormattedMessage
                id="Pages.Document.Upload.SomethingWentWrongPart1"
                defaultMessage="Bei der Verarbeitung dieser Datei ist ein Fehler aufgetreten. Bitte prüfen Sie zunächst, ob Sie die Datei auf Ihrem eigenen Gerät öffnen können."
              />
            </ValidationMessage>
            <VerticalSpacer space={theme.sizes.mini} />
            <ValidationMessage hasError style={{ display: 'block' }}>
              <FormattedMessage
                id="Pages.Document.Upload.SomethingWentWrongPart2"
                defaultMessage="Hinweis: Dies passiert normalerweise bei einer passwortgeschützten Datei oder einem nicht unterstützten Dateiformat."
              />
            </ValidationMessage>
            <VerticalSpacer space={theme.sizes.small} />
          </>
        )}
        {uploadStatus === UploadStatus.UPLOAD_ERROR && (
          <>
            <ValidationMessage hasError style={{ display: 'block' }}>
              <FormattedMessage
                id="Pages.Document.Upload.UploadErrorPart1"
                defaultMessage="Es gab ein Problem mit der Datei {fileName}."
                values={{
                  fileName: failedToUploadFileNames.join(', '),
                }}
              />
            </ValidationMessage>
            <VerticalSpacer space={theme.sizes.mini} />
            <ValidationMessage hasError style={{ display: 'block' }}>
              <FormattedMessage
                id="Pages.Document.Upload.UploadErrorPart2"
                defaultMessage="Bitte versuchen Sie den Upload erneut oder verwenden Sie eine andere Datei."
              />
            </ValidationMessage>
            <VerticalSpacer space={theme.sizes.small} />
          </>
        )}
        <ButtonsHolder isMobile={isMobile}>
          <Button
            main
            // @ts-ignore
            style={{
              width: isMobile ? '100%' : 'auto',
              gridArea: 'upload-start-button',
            }}
            disabled={
              !isUploadPossible || uploadStatus === UploadStatus.IN_PROGRESS
            }
            onClick={
              uploadStatus === UploadStatus.DONE
                ? onFinishUpload
                : onStartUpload
            }
          >
            {getButtonText(uploadStatus, intl)}
          </Button>
          <Button
            basic
            // @ts-ignore
            style={{
              color: theme.colors.bambusBlue50,
              gridArea: 'upload-cancel-button',
            }}
            onClick={onCancelUploadPress}
          >
            <FormattedMessage id="Cancel" defaultMessage="Abbrechen" />
          </Button>
        </ButtonsHolder>
      </UploadScreenWrapper>
    );
  }
);

export default withRouter(Upload);
