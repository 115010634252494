import React from 'react';
import styled from 'styled-components';

const Space = styled.div<any>`
  width: ${(p) => p.space};
`;

const HorizontalSpacer = ({ space = '1em' }) => {
  return <Space space={space}></Space>;
};

export default HorizontalSpacer;
