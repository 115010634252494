import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import UppyCore from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import styled from 'styled-components';
import { Upload as UploadIcon, X, Image, File } from 'react-feather';
import { useIntl, IntlShape, FormattedMessage } from 'react-intl';
import { Button, Tooltip } from 'bambus-ui-components';
import ReactTooltip from 'react-tooltip';
import UploadedFile from 'organisms/UploadTool/UploadedFile';
import { useUpdateEffect } from 'react-use';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';

import IsMobileContext from 'contexts/IsMobileContext';

import VerticalSpacer from 'atoms/VerticalSpacer';
import FeatherIconHolder from 'atoms/FeatherIconHolder';
import Heading from 'atoms/Heading';

import LoadingIndicator from 'molecules/LoadingIndicator';

import theme from 'styles/theme';

import { AlreadyUploadedFile } from 'models/Document';

import {
  deleteDocumentFile,
  getDocumentFilePresignedUploadURL,
} from 'api/requests';

import {
  sendOnBeforeUploadFilesAnalytics,
  sendOnAfterUploadFilesAnalytics,
  sendFileDeletedAnalytics,
} from 'analytics/DocumentAnalytics';

// TODO: Fix Uppy Types

const EDIT_PICTURE_INSRUCTIONS_TOOLTIP_ID = 'EditPictureTooltip';
const KEY_WAS_EDIT_PICTURE_TOOLTIP_AUTOMATICALLY_SHOWN_IN_CURRENT_SESSION =
  'WasEditPictureTooltipShownInCurrentSession';

const mapUppyFilesToSizeAndType = (
  files: any
): {
  type: string;
  size: number;
}[] => {
  return files.map(({ meta, size }: any) => ({ type: meta.type, size: size }));
};

const mapUppyFilesToNameAndSizeAndTypeAndFailureReason = (
  files: any
): {
  type: string;
  size: number;
  failureReason: string;
  fileName: string;
}[] => {
  return files.map(({ meta, size, error, name }: any) => ({
    type: meta.type,
    size,
    fileName: name,
    failureReason: error,
  }));
};

const getFileIconBasedOnType = (type: string): React.ReactNode => {
  if (type.includes('image')) {
    return <Image />;
  }
  if (type.includes('pdf')) {
    return <File />;
  }
  return null;
};

const CustomMainScreen = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  user-select: none;
`;

const UppyWrapper = styled.div<any>`
  position: relative;
  /* Setting a z-index to create a new stacking context */
  z-index: 0;

  /* Overriding the styles of Uppy so it matches our design language */
  .uppy-Root {
    font-family: inherit;
  }

  .uppy-Dashboard-inner {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px dashed ${({ theme }) => theme.colors.bambusBlue50};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .uppy-Dashboard-AddFiles {
    margin: 0;
    border: none;
  }

  .uppy-DashboardContent-title {
    color: ${({ theme }) => theme.colors.bambusBlue100};
  }

  .uppy-DashboardContent-bar {
    background-color: ${({ theme }) => theme.colors.white};
  }

  .uppy-DashboardContent-back,
  .uppy-DashboardContent-addMore {
    color: ${({ theme }) => theme.colors.bambusBlue50};
  }

  .uppy-Dashboard-Item-action {
    color: ${({ theme }) => theme.colors.actionBlue};
  }

  .uppy-Dashboard-Item-name {
    color: ${({ theme }) => theme.colors.bambusBlue100};
  }

  .uppy-Dashboard-Item-status {
    color: ${({ theme }) => theme.colors.bambusBlue50};
  }

  .uppy-Dashboard-AddFiles-title {
    display: ${({ isCustomTitleHidden }) =>
      isCustomTitleHidden ? 'block' : 'none'};
  }

  .uppy-Dashboard-AddFiles-info {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    padding-top: 30px;
    padding-bottom: 0;
    .uppy-Dashboard-note {
      color: ${({ theme }) => theme.colors.bambusBlue100};
    }
  }

  .uppy-Dashboard-dropFilesHereHint {
    background-color: ${({ theme }) => theme.colors.white};
  }

  .uppy-StatusBar-content {
    color: ${({ theme }) => theme.colors.bambusBlue100};
  }

  .uppy-StatusBar.is-complete .uppy-StatusBar-progress {
    background-color: ${({ theme }) => theme.colors.green};
  }

  .uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
    color: ${({ theme }) => theme.colors.green};
  }

  .uppy-StatusBar.is-error .uppy-StatusBar-progress {
    background-color: ${({ theme }) => theme.colors.red};
  }

  .uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
    color: ${({ theme }) => theme.colors.red};
  }

  .uppy-StatusBar-actionBtn--retry {
    background-color: ${({ theme }) => theme.colors.red};
  }

  .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
    display: none;
  }

  /* Hide the edit button for already uploaded files */
  .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-action--edit {
    display: none;
  }
`;

const UploadedFilesWrapper = styled.div<any>`
  width: 100%;

  padding: ${(p) => p.theme.sizes.small};
  background-color: ${({ theme }) => theme.colors.white};

  border-left: 1px dashed ${({ theme }) => theme.colors.bambusBlue50};
  border-right: 1px dashed ${({ theme }) => theme.colors.bambusBlue50};
  border-bottom: 1px dashed ${({ theme }) => theme.colors.bambusBlue50};
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const CustomTitle = styled.span`
  color: ${({ theme }) => theme.colors.bambusBlue25};
  font-size: 1.325em;
`;

type UploadToolProps = {
  documentId: string;
  onFileListChange?: (numberOfFiles: number) => void;
  uploadTriggerSetter: (uploadTrigger: () => Promise<object>) => void;
  alreadyUploadedFiles: AlreadyUploadedFile[];
  showLoadingIndicator?: boolean;
};

// TODO: Change this from "require" (using this temporarily as types are missing)
const GermanLocale = require('@uppy/locales/lib/de_DE');

const getImportFileText = (isMobile: boolean) =>
  isMobile
    ? 'Datei per %{browse} hinzufügen'
    : 'Datei per Drag & Drop oder %{browse} hinzufügen';

const setupEditPictureButtonAndTrigggerTooltip = () => {
  const editPictureButtons: HTMLElement[] = Array.from(
    document.querySelectorAll(
      '.uppy-Dashboard-Item:not(.is-complete) .uppy-Dashboard-Item-action--edit'
    )
  );

  if (editPictureButtons.length > 0) {
    editPictureButtons.forEach((editPictureButton) => {
      /**
       * Adding tooltip specific triggers and options to the element.
       */
      editPictureButton.dataset.tip = '';
      editPictureButton.dataset.for = EDIT_PICTURE_INSRUCTIONS_TOOLTIP_ID;
      editPictureButton.dataset.place = 'bottom';
    });

    /**
     * As we've just dynamically added a trigger for our tooltip,
     * it needs to be rebuilt (refreshed).
     */
    ReactTooltip.rebuild();

    /**
     * If in this session we haven't shown the tooltip automatically,
     * then do that now for the first edit picture button.
     */
    if (
      !sessionStorage.getItem(
        KEY_WAS_EDIT_PICTURE_TOOLTIP_AUTOMATICALLY_SHOWN_IN_CURRENT_SESSION
      )
    ) {
      /**
       * Trigger a programatic show, then hide after 4 seconds.
       */
      ReactTooltip.show(editPictureButtons[0]);
      setTimeout(() => {
        ReactTooltip.hide(editPictureButtons[0]);
      }, 4000);
      sessionStorage.setItem(
        KEY_WAS_EDIT_PICTURE_TOOLTIP_AUTOMATICALLY_SHOWN_IN_CURRENT_SESSION,
        'true'
      );
    }
  }
};

const UploadTool = ({
  documentId,
  onFileListChange,
  uploadTriggerSetter,
  alreadyUploadedFiles = [],
  showLoadingIndicator,
}: UploadToolProps) => {
  const isMobile = useContext(IsMobileContext);

  /**
   * The custom title is hidden when we already have at least
   * one uploaded document, or if the drag and drop overlay
   * is visible.
   */
  const [isCustomTitleHidden, setIsCustomTitleHidden] =
    useState<boolean>(false);
  const [isUppyInitialized, setIsUppyInitialized] = useState(false);
  const [alreadyUploadedFilesState, setAlreadyUploadedFilesState] =
    useState<AlreadyUploadedFile[]>(alreadyUploadedFiles);
  const [fileNamesToDelete, setFileNamesToDelete] = useState<string[]>([]);
  const [isLoadingInternally, setIsLoadingInternally] =
    useState<boolean>(false);

  useUpdateEffect(() => {
    setAlreadyUploadedFilesState(alreadyUploadedFiles);
  }, [alreadyUploadedFiles]);

  const deleteUploadedFiles = useCallback(
    (fileIds: any[]) => {
      const newAlreadyUploadedFileList = alreadyUploadedFilesState.filter(
        (file) => !fileIds.includes(file.id)
      );
      setAlreadyUploadedFilesState(newAlreadyUploadedFileList);

      //these calls have to be made sequentially to not break the BE
      (async () => {
        setIsLoadingInternally(true);
        // TODO: Use a better optimistic handling (also handle the case where the file couldnt be deleted)
        for (const fileId of fileIds) {
          try {
            await deleteDocumentFile({ documentId, fileId });
          } catch {}
        }
        setIsLoadingInternally(false);
      })();
    },
    [alreadyUploadedFilesState, documentId]
  );

  useEffect(() => {
    (async () => {
      if (fileNamesToDelete.length > 0) {
        let fileIdsToDelete = alreadyUploadedFilesState
          .filter((alreadyUploadedFile) =>
            fileNamesToDelete.includes(alreadyUploadedFile.name)
          )
          .map((alreadyUploadedFile) => alreadyUploadedFile.id);
        if (fileIdsToDelete.length > 0) {
          deleteUploadedFiles(fileIdsToDelete);
          setFileNamesToDelete([]);
        }
      }
    })();
  }, [fileNamesToDelete, alreadyUploadedFilesState, deleteUploadedFiles]);

  const intl: IntlShape = useIntl();

  const uppyInstance = useRef<any>(null);

  const initializeUppy = useCallback(
    (isMobile) => {
      const uppy: any = UppyCore<any>({
        debug: true,
        autoProceed: false,
        restrictions: {
          maxFileSize: 100000000,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.jpg', '.jpeg', '.png', '.pdf'],
        },
        locale: {
          strings: {
            ...GermanLocale.strings,
            dropPaste: getImportFileText(isMobile),
          },
        },
      })
        .use(Dashboard, {
          width: '100%',
          height: 410,
          inline: true,
          hideUploadButton: true,
          showLinkToFileUploadResult: false,
          trigger: '.UppyModalOpenerBtn',
          target: '.upload-holder',
          replaceTargetContent: true,
          showProgressDetails: true,
          browserBackButtonClose: true,
          proudlyDisplayPoweredByUppy: false,
          showRemoveButtonAfterComplete: true,
          note: intl.formatMessage({
            id: 'Uppy.RestrictionMessage',
            defaultMessage: '.pdf, .png oder .jpg mit max. 100MB',
          }),
        })
        .use(ImageEditor, {
          target: Dashboard,
          quality: 1,
        })
        .use(AwsS3, {
          getUploadParameters: async (file) => {
            const { url } = await getDocumentFilePresignedUploadURL({
              documentId,
              name: file.name,
              type: file.type || '',
              size: file.size,
            });

            // Return an object in the correct shape.
            return {
              method: 'PUT',
              url,
              // Provide content type header required by S3
              headers: {
                'Content-Type': file.type,
              },
            };
          },
        });

      uppyInstance.current = uppy;

      setIsUppyInitialized(true);
    },
    [intl, documentId]
  );

  /**
   * useEffect for Uppy initialization
   */
  useEffect(() => {
    /**
     * If Uppy hasn't been initialized, initialize it.
     */
    if (!uppyInstance.current) {
      initializeUppy(isMobile);
    } else {
      /**
       * The dropPaste text gets modified whether we're on mobile
       * or desktop. On mobile drag and drop is not possible.
       */
      uppyInstance.current.setOptions({
        locale: {
          strings: {
            ...GermanLocale.strings,
            dropPaste: getImportFileText(isMobile),
          },
        },
      });
    }
  }, [isMobile, initializeUppy]);

  /**
   * Calculates the total number of files (Uppy + the ones in the already uploaded files section)
   * and passes it to the onFileNumberChange prop.
   */
  const filesChangedInternalHandler = useCallback(() => {
    const numberOfFilesInUppy = uppyInstance.current?.getFiles().length ?? 0;
    const totalNumberOfFiles =
      numberOfFilesInUppy + alreadyUploadedFilesState.length;
    onFileListChange?.(totalNumberOfFiles);
    setIsCustomTitleHidden(numberOfFilesInUppy > 0);
  }, [alreadyUploadedFilesState, onFileListChange]);

  /**
   * Whenever alreadyUploadedFilesState changes, we trigger the files changed handler
   */
  useUpdateEffect(() => {
    filesChangedInternalHandler();
  }, [alreadyUploadedFilesState]);

  const onFileAdded = useCallback(
    (file: any) => {
      // If a PDF has been added, change the preview to Bambus logo! 🐼
      // (as it would default to the Acrobat logo otherwise)
      if (file.type?.includes('pdf')) {
        file.preview = `${process.env.PUBLIC_URL}/blue-bambus-icon.svg`;
      }
      /**
       * Show a tooltip on the "Edit Image" button with instructions - only on desktop.
       * Note: The "Edit Image" button shows up on image files as part of Uppy's Image Editor plugin.
       */
      if (!isMobile && file.type?.includes('image')) {
        setTimeout(() => {
          setupEditPictureButtonAndTrigggerTooltip();
        }, 400);
      }
      filesChangedInternalHandler();
    },
    [isMobile, filesChangedInternalHandler]
  );

  const onFileRemovedFromUppy = useCallback(() => {
    filesChangedInternalHandler();
  }, [filesChangedInternalHandler]);

  const onUploadedFileDelete = (fileId: any) => {
    // TODO: Use a better optimistic handling (also handle the case where the file couldnt be deleted)
    deleteDocumentFile({ documentId, fileId });
    const newAlreadyUploadedFileList = alreadyUploadedFilesState.filter(
      (file) => fileId !== file.id
    );
    setAlreadyUploadedFilesState(newAlreadyUploadedFileList);
    sendFileDeletedAnalytics({
      documentId,
      fileId,
    });
  };

  const setUppyEventHandlers = useCallback(
    ({ onFileAdded, onFileRemovedFromUppy }) => {
      const uppy = uppyInstance.current;
      if (uppy) {
        if (onFileAdded) {
          uppy.on('file-added', onFileAdded);
        }
        if (onFileRemovedFromUppy) {
          uppy.on('file-removed', onFileRemovedFromUppy);
        }
      }
    },
    []
  );

  const clearUppyEventHandlers = useCallback(
    ({ onFileAdded, onFileRemovedFromUppy }) => {
      const uppy = uppyInstance.current;
      if (uppy) {
        if (onFileAdded) {
          uppy.off('file-added', onFileAdded);
        }
        if (onFileRemovedFromUppy) {
          uppy.off('file-removed', onFileRemovedFromUppy);
        }
      }
    },
    []
  );

  /**
   * Effect to set the event handlers.
   */
  useEffect(() => {
    if (isUppyInitialized) {
      setUppyEventHandlers({ onFileAdded, onFileRemovedFromUppy });

      //Clear the handlers
      return () => {
        clearUppyEventHandlers({ onFileAdded, onFileRemovedFromUppy });
      };
    }
  }, [
    setUppyEventHandlers,
    clearUppyEventHandlers,
    onFileAdded,
    onFileRemovedFromUppy,
    isUppyInitialized,
  ]);

  const onBrowseFilesPress = useCallback(() => {
    //The input (type file) that Uppy uses
    const uppyFileInput: HTMLInputElement | null =
      document.querySelector('input[type=file]');
    uppyFileInput?.click();
  }, []);

  /**
   * Function to trigger the upload.
   * It is sent back to the parent component via
   * the "uploadTriggerSetter" prop.
   */
  const triggerUpload = useCallback<() => Promise<any>>(() => {
    if (uppyInstance.current) {
      if (uppyInstance.current.getFiles().length === 0) {
        return Promise.resolve();
      }

      //Before upload analytics trigger
      const newFilesToUploadInfoForAnalytics = mapUppyFilesToSizeAndType(
        uppyInstance.current.getFiles()
      );
      if (newFilesToUploadInfoForAnalytics.length > 0) {
        sendOnBeforeUploadFilesAnalytics({
          documentId,
          filesToUpload: newFilesToUploadInfoForAnalytics,
        });
      }

      return uppyInstance.current.upload().then((result: any) => {
        //After upload analytics trigger
        const filesSuccessfullyUploadedInfoForAnalytics =
          mapUppyFilesToSizeAndType(result.successful);

        const filesThatFailedToBeUploaded =
          mapUppyFilesToNameAndSizeAndTypeAndFailureReason(result.failed);

        sendOnAfterUploadFilesAnalytics({
          documentId,
          filesUploadedSuccessfully: filesSuccessfullyUploadedInfoForAnalytics,
          filesUploadedWithError: filesThatFailedToBeUploaded,
        });

        uppyInstance.current.reset();

        if (filesThatFailedToBeUploaded.length > 0) {
          const failedFileNames = filesThatFailedToBeUploaded.map(
            (file) => file.fileName
          );

          setFileNamesToDelete(failedFileNames);
          return Promise.reject({
            uploadError: true,
            networkError: true,
            failedToUploadFileNames: failedFileNames,
          });
        }

        return result;
      });
    } else {
      return Promise.reject({
        uploadError: false,
        error: 'Frontend error. Uppy instance does not exist.',
      });
    }
  }, [documentId]);

  /**
   * Effect to send back to the parent component
   * the function that can trigger the upload process.
   */
  useEffect(() => {
    uploadTriggerSetter(triggerUpload);
  }, [triggerUpload, uploadTriggerSetter]);

  const TitleText = isMobile
    ? intl.formatMessage({
        id: 'Uppy.UploadData',
        defaultMessage: 'Datei hochladen',
      })
    : intl.formatMessage({
        id: 'Uppy.UploadDataByDND',
        defaultMessage: 'Datei per Drag & Drop hochladen',
      });

  return (
    <div style={{ position: 'relative' }}>
      <UppyWrapper isCustomTitleHidden={isCustomTitleHidden}>
        <div className="upload-holder" />
        <Tooltip id={EDIT_PICTURE_INSRUCTIONS_TOOLTIP_ID} effect="solid">
          <FormattedMessage
            id="Uppy.EditPictureInstructions"
            defaultMessage="Einzelne Seiten können mittels dieser Funktion rotiert werden."
          />
        </Tooltip>
        {!isCustomTitleHidden && (
          <CustomMainScreen>
            <CustomTitle>{TitleText}</CustomTitle>
            <VerticalSpacer space={theme.sizes.small} />
            <FeatherIconHolder
              iconWidth="24px"
              iconFillColor={theme.colors.bambusBlue50}
            >
              <UploadIcon />
            </FeatherIconHolder>
            <VerticalSpacer space={theme.sizes.small} />
            <Button onClick={onBrowseFilesPress} outline>
              <FormattedMessage
                id="Uppy.ChooseFiles"
                defaultMessage="Datei auswählen"
              />
            </Button>
          </CustomMainScreen>
        )}
      </UppyWrapper>
      {alreadyUploadedFilesState.length > 0 && (
        <UploadedFilesWrapper>
          <Heading
            weight={700}
            size={isMobile ? 18 : 10}
            color={theme.colors.bambusBlue100}
          >
            <FormattedMessage
              id="UploadedDocuments"
              defaultMessage="Bereits hochgeladene Dateien"
            />
          </Heading>

          <VerticalSpacer space={theme.sizes.small} />
          {alreadyUploadedFilesState.map((file) => (
            <Fragment key={file.id}>
              <UploadedFile
                typeIcon={getFileIconBasedOnType(file.type ?? '')}
                fileName={file.name}
                fileSizeBytes={file.size ?? 0}
                closeButton={<X />}
                onDelete={() => onUploadedFileDelete(file.id)}
              />
              <VerticalSpacer space={theme.sizes.small} />
            </Fragment>
          ))}
        </UploadedFilesWrapper>
      )}
      {(showLoadingIndicator || isLoadingInternally) && <LoadingIndicator />}
    </div>
  );
};

export default UploadTool;
