import styled from 'styled-components';

type VerticalSeparatorProps = {
  height?: string;
  marginLeft?: string;
  marginRight?: string;
};

const VerticalSeparator = styled.div<VerticalSeparatorProps>`
  width: 1px;
  ${({ height }) => height && `height: ${height};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}

  background-color: ${({ theme }) => theme.colors.bambusBlue10};
`;

export default VerticalSeparator;
