import React from 'react';
import styled from 'styled-components';
import history from 'utils/History';
import { useQuery } from 'react-query';
import { Button } from 'bambus-ui-components';
import { getUserDetails } from 'api/requests';

const PrivilegedModeInfoWrapper = styled.div<{ height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${({ height }) => height};
  background-color: ${({ theme }) => theme.colors.red};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 800px) {
    font-size: 0.85rem;
  }
`;

export type PrivilegedSessionIndicatorProps = {
  height: string;
};

const PrivilegedSessionIndicator = ({
  height,
}: PrivilegedSessionIndicatorProps) => {
  const { data: userProfileData } = useQuery(['userData'], getUserDetails);

  return (
    <PrivilegedModeInfoWrapper height={height}>
      <span>
        Viewing data for <strong>{userProfileData?.email}</strong>.
        <Button
          muted
          // @ts-ignore
          style={{ marginLeft: 20 }}
          onClick={() => {
            history.push('/documents');
          }}
        >
          Go to Documents
        </Button>
      </span>
      <span>
        This is a special session, only valid in this browser tab and ends with
        the closing of the tab.
      </span>
    </PrivilegedModeInfoWrapper>
  );
};

export default PrivilegedSessionIndicator;
