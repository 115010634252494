import { DefaultTheme } from 'styled-components';

const bambusBlue10 = '#E6E6EC';
const purple = '#966B9D';
const green = '#558B6E';
const red = '#DB5461';

const theme: DefaultTheme = {
  colors: {
    white: '#FFFBFC',
    actionBlue: '#1F487E',
    bambusBlue5: '#F2F1F4',
    bambusBlue10,
    bambusBlue25: '#BFC7D3',
    bambusBlue50: '#8093AB',
    bambusBlue75: '#415E82',
    bambusBlue90: '#1A3F69',
    bambusBlue100: '#012A59',
    bambusBlue2dot5: '#F9F6F8',
    green: '#558B6E',
    purple,
    red,
    documentStatuses: {
      open: bambusBlue10,
      processing: purple,
      accepted: green,
      declined: red,
    },
    pink: '#E06C9F',
  },
  fontWeights: {
    heading: 800,
  },
  fontSizes: {
    text: '0.875em',
    navigationText: '1em',
    paragraph: '1.125em',
    primaryNavigationText: '1.25em',
    heading: '1.75em',
  },
  sizes: {
    mini: '0.325rem',
    extraSmall: '0.625rem',
    small: '1.25rem',
    medium: '1.875rem',
    large: '2.2rem',
    midLarge: '2.75rem',
    extraLarge: '4rem',
    mobile: {
      headerHeight: '56px',
      headerHeightPx: 56,
    },
  },
  lineHeights: {
    paragraph: '27px',
    longParagraph: '21px',
    heading: '30px',
  },
  borderRadii: {
    small: '3px',
    normal: '6px',
  },
  breakpoints: {
    mobileMaxWidthPx: 1000,
    desktopMinWidthPx: 1001,
  },
  animationDurations: {
    normal: '0.3s',
  },
  zIndexes: {
    mobileHeader: 3,
    mobileReorderHeader: 4,
  },
  boxShadows: {
    tile: '0px 6px 12px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.05)',
  },
  cookieConsent: {
    wrapperOpacity: 0.9,
    wrapperBackgroundColor: '#F9F6F8',
    backgroundColor: '#012A59',
    maxWidth: '646px',
    textColor: '#FFFBFC',
    borderRadius: '3px',
    partiallyAcceptButtonColor: '#20487E',
    acceptAllButtonColor: '#548C6E',
  },
};

export default theme;
