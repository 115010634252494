import React from 'react';
import styled from 'styled-components';

const StyledParagraph = styled.p<any>`
  font-size: calc(
    ${({ theme }) => theme.fontSizes.paragraph} - ${({ size }) => size}px
  );
  color: ${({ color, theme }) => color || theme.colors.actionBlue};
  line-height: ${({ lineHeight, theme }) =>
    lineHeight || theme.lineHeights.paragraph};
`;

type ParagraphProps = {
  color?: string;
  size?: number;
  lineHeight?: string;
};

/**
 * Paragraph component.
 * As the "size" prop grows, the text size decreases.
 */
const Paragraph = ({
  children,
  color,
  size = 0,
  lineHeight,
}: React.PropsWithChildren<ParagraphProps>) => {
  return (
    <StyledParagraph color={color} size={size} lineHeight={lineHeight}>
      {children}
    </StyledParagraph>
  );
};

export default Paragraph;
