import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<any>`
  box-sizing: content-box;
  width: ${(p) => p.w};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.p};
  grid-area: ${(p) => p.gridAreaName};
  border-radius: ${(p) => p.borderRadius};
  background-color: ${(p) => p.backgroundColor};
  transition: all 0.3s;
`;

const FeatherIconHolder = ({
  children: FeatherIcon,
  iconWidth = '18px',
  padding = '0em',
  gridAreaName = '',
  borderRadius = '0px',
  backgroundColor = 'transparent',
  iconFillColor,
  ...rest
}: any) => {
  return (
    <Wrapper
      w={iconWidth}
      p={padding}
      gridAreaName={gridAreaName}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      {...rest}
    >
      {FeatherIcon &&
        React.cloneElement(FeatherIcon, {
          color: iconFillColor,
          size: iconWidth,
        })}
    </Wrapper>
  );
};

export default FeatherIconHolder;
