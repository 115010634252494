import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Button, ValidationMessage } from 'bambus-ui-components';
import styled from 'styled-components';
import { CornerDownRight } from 'react-feather';

import theme from 'styles/theme';

import HorizontalSpacer from 'atoms/HorizontalSpacer';
import FeatherIconHolder from 'atoms/FeatherIconHolder';
import Heading from 'atoms/Heading';
import Paragraph from 'atoms/Paragraph';
import MainContentWrapper from 'atoms/MainContentWrapper';
import VerticalSpacer from 'atoms/VerticalSpacer';

import { DocumentStatus } from 'molecules/Document';
import UploadScreenPlaceholder from 'molecules/UploadScreenPlaceholder';

import DocumentNote from 'organisms/DocumentNote';
import DocumentValidationMessage from 'organisms/DocumentValidationMessage';

import UploadScreen, { UploadScreenProps } from './Upload';
import ReorderScreen, { ReorderScreenProps } from './Reorder';
import ReviewScreen, { ReviewScreenProps } from './Review';

import NavigateAwayBlockingModal from './NavigateAwayBlockingModal';

import RouteLeavingGuard from 'utils/RouteLeavingGuard';

import { getDocumentDetails, deleteAllDocumentFiles } from 'api/requests';

import routes from 'routes';
import DocumentUploadedSuccessfullyMessage from 'organisms/DocumentUploadedSuccessfullyMessage';
import ScrollDocumentsContainerToTopContext from 'contexts/ScrollDocumentsContainerToTopContext';

type RouteParams = {
  id?: string | undefined;
};

type DocumentPageProps = {};

// The steps a user goes through when uploading a document
enum DocumentUploadState {
  UPLOAD,
  REORDER,
  REVIEW,
  REVIEW_LOCKED,
}

type NextDocumentStateQueryProps = {
  currentState: DocumentUploadState | null | undefined;
};

const getNextStateForDocument = ({
  currentState,
}: NextDocumentStateQueryProps): DocumentUploadState => {
  switch (currentState) {
    case DocumentUploadState.UPLOAD:
      return DocumentUploadState.REVIEW;
    case DocumentUploadState.REORDER:
      return DocumentUploadState.REVIEW;
    default:
      return DocumentUploadState.UPLOAD;
  }
};

type PreviousDocumentStateQueryProps = {
  currentState: DocumentUploadState | null | undefined;
};

const getPreviousStateForDocument = ({
  currentState,
}: PreviousDocumentStateQueryProps): DocumentUploadState => {
  switch (currentState) {
    case DocumentUploadState.REORDER:
      return DocumentUploadState.REVIEW;
    case DocumentUploadState.REVIEW:
      return DocumentUploadState.UPLOAD;
    default:
      return DocumentUploadState.UPLOAD;
  }
};

type CommonProps = {
  documentId: string;
};

type CorrectScreenForDocumentQueryProps = {
  documentUploadState: DocumentUploadState | null | undefined;
  commonProps: CommonProps;
  uploadScreenProps: Omit<UploadScreenProps, keyof CommonProps>;
  reorderScreenProps: Omit<ReorderScreenProps, keyof CommonProps>;
  reviewScreenProps: Omit<ReviewScreenProps, keyof CommonProps>;
};

export type DocumentPageType = {
  index: number;
  smallPreview: string;
  fullPreview: string;
};

const getScreenForDocument = ({
  documentUploadState,
  commonProps,
  reorderScreenProps,
  uploadScreenProps,
  reviewScreenProps,
}: CorrectScreenForDocumentQueryProps) => {
  switch (documentUploadState) {
    case DocumentUploadState.UPLOAD:
      return <UploadScreen {...commonProps} {...uploadScreenProps} />;
    case DocumentUploadState.REORDER:
      return <ReorderScreen {...commonProps} {...reorderScreenProps} />;
    case DocumentUploadState.REVIEW:
      return <ReviewScreen {...commonProps} {...reviewScreenProps} />;
    case DocumentUploadState.REVIEW_LOCKED:
      return <ReviewScreen isLocked {...commonProps} {...reviewScreenProps} />;
  }
};

const getCorrectDocumentUploadStateBasedOnDocStatus = (
  documentStatus: DocumentStatus
): DocumentUploadState => {
  switch (documentStatus) {
    case DocumentStatus.Open:
      return DocumentUploadState.UPLOAD;
    case DocumentStatus.Accepted:
      return DocumentUploadState.REVIEW_LOCKED;
    case DocumentStatus.Declined:
      return DocumentUploadState.UPLOAD;
    case DocumentStatus.Processing:
      return DocumentUploadState.REVIEW_LOCKED;
    case DocumentStatus.GraceEditable:
    default:
      return DocumentUploadState.UPLOAD;
  }
};

type HistoryState = {
  cameByDocumentClick?: boolean;
};

const ClusterChildInformation = styled.div`
  display: flex;
  align-items: center;
`;

const ClusterTitle = styled.span`
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  color: ${(p) => p.theme.colors.bambusBlue100};
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: break-spaces;
  max-width: 100%;
`;

const Document = React.memo(
  ({
    match,
    history,
  }: DocumentPageProps &
    RouteComponentProps<RouteParams, any, HistoryState>) => {
    const documentId = match.params.id;

    /**
     * Initially the state (screen/ upload process step)
     * is undefined. After we fetch the data of document we
     * set this based on the status
     */
    const [documentUploadState, setDocumentUploadState] = useState<
      DocumentUploadState | undefined | null
    >(undefined);

    /**
     * It's important to know whether the user came from the Reorder screen,
     * because on mobile, when a user comes from the Reorder screen (goes to Review)
     * the full page review screen must be automatically shown.
     */
    const [didUserComeFromReordering, setDidUserComeFromReordering] =
      useState<boolean>(false);

    /**
     * We're handling the complete reset of upload process in this component,
     * thus here is the component state that for loading and error of resetting.
     * Note: Reset = Deletion of all user uploaded files.
     */
    const [isDocumentResetInProgress, setIsDocumentResetInProgress] =
      useState<boolean>(false);
    const [isDocumentResetError, setIsDocumentResetError] =
      useState<boolean>(false);

    const [isThereUnsavedProgress, setIsThereUnsavedProgress] =
      useState<boolean>(false);

    /**
     * Refetching on window focus is disabled as the (presigned) URLs
     * of user uploaded files change with each request and this would
     * trigger a rerender.
     *
     * Cache time set to 0 so we're NOT reusing the data we fetched earlier.
     */
    const {
      data: documentDetails,
      refetch: refetchDocumentData,
      isLoading,
      error: documentDetailsFetchError,
    } = useQuery(
      ['documents', documentId],
      () => getDocumentDetails({ documentId }),
      {
        refetchOnWindowFocus: false,
        cacheTime: 0,
      }
    );

    /**
     * This effect runs when documentDetails first changes (after the data
     * is fetched from the server).
     * The effect sets the state of the upload process.
     */
    useEffect(() => {
      if (documentDetails && documentUploadState === undefined) {
        setDocumentUploadState(
          getCorrectDocumentUploadStateBasedOnDocStatus(documentDetails.status)
        );
      }
    }, [documentDetails, documentUploadState]);

    const onBackClick = useCallback(() => {
      /**
       * If we're on the first step (Uppy Upload) or in the locked
       * review, navigate to the documents overview.
       */
      if (
        documentUploadState === DocumentUploadState.UPLOAD ||
        documentUploadState === DocumentUploadState.REVIEW_LOCKED
      ) {
        if (history.location.state?.cameByDocumentClick) {
          history.goBack();
        } else {
          history.push(routes.documentsRoute);
        }
      } else {
        setDidUserComeFromReordering(
          documentUploadState === DocumentUploadState.REORDER
        );
        const previousState: DocumentUploadState = getPreviousStateForDocument({
          currentState: documentUploadState,
        });
        refetchDocumentData();
        setDocumentUploadState(previousState);
      }
    }, [history, documentUploadState, refetchDocumentData]);

    const onUploadProcessNextStep = useCallback(async () => {
      refetchDocumentData();
      setDidUserComeFromReordering(
        documentUploadState === DocumentUploadState.REORDER
      );
      const nextState = getNextStateForDocument({
        currentState: documentUploadState,
      });
      setDocumentUploadState(nextState);
      setIsThereUnsavedProgress(false);
    }, [documentUploadState, refetchDocumentData]);

    /**
     * This can happen from inside the Review step.
     */
    const onReorderPagesIntention = useCallback(() => {
      setDocumentUploadState(DocumentUploadState.REORDER);
    }, []);

    const onUploadOrConcatenationError = () => {
      refetchDocumentData();
    };

    const onRestartDocumentUploadIntention = useCallback(async () => {
      try {
        setIsDocumentResetInProgress(true);
        setIsDocumentResetError(false);
        setDocumentUploadState(null);
        await deleteAllDocumentFiles({ documentId: documentId || '' });
        await refetchDocumentData();
        setIsDocumentResetInProgress(false);
        setDocumentUploadState(DocumentUploadState.UPLOAD);
      } catch (error) {
        setIsDocumentResetError(true);
      }
    }, [documentId, refetchDocumentData]);

    const shouldValidationMessageBeShown =
      documentDetails?.validationMessage &&
      documentDetails?.status === DocumentStatus.Declined;

    useEffect(() => {
      if (isThereUnsavedProgress) {
        window.onbeforeunload = (event: any) => {
          // Cancel the event as stated by the standard.
          event.preventDefault();
          // Chrome requires returnValue to be set.
          event.returnValue = '';
        };
      } else {
        window.onbeforeunload = null;
      }
      return () => {
        window.onbeforeunload = null;
      };
    }, [isThereUnsavedProgress]);

    // When a file gets selected in Uppy (but not yet uploaded)
    const onFileListChange = (newNumberOfFiles: number) => {
      if (newNumberOfFiles > 0) {
        setIsThereUnsavedProgress(true);
      } else {
        setIsThereUnsavedProgress(false);
      }
    };

    const onSubmitDocument = () => {};

    const isClusterChildDocument =
      documentDetails?.parent !== undefined && documentDetails?.parent !== null;
    const shownTitle =
      (isClusterChildDocument
        ? documentDetails?.parent.title
        : documentDetails?.title) ?? '';

    const getBackButtonTextNode = () => {
      if (
        documentUploadState === DocumentUploadState.UPLOAD ||
        documentUploadState === DocumentUploadState.REVIEW_LOCKED
      )
        return (
          <FormattedMessage id="ToOverview" defaultMessage="zur Übersicht" />
        );

      if (documentUploadState === DocumentUploadState.REVIEW) {
        return (
          <FormattedMessage
            id="ToFileChoice"
            defaultMessage="zur Dateiauswahl"
          />
        );
      }

      return (
        <FormattedMessage
          id="ToLastStep"
          defaultMessage="zum vorherigen Schritt"
        />
      );
    };

    const { scrollDocumentsContainerToTop } = useContext(
      ScrollDocumentsContainerToTopContext
    );

    useEffect(() => {
      scrollDocumentsContainerToTop();
      // eslint-disable-next-line
    }, [documentUploadState]);

    return (
      <MainContentWrapper>
        <RouteLeavingGuard
          when={isThereUnsavedProgress}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={() => {
            return true;
          }}
          warningDialogBuilder={(isOpen, onCancel, onConfirm) => (
            <NavigateAwayBlockingModal
              isOpen={isOpen}
              documentTitle={
                (isClusterChildDocument
                  ? `${documentDetails?.parent.title}-${documentDetails?.title}`
                  : documentDetails?.title) ?? ''
              }
              onStayOnDocUpload={onCancel}
              onNavigateAway={onConfirm}
            />
          )}
        />

        {(documentDetailsFetchError || isDocumentResetError) && (
          <div style={{ marginTop: 20 }}>
            <ValidationMessage hasError>
              <FormattedMessage
                id="GeneralFailurePleaseContactSupport"
                defaultMessage="Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support."
              />
            </ValidationMessage>
          </div>
        )}
        {isLoading || isDocumentResetInProgress ? (
          <UploadScreenPlaceholder />
        ) : (
          <>
            <Button
              basic
              icon={<ArrowLeft />}
              //@ts-ignore
              style={{ color: theme.colors.bambusBlue50 }}
              onClick={onBackClick}
            >
              {getBackButtonTextNode()}
            </Button>
            {/* If there is a note or a validation message, insert space below the back button: */}
            {(shouldValidationMessageBeShown ||
              documentDetails?.note ||
              documentUploadState === DocumentUploadState.REVIEW) && (
              <VerticalSpacer space={theme.sizes.small} />
            )}
            {shouldValidationMessageBeShown && (
              <>
                <DocumentValidationMessage
                  message={documentDetails?.validationMessage || ''}
                />
                {/* If there's also a note, insert space below */}
                {documentDetails?.note && (
                  <VerticalSpacer space={theme.sizes.mini} />
                )}
              </>
            )}
            {documentDetails?.note &&
              documentUploadState === DocumentUploadState.UPLOAD && (
                <DocumentNote note={documentDetails.note} />
              )}
            {documentUploadState === DocumentUploadState.REVIEW && (
              <DocumentUploadedSuccessfullyMessage />
            )}
            <VerticalSpacer space={theme.sizes.large} />
            <Heading weight={700} size={7} color={theme.colors.bambusBlue100}>
              {shownTitle}
            </Heading>
            {isClusterChildDocument && (
              <>
                <VerticalSpacer space={theme.sizes.extraSmall} />
                <ClusterChildInformation>
                  <FeatherIconHolder
                    gridAreaName="icon"
                    iconFillColor={theme.colors.bambusBlue100}
                  >
                    <CornerDownRight />
                  </FeatherIconHolder>
                  <HorizontalSpacer space={theme.sizes.extraSmall} />
                  <ClusterTitle>{documentDetails?.title}</ClusterTitle>
                </ClusterChildInformation>
              </>
            )}
            <VerticalSpacer space={theme.sizes.small} />
            <Paragraph color={theme.colors.bambusBlue100}>
              {documentDetails?.description || ''}
            </Paragraph>
            {documentDetails?.availableAt && (
              <>
                <VerticalSpacer space={theme.sizes.small} />
                <Paragraph color={theme.colors.bambusBlue100}>
                  <FormattedMessage
                    id="AvailableAt"
                    defaultMessage="Erhältlich bei"
                  />
                  {`: ${documentDetails.availableAt}`}
                </Paragraph>
              </>
            )}
            {documentDetails?.period && (
              <>
                <VerticalSpacer space={theme.sizes.small} />
                <Paragraph color={theme.colors.bambusBlue100}>
                  <FormattedMessage id="Period" defaultMessage="Zeitraum" />
                  {`: ${documentDetails.period}`}
                </Paragraph>
              </>
            )}
          </>
        )}
        <VerticalSpacer space={theme.sizes.medium} />
        {getScreenForDocument({
          documentUploadState,
          commonProps: {
            documentId: documentId || '',
          },
          uploadScreenProps: {
            onFileListChange,
            downloadableDocumentURLs:
              documentDetails?.filesForSigning.map((file) => file.url) || [],
            onFinishUpload: onUploadProcessNextStep,
            initialFiles: documentDetails?.userFiles || [],
            onUploadOrConcatenationError,
            onRestartDocumentUploadIntention,
          },
          reorderScreenProps: {
            initialDocumentPages: documentDetails?.pages || [],
            onFinishReorder: onUploadProcessNextStep,
            onCancelPress: onBackClick,
            onRestartDocumentUploadIntention,
          },
          reviewScreenProps: {
            documentURL: documentDetails?.concatenatedFile?.url || '',
            documentStatus: documentDetails?.status,
            autoOpenMobileReviewScreen: didUserComeFromReordering,
            onReorderPagesIntention,
            customerNote: documentDetails?.customerNote,
            onSubmitDocument,
          },
        })}
      </MainContentWrapper>
    );
  }
);

export default withRouter(Document);
