import React from 'react';
import styled from 'styled-components';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { TextRow, RectShape } from 'react-placeholder/lib/placeholders';

import theme from 'styles/theme';

import Tile from 'atoms/Tile';

const PersonalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WidgetWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: ${({ theme }) => theme.sizes.medium};
`;

const PersonalInfoInput = () => {
  return (
    <PersonalInfoWrapper>
      <TextRow
        color={theme.colors.bambusBlue5}
        style={{ width: 200, height: 12, borderRadius: 3, marginBottom: 10 }}
      />
      <RectShape
        color={theme.colors.bambusBlue5}
        style={{ width: '100%', height: 46, borderRadius: 3 }}
      />
    </PersonalInfoWrapper>
  );
};

const widgetPlaceholder = (
  <Tile>
    <WidgetWrapper>
      <PersonalInfoInput />
      <PersonalInfoInput />
      <PersonalInfoInput />
      <PersonalInfoInput />
    </WidgetWrapper>
  </Tile>
);

const PersonalInfoWidgetPlaceholder = () => {
  return (
    <ReactPlaceholder
      customPlaceholder={widgetPlaceholder}
      showLoadingAnimation
      ready={false}
      children={null}
    />
  );
};

export default PersonalInfoWidgetPlaceholder;
