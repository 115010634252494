import { useEffect, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import CurrentPageContext from 'contexts/CurrentPageContext';

import { Page } from 'pages/PagesList';

const SetCurrentPageOnNavigation = ({ location }: RouteComponentProps) => {
  const { setCurrentPage } = useContext(CurrentPageContext);

  useEffect(() => {
    //If "/documents" is in the pathname, set the Documents page as current one.
    if (/^\/documents/.test(location.pathname)) {
      setCurrentPage(Page.DOCUMENTS);
      return;
    }

    if (/^\/settings/.test(location.pathname)) {
      setCurrentPage(Page.SETTINGS);
      return;
    }

    if (/^\/profile/.test(location.pathname)) {
      setCurrentPage(Page.PROFILE);
      return;
    }

    setCurrentPage(null);
  }, [location, setCurrentPage]);
  return null;
};

export default withRouter(SetCurrentPageOnNavigation);
