import React, { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  File as FileIcon,
  CheckCircle as CheckCircleIcon,
  XCircle as XCircleIcon,
  X as XIcon,
  Upload as UploadIcon,
} from 'react-feather';

import VerticalSeparator from 'atoms/VerticalSeparator';

import theme from 'styles/theme';
import { FadeAndSlideIn, FadeIn, FadeOut } from 'styles/StyledAnimations';

import { NotificationType } from 'models/Notifications';

const Notification = styled.article<{
  isMobile: boolean;
  shouldAlsoSlideIn: boolean;
  shouldFadeOut?: boolean;
}>`
  // When on mobile we absolutely position the notifications with 10 px left & right padding - see NotificationsTooltip.tsx;
  ${({ isMobile }) => isMobile && 'width: calc(100vw - 20px)'};
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 1fr;
  grid-template-areas: 'icon separator text';
  gap: 15px;
  padding: 20px;

  background-color: ${({ theme }) => theme.colors.bambusBlue2dot5};

  filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 12.963px 23.4259px rgba(0, 0, 0, 0.0151852))
    drop-shadow(0px 7.7037px 12.7407px rgba(0, 0, 0, 0.0121481))
    drop-shadow(0px 4px 6.5px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 1.62963px 3.25926px rgba(0, 0, 0, 0.00785185))
    drop-shadow(0px 0.37037px 1.57407px rgba(0, 0, 0, 0.00481481));

  cursor: pointer;

  ${({ shouldAlsoSlideIn }) => (shouldAlsoSlideIn ? FadeAndSlideIn : FadeIn)};

  ${({ shouldFadeOut }) => shouldFadeOut && FadeOut};

  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadii.small};
    border-top-right-radius: ${({ theme }) => theme.borderRadii.small};
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadii.small};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadii.small};
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.bambusBlue5};
  }
`;

const TextHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px auto;
  grid-template-rows: auto 5px auto;
  grid-template-areas:
    'title . time-elapsed-text-or-dismiss-button'
    '. . .'
    'description description description';
`;

const NotificationTitle = styled.h1`
  font-weight: 700;
  font-size: 14px;
  line-height: 17.57px;
  color: ${({ theme }) => theme.colors.bambusBlue100};
`;

const NotificationDescription = styled.p`
  max-width: 275px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.57px;
  color: ${({ theme }) => theme.colors.bambusBlue100};
`;

const TimeElapsedText = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 12.55px;
  color: ${({ theme }) => theme.colors.bambusBlue25};
`;

const DismissButton = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
  padding-right: 0;
`;

export type NotificationProps = {
  notificationIds: string[];
  title: string;
  description: string;
  documentIds: (string | number)[];
  notificationType: NotificationType;
  timeElapsedText: string;
  onNotificationClick: (
    documentIds: (string | number)[],
    notificationIds: string[]
  ) => any;
  isMobile: boolean;
  type?: 'tooltip' | 'mobile-realtime';
  onDismissClick?: (notificationIds: string[]) => any;
};

const getIconBasedOnNotificationType = (
  notificationType: NotificationType
): React.ReactNode => {
  const ICON_SIZE = 18;
  switch (notificationType) {
    case 'added':
      return <UploadIcon color={theme.colors.bambusBlue100} size={ICON_SIZE} />;
    case 'available_download':
      return <FileIcon color={theme.colors.purple} size={ICON_SIZE} />;
    case 'available_download_and_sign':
      return <FileIcon color={theme.colors.purple} size={ICON_SIZE} />;
    case 'accepted':
      return <CheckCircleIcon color={theme.colors.green} size={ICON_SIZE} />;
    case 'declined':
      return <XCircleIcon color={theme.colors.red} size={ICON_SIZE} />;
  }
};

const TooltipNotification = ({
  notificationIds,
  title,
  description,
  documentIds,
  notificationType,
  timeElapsedText,
  onNotificationClick,
  isMobile,
  type = 'tooltip',
  onDismissClick,
}: NotificationProps) => {
  const onClick = () => {
    onNotificationClick(documentIds, notificationIds);
  };

  const onDismiss = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onDismissClick?.(notificationIds);
  };

  const [shouldFadeOut, setShouldFadeOut] = useState(false);

  // if it's a mobile realtime notification then dismiss it automatically after 3seconds
  useEffect(() => {
    if (type === 'mobile-realtime') {
      const timer = setTimeout(() => {
        setShouldFadeOut(true);
        //wait till the fade out animation is done then dismiss the notification
        setTimeout(() => {
          onDismissClick?.(notificationIds);
        }, 300);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [type, notificationIds, onDismissClick]);

  return (
    <Notification
      onClick={onClick}
      isMobile={isMobile}
      shouldAlsoSlideIn={type === 'tooltip'}
      shouldFadeOut={shouldFadeOut}
    >
      <div
        style={{
          gridArea: 'icon',
        }}
      >
        {getIconBasedOnNotificationType(notificationType)}
      </div>
      <VerticalSeparator
        style={{
          gridArea: 'separator',
          height: '100%',
        }}
      />
      <TextHolder
        style={{
          gridArea: 'text',
        }}
      >
        <NotificationTitle
          style={{
            gridArea: 'title',
            alignSelf: 'center',
          }}
        >
          {title}
        </NotificationTitle>
        {type === 'tooltip' ? (
          <TimeElapsedText
            style={{
              gridArea: 'time-elapsed-text-or-dismiss-button',
              alignSelf: 'start',
            }}
          >
            {timeElapsedText}
          </TimeElapsedText>
        ) : (
          <DismissButton onClick={onDismiss}>
            <XIcon
              color={theme.colors.bambusBlue50}
              size={12}
              style={{
                gridArea: 'time-elapsed-text-or-dismiss-button',
                alignSelf: 'start',
              }}
            />
          </DismissButton>
        )}
        <NotificationDescription
          style={{
            gridArea: 'description',
            alignSelf: 'center',
          }}
        >
          {description}
        </NotificationDescription>
      </TextHolder>
    </Notification>
  );
};

export default TooltipNotification;
