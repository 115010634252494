import React, { useContext, useEffect, useRef } from 'react';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';

import { NotificationType } from 'models/Notifications';

import theme from 'styles/theme';

import TooltipNotification from 'atoms/TooltipNotification';

import IsMobileContext from 'contexts/IsMobileContext';

const StyledTooltip = styled(Tooltip)<{
  arrowPosLeft?: string;
  ref: any;
}>`
  &&.show {
    opacity: 1;
    padding: 0;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadii.small};
    filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.02))
      drop-shadow(0px 12.963px 23.4259px rgba(0, 0, 0, 0.0151852))
      drop-shadow(0px 7.7037px 12.7407px rgba(0, 0, 0, 0.0121481))
      drop-shadow(0px 4px 6.5px rgba(0, 0, 0, 0.01))
      drop-shadow(0px 1.62963px 3.25926px rgba(0, 0, 0, 0.00785185))
      drop-shadow(0px 0.37037px 1.57407px rgba(0, 0, 0, 0.00481481));
  }
  &&.show::after,
  &&.show::before {
    ${({ arrowPosLeft }) => arrowPosLeft && `left: ${arrowPosLeft};`}
  }
`;

const InnerWrapper = styled.div`
  max-height: min(500px, 70vh);
  overflow-y: auto;
  border-radius: ${({ theme }) => theme.borderRadii.small};
`;

export const REALTIME_NOTIFICATIONS_TOOLTIP_ID =
  'realtime-notifications-tooltip';

type Notification = {
  id: string | number;
  title: string;
  description: string;
  documentIds: (string | number)[];
  notificationType: NotificationType;
  timeElapsedText: string;
};

type MobileGlobalNotificationsProps = {
  arrowPosLeft?: string;
  notifications: (Notification & {
    notificationIds: string[];
  })[];
  onNotificationClick: (
    documentIds: (string | number)[],
    notificationIds: string[]
  ) => any;
  onNotificationDismiss: (notificationIds: string[]) => any;
};

const MobileGlobalNotifications = ({
  arrowPosLeft,
  notifications,
  onNotificationClick,
  onNotificationDismiss,
}: MobileGlobalNotificationsProps) => {
  const isMobile = useContext(IsMobileContext);

  const tooltipRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const listener = () => {
      if (tooltipRef.current) {
        (tooltipRef.current as any).tooltipRef = null;
      }
    };
    document.addEventListener(
      'cockpit:HACKYcloseMobileGlobalNotificationsTooltip',
      listener
    );
    return () => {
      document.removeEventListener(
        'cockpit:HACKYcloseMobileGlobalNotificationsTooltip',
        listener
      );
    };
  }, []);

  return (
    <StyledTooltip
      ref={tooltipRef}
      id={REALTIME_NOTIFICATIONS_TOOLTIP_ID}
      effect="solid"
      border={false}
      clickable
      place="bottom"
      backgroundColor="transparent"
      arrowColor={theme.colors.bambusBlue2dot5}
      overridePosition={(position) => {
        if (isMobile) {
          return { ...position, left: 10 };
        } else {
          return position;
        }
      }}
      arrowPosLeft={arrowPosLeft}
    >
      <InnerWrapper>
        {notifications.map(
          ({
            notificationIds,
            id,
            title,
            description,
            timeElapsedText,
            notificationType,
            documentIds,
          }) => (
            <TooltipNotification
              notificationIds={notificationIds}
              key={id}
              type="mobile-realtime"
              title={title}
              description={description}
              notificationType={notificationType}
              documentIds={documentIds}
              timeElapsedText={timeElapsedText}
              onNotificationClick={onNotificationClick}
              isMobile={isMobile}
              onDismissClick={onNotificationDismiss}
            />
          )
        )}
      </InnerWrapper>
    </StyledTooltip>
  );
};

export default MobileGlobalNotifications;
