import React from 'react';
import { Page } from 'pages/PagesList';

export type CurrentPageContextType = {
  currentPage: Page | null;
  setCurrentPage: (newPage: Page | null) => void;
};

const CurrentPageContext = React.createContext<CurrentPageContextType>({
  currentPage: null,
  setCurrentPage: () => {},
});

export default CurrentPageContext;
