import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import theme from 'styles/theme';

import FeatherIconHolder from 'atoms/FeatherIconHolder';

const DisabledLinkStyles = css`
  ${({ theme }) => `
     color: ${theme.colors.bambusBlue50};
     cursor: not-allowed;
  `}
`;

const StyledLink = styled(Link)<any>`
  display: grid;
  /* 20px is the empty space here :) */
  grid-template-columns: 18px 20px 1fr;
  grid-template-areas: 'icon . text';
  align-items: center;

  font-size: ${({ theme }) => theme.fontSizes.navigationText};
  font-weight: 400;

  margin-top: 27px;
  margin-top: ${(p) => p.$marginTop};

  color: ${(p) => p.theme.colors.white};

  ${({ $disabled }) => $disabled && DisabledLinkStyles}
`;

const TextWrapper = styled.span<any>`
  grid-area: text;
  font-weight: ${(p) => (p.active ? '700' : '400')};
`;

export type SecondaryNavigationLinkProps = {
  featherIcon?: React.ReactNode;
  href: string;
  active?: boolean;
  marginTop?: string;
  iconStyles?: object;
  disabled?: boolean;
  onClick?: (event: Event) => void;
};

/**
 * React Component for a Secondary Navigation Link
 */
const SecondaryNavigationLink = ({
  children,
  featherIcon,
  href = '',
  active = false,
  marginTop = '0px',
  iconStyles = {},
  disabled = false,
  onClick = () => {},
}: React.PropsWithChildren<SecondaryNavigationLinkProps>) => {
  const isActive = !disabled && active;
  return (
    <StyledLink
      to={href}
      $marginTop={marginTop}
      $disabled={disabled}
      onClick={
        disabled
          ? (e: Event) => {
              e.preventDefault();
            }
          : onClick
      }
    >
      {featherIcon && (
        <FeatherIconHolder
          gridAreaName="icon"
          padding="6px"
          backgroundColor={isActive ? theme.colors.bambusBlue90 : 'transparent'}
          borderRadius="50%"
          active={!disabled && isActive}
          iconFillColor={
            isActive ? theme.colors.white : theme.colors.bambusBlue50
          }
          style={iconStyles}
        >
          {featherIcon}
        </FeatherIconHolder>
      )}
      <TextWrapper active={isActive}>{children}</TextWrapper>
    </StyledLink>
  );
};

export default SecondaryNavigationLink;
