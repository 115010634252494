import React from 'react';
import styled from 'styled-components';

const Text = styled.span<any>`
  color: ${({ theme }) => theme.colors.bambusBlue50};
  font-weight: ${({ strong }) => (strong ? 600 : 400)};
  font-size: ${({ large }) => (large ? '1em' : '0.875em')};
`;

export type SubtitleTextProps = {
  strong?: boolean;
  large?: boolean;
};

const SubtitleText = ({
  children,
  strong = false,
  large = false,
}: React.PropsWithChildren<SubtitleTextProps>) => {
  return (
    <Text strong={strong} large={large}>
      {children}
    </Text>
  );
};

export default SubtitleText;
