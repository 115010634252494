import Axios, { Method as HTTPVerb } from 'axios';

import { deepTransformKeys } from 'utils/Object';
import { toCamelCase, toSnakeCase } from 'utils/Text';

import { sendFailedRequestAnalytics } from 'analytics/General';

import { ADMIN_SESSION_APPLICATION_ID_KEY } from 'constants/storage';

const adminApplicationId = sessionStorage.getItem(
  ADMIN_SESSION_APPLICATION_ID_KEY
);

const axios = Axios.create({
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers:
    adminApplicationId !== null
      ? { 'X-Bambus-Application-Id': adminApplicationId }
      : {},
});

/**
  Interceptor to catch some errors like status code 401 - no credentials/ session timeout, in case
  of which we redirect to login.
  All the errors are pushed to FE Analytics as well.
*/
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      sendFailedRequestAnalytics({
        url: error.config.url,
        httpMethod: error.config.method,
        responseBody: error.request.responseText,
        responseCode: error.request.statusText,
      });

      // If the status is 401, the session of the user most probably timed out, redirect
      // to login
      if (error.response.status === 401) {
        window.location.href = `${
          process.env.REACT_APP_AUTH_URL || 'https://auth.bambus.io'
        }`;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      sendFailedRequestAnalytics({
        url: error.config.url,
        httpMethod: error.config.method,
        responseBody: 'No response received.',
        responseCode: 'No response received.',
      });
    }
    return Promise.reject(error);
  }
);

const fileFormData = (body: any) => {
  const formData = new FormData();
  formData.append('file', body.file);
  return formData;
};

/**
 * Function that sends an HTTP request to the specified URL.
 * The request bodies are transfomed to snake case and the response bodies to camel case
 * @param path The URL to send the request to
 * @param method The HTTP verb (GET, POST, PUT, PATCH, DELETE)
 * @param body The body of the HTTP request (only for POST, PUT, PATCH, DELETE)
 */
export const request = (
  path: string,
  method: HTTPVerb = 'GET',
  body: any = null,
  transformKeysInResponseDisabled = false
) => {
  let bodyContent: { data?: any } = body
    ? { data: deepTransformKeys(body, toSnakeCase) }
    : {};

  if (body && body.file) {
    bodyContent = { data: fileFormData(body) };
  }

  return axios
    .request({
      url: path,
      method,
      ...bodyContent,
    })
    .then(({ data }) => {
      if (!transformKeysInResponseDisabled) {
        return deepTransformKeys(data, toCamelCase);
      } else {
        return data;
      }
    });
};
