import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import IsMobileContext from 'contexts/IsMobileContext';

import Footer from 'organisms/Footer';

const Wrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'content'
    'footer';
`;

type ContentWrapperProps = {
  isMobile: boolean;
  centerContent?: boolean;
};

const ContentWrapperDesktopStyles = css`
  /* Gradually increase the padding */
  ${(p) => `
 padding: ${p.theme.sizes.large} calc(${p.theme.sizes.small} + 8vw);
`}
`;

const InnerContentWrapper = styled.div<ContentWrapperProps>`
  grid-area: 'content';

  //The first padding serves as a fallback
  padding: ${(p) => p.theme.sizes.small};

  display: flex;
  ${({ centerContent }) => centerContent && `justify-content: center;`}

  ${(p) => !p.isMobile && ContentWrapperDesktopStyles}
`;

const InnerContent = styled.div`
  width: 100%;
  max-width: 60rem;
`;

export type MainContentWrapperProps = {
  children: React.ReactNode;
  centerContent?: boolean;
};

/**
 * React Wrapper component which wraps the main content to be displayed.
 * This is on:
 *  1. Desktop: the column next to the menu
 *  2. Mobile: the row below the mobile header
 */
const MainContentWrapper = ({
  children,
  centerContent = true,
}: MainContentWrapperProps) => {
  const isMobile = useContext(IsMobileContext);
  return (
    <Wrapper>
      <InnerContentWrapper isMobile={isMobile} centerContent={centerContent}>
        <InnerContent>{children}</InnerContent>
      </InnerContentWrapper>
      <Footer gridAreaName="footer" />
    </Wrapper>
  );
};

export default MainContentWrapper;
