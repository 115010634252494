import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import {
  Check,
  Upload,
  Clock,
  AlertTriangle,
  CornerDownRight,
} from 'react-feather';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { useIntl, FormattedMessage, IntlShape } from 'react-intl';

import { getDocumentRoute } from 'routes';

import theme from 'styles/theme';

import IsMobileContext from 'contexts/IsMobileContext';

import FeatherIconHolder from 'atoms/FeatherIconHolder';
import CaptionText from 'atoms/CaptionText';
import VerticalSpacer from 'atoms/VerticalSpacer';

import StatusIndicator from 'molecules/StatusIndicator';

import Note from './Note';

export enum DocumentStatus {
  Open = 'todo',
  Processing = 'processing',
  Accepted = 'accepted',
  Declined = 'declined',
  GraceEditable = 'editable',
}

const getIconByTypeAndStatus = (
  documentType: DocumentType,
  documentStatus: DocumentStatus
) => {
  // we only show a colored (red) icon when the document is clustered and declined
  if (
    documentType === 'cluster' &&
    documentStatus === DocumentStatus.Declined
  ) {
    return (
      <FeatherIconHolder gridAreaName="icon" iconFillColor={theme.colors.red}>
        <AlertTriangle />
      </FeatherIconHolder>
    );
  }

  switch (documentStatus) {
    case DocumentStatus.Accepted:
      return (
        <FeatherIconHolder
          gridAreaName="icon"
          iconFillColor={theme.colors.bambusBlue100}
        >
          <Check />
        </FeatherIconHolder>
      );
    case DocumentStatus.Processing:
      return (
        <FeatherIconHolder
          gridAreaName="icon"
          iconFillColor={theme.colors.bambusBlue100}
        >
          <Clock />
        </FeatherIconHolder>
      );
    case DocumentStatus.GraceEditable:
      return (
        <FeatherIconHolder
          gridAreaName="icon"
          iconFillColor={theme.colors.bambusBlue100}
        >
          <Clock />
        </FeatherIconHolder>
      );
    case DocumentStatus.Declined:
      return (
        <FeatherIconHolder
          gridAreaName="icon"
          iconFillColor={theme.colors.bambusBlue100}
        >
          <AlertTriangle />
        </FeatherIconHolder>
      );
    case DocumentStatus.Open:
    default:
      return (
        <FeatherIconHolder
          gridAreaName="icon"
          iconFillColor={theme.colors.bambusBlue100}
        >
          <Upload />
        </FeatherIconHolder>
      );
  }
};

const ClusterDocumentWrapper = styled.article<{
  shouldHaveBottomBorderRadius?: boolean;
}>`
  display: grid;
  grid-template-areas: 'icon . title . status-indicator';
  /* That 19px serves as a spacer */
  /* We need the minmax(0, 1fr) here so the title does not blow out */
  grid-template-columns: auto 19px minmax(0, 1fr) 10px auto;

  padding: ${(p) => p.theme.sizes.extraSmall} ${(p) => p.theme.sizes.small};
  border-top: 1px solid ${({ theme }) => theme.colors.bambusBlue10};
  background-color: ${({ theme }) => theme.colors.bambusBlue2dot5};

  &:hover {
    background-color: ${({ theme }) => theme.colors.bambusBlue5};
  }

  cursor: pointer;

  ${({ shouldHaveBottomBorderRadius, theme }) =>
    shouldHaveBottomBorderRadius &&
    `
    border-bottom-left-radius: ${theme.borderRadii.normal};
    border-bottom-right-radius: ${theme.borderRadii.normal};
  `}
`;

const ClusterIconOnHoverWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${ClusterDocumentWrapper}:hover & svg {
    stroke: ${({ theme }) => theme.colors.bambusBlue100};
  }
`;

const getClusterChildIcon = (documentStatus: DocumentStatus) => {
  switch (documentStatus) {
    case DocumentStatus.Declined:
      return (
        <FeatherIconHolder gridAreaName="icon" iconFillColor={theme.colors.red}>
          <CornerDownRight />
        </FeatherIconHolder>
      );
    default:
      return (
        <ClusterIconOnHoverWrapper
          style={{
            gridArea: 'icon',
          }}
        >
          <FeatherIconHolder iconFillColor={theme.colors.bambusBlue25}>
            <CornerDownRight />
          </FeatherIconHolder>
        </ClusterIconOnHoverWrapper>
      );
  }
};

export type DocumentType = 'single' | 'cluster';

const getStatusIndicatorByDocumentStatusAndType = (
  type: DocumentType,
  documentState: DocumentStatus,
  hideStatusText = false,
  hideStatusCircle = false,
  intl: IntlShape
) => {
  if (type === 'cluster') return null;

  switch (documentState) {
    case DocumentStatus.Accepted:
      return (
        <StatusIndicator
          indicatorColor={theme.colors.green}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.Accepted',
            defaultMessage: 'Akzeptiert',
          })}
          style={{
            gridArea: 'status-indicator',
          }}
          hideText={hideStatusText}
          hideCircle={hideStatusCircle}
        />
      );
    case DocumentStatus.Processing:
      return (
        <StatusIndicator
          indicatorColor={theme.colors.purple}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.InProcessing',
            defaultMessage: 'In Bearbeitung',
          })}
          style={{
            gridArea: 'status-indicator',
          }}
          hideText={hideStatusText}
          hideCircle={hideStatusCircle}
        />
      );
    case DocumentStatus.GraceEditable:
      return (
        <StatusIndicator
          indicatorColor={theme.colors.purple}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.InProcessing',
            defaultMessage: 'In Bearbeitung',
          })}
          style={{
            gridArea: 'status-indicator',
          }}
          hideText={hideStatusText}
          hideCircle={hideStatusCircle}
        />
      );
    case DocumentStatus.Declined:
      return (
        <StatusIndicator
          indicatorColor={theme.colors.red}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.Declined',
            defaultMessage: 'Abgelehnt',
          })}
          style={{
            gridArea: 'status-indicator',
          }}
          hideText={hideStatusText}
          hideCircle={hideStatusCircle}
        />
      );
    case DocumentStatus.Open:
    default:
      return (
        <StatusIndicator
          indicatorColor={theme.colors.bambusBlue25}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.Open',
            defaultMessage: 'Zu erledigen',
          })}
          style={{
            gridArea: 'status-indicator',
          }}
          hideText={hideStatusText}
          hideCircle={hideStatusCircle}
        />
      );
  }
};

export const DIM_ANIMATION_SECONDS = 10;

const Wrapper = styled.article<{ dim: boolean; showCursorPointer: boolean }>`
  /* Browser compatibility should be fine (except for IE of course) */
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.05));

  ${({ showCursorPointer }) => showCursorPointer && 'cursor: pointer;'}

  // dim 5 seconds then fade in
  @keyframes DimAndFadeIn {
    0% {
      filter: opacity(0.5);
    }

    50% {
      filter: opacity(0.5);
    }

    80% {
      filter: opacity(1) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1))
        drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.05));
    }

    100% {
      filter: opacity(1) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1))
        drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.05));
    }
  }
  ${({ dim }) =>
    dim &&
    `
    animation: DimAndFadeIn ${DIM_ANIMATION_SECONDS}s;
  `}
`;

const DocumentInfoWrapper = styled.section<{
  hideBottomBorderRadius?: boolean;
  showTopRedBorder?: boolean;
}>`
  position: relative;
  display: grid;
  grid-template-areas:
    'icon . title . status-indicator'
    '. . description description status-indicator';

  /* That 19px serves as a spacer */
  /* We need the minmax(0, 1fr) here so the title does not blow out */
  grid-template-columns: auto 19px minmax(0, 1fr) 10px auto;
  align-items: center;

  padding: ${(p) => p.theme.sizes.small};

  border-radius: ${(p) => p.theme.borderRadii.normal};
  /* If a note is shown, don't have a bottom border radius  */
  ${(p) =>
    p.hideBottomBorderRadius &&
    `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}

  ${({ showTopRedBorder, theme }) =>
    showTopRedBorder &&
    `
      &::after { 
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        border-top: 6px solid ${theme.colors.red};
        border-top-left-radius: ${theme.borderRadii.normal};
        border-top-right-radius: ${theme.borderRadii.normal};
      }
  `}

  background-color: ${(p) => p.theme.colors.white};
`;

const TitleHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Title = styled.h1`
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  color: ${(p) => p.theme.colors.bambusBlue100};
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: break-spaces;
  max-width: 100%;
`;

const AfterTitle = styled.span`
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  color: ${(p) => p.theme.colors.bambusBlue100};
`;

const DescriptionHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${({ theme }) => theme.sizes.mini};
`;

const Description = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.text};
  font-weight: 400;
  color: ${(p) => p.theme.colors.bambusBlue100};
`;

const AvailableAtText = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.text};
  font-weight: 400;
  color: ${(p) => p.theme.colors.bambusBlue100};
`;

const OptionalLabel = styled.div`
  position: absolute;
  z-index: 1;
  right: 20px;
  top: -14px;
  padding: ${(p) => p.theme.sizes.mini};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${(p) => p.theme.borderRadii.small};
  background-color: ${(p) => p.theme.colors.bambusBlue10};
`;

const ClusterTitle = styled.span`
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  color: ${(p) => p.theme.colors.bambusBlue100};
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: break-spaces;
  max-width: 100%;
`;

export type ClusterDocument = {
  id: string;
  title: string;
  status: DocumentStatus;
};

export type DocumentProps = {
  type?: DocumentType;
  clusterDocuments?: ClusterDocument[];
  id: string;
  title: string;
  description?: string;
  association?: string;
  availableAt?: string;
  period?: string;
  isOptional?: boolean;
  note?: string | null;
  status: DocumentStatus;
  dim?: boolean;
};

/**
 * React component that represents one document (single or clustered).
 * The afterTitle (association) and description are hidden on mobile.
 */
const Document = ({
  type = 'single',
  clusterDocuments = [],
  id,
  title = '',
  association = '',
  description = '',
  availableAt = '',
  period = '',
  isOptional = false,
  note = null,
  status,
  dim = false,
  history,
}: DocumentProps & RouteComponentProps) => {
  const isMobile = useContext(IsMobileContext);

  const onDocumentClick = useCallback(() => {
    history.push(getDocumentRoute(id), {
      cameByDocumentClick: true,
    });
  }, [history, id]);

  const onClusterDocumentChildClick = useCallback(
    (documentId) => {
      history.push(getDocumentRoute(documentId), {
        cameByDocumentClick: true,
      });
    },
    [history]
  );

  const intl = useIntl();

  return (
    <Wrapper
      dim={dim}
      data-dimmed={dim ? 'true' : 'false'}
      showCursorPointer={type === 'single'}
      {...(type === 'single' && {
        onClick: onDocumentClick,
      })}
    >
      <DocumentInfoWrapper
        hideBottomBorderRadius={
          !!note ||
          !!period ||
          (type === 'cluster' && clusterDocuments.length > 0)
        }
        showTopRedBorder={status === DocumentStatus.Declined}
      >
        {getIconByTypeAndStatus(type, status)}
        <TitleHolder style={{ gridArea: 'title' }}>
          <Title>{`${title}${association ? ' ' : ''}`}</Title>
          {association && (
            <>
              <AfterTitle>{`${intl.formatMessage({
                id: 'Of',
                defaultMessage: 'von',
              })} ${association}`}</AfterTitle>
            </>
          )}
        </TitleHolder>
        {getStatusIndicatorByDocumentStatusAndType(
          type,
          status,
          isMobile,
          !isMobile,
          intl
        )}
        {isOptional && (
          <OptionalLabel>
            <CaptionText small>
              <FormattedMessage id="Optional" defaultMessage="Optional" />
            </CaptionText>
          </OptionalLabel>
        )}
        {!isMobile && type === 'single' && (description || availableAt) && (
          <DescriptionHolder style={{ gridArea: 'description' }}>
            {description && <Description>{description}</Description>}
            {availableAt && (
              <>
                {/* The same spacing is used as the padding of DescriptionHolder */}
                {description && <VerticalSpacer space={theme.sizes.mini} />}
                <AvailableAtText>
                  <FormattedMessage
                    id="AvailableAt"
                    defaultMessage="Erhältlich bei"
                  />
                  {`: ${availableAt}`}
                </AvailableAtText>
              </>
            )}
          </DescriptionHolder>
        )}
      </DocumentInfoWrapper>
      {type === 'cluster' &&
        clusterDocuments.map(({ id, title, status }, index) => (
          <ClusterDocumentWrapper
            key={id}
            onClick={() => {
              onClusterDocumentChildClick(id);
            }}
            shouldHaveBottomBorderRadius={
              index === clusterDocuments.length - 1 && !note
            }
          >
            {getClusterChildIcon(status)}
            <ClusterTitle style={{ gridArea: 'title' }}>{title}</ClusterTitle>
            {getStatusIndicatorByDocumentStatusAndType(
              'single',
              status,
              isMobile,
              !isMobile,
              intl
            )}
          </ClusterDocumentWrapper>
        ))}
      {(note || period) && (
        <Note note={note} period={period} hidePeriod={type === 'cluster'} />
      )}
    </Wrapper>
  );
};

export default withRouter(Document);
