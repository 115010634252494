import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Mail, Phone } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BambusLogo } from 'illustrations/BambusBlueLogoWithName.svg';
import theme from 'styles/theme';
import { getOnboardingDetails } from 'api/requests';

const StyledFooter = styled.footer<{
  gridAreaName: string;
}>`
  grid-area: ${({ gridAreaName }) => gridAreaName};
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'logo .'
    'phone-number email'
    'data-protection imprint';
  row-gap: 20px;
  column-gap: 3px;

  padding: 20px;

  & .icon {
    display: none;
  }

  background: ${({ theme }) => theme.colors.bambusBlue10};

  @media (min-width: 750px) {
    grid-template-rows: auto;
    grid-template-columns: auto auto auto 1fr auto auto;
    grid-template-areas: 'logo phone-number email . data-protection imprint';
    column-gap: 2vw;
    //playing around with the padding here
    padding: 40px min(4vw, 60px);

    & .icon {
      display: block;
    }
  }
`;

const FooterLink = styled.a`
  color: ${({ theme }) => theme.colors.bambusBlue100};
  text-decoration: none;

  font-size: 0.85rem;
  @media (min-width: 330px) {
    font-size: 1rem;
  }
  white-space: nowrap;
`;

const IconAndCommunicationOptionHolder = styled.div`
  display: flex;
  align-items: center;
  & .icon {
    margin-right: 10px;
  }
`;

const Footer = ({ gridAreaName }: { gridAreaName: string }) => {
  const [country, setCountry] = useState<'DE' | 'AT'>('DE');

  useEffect(() => {
    (async () => {
      const result = await getOnboardingDetails();
      if (result.application?.language?.toLowerCase() !== 'de') return;

      console.log(result);
      setCountry(
        result.application?.language_variant?.toLowerCase() === 'at'
          ? 'AT'
          : 'DE'
      );
    })();
  }, []);

  return (
    <StyledFooter gridAreaName={gridAreaName}>
      <div style={{ gridArea: 'logo' }}>
        <BambusLogo />
      </div>
      <FooterLink
        href={country === 'AT' ? 'tel:0043800007171' : 'tel:004930568373000'}
        style={{ gridArea: 'phone-number' }}
      >
        <IconAndCommunicationOptionHolder>
          <Phone
            size={16}
            className="icon"
            color={theme.colors.bambusBlue100}
          />
          {country === 'AT' ? (
            <span>+43 800 00 7171</span>
          ) : (
            <span>+49 30 837 960 40</span>
          )}
        </IconAndCommunicationOptionHolder>
      </FooterLink>
      <FooterLink
        href="mailto:beratung@bambus.io"
        style={{ gridArea: 'email' }}
      >
        <IconAndCommunicationOptionHolder>
          <Mail size={16} className="icon" color={theme.colors.bambusBlue100} />
          <span>
            <FormattedMessage
              id="footer.email_address"
              defaultMessage="beratung@bambus.io"
            />
          </span>
        </IconAndCommunicationOptionHolder>
      </FooterLink>
      <FooterLink
        href="https://www.bambus.io/datenschutz/"
        target="_blank"
        style={{ gridArea: 'data-protection' }}
      >
        <FormattedMessage
          id="footer.menu.dataProtection"
          defaultMessage="Datenschutz"
        />
      </FooterLink>
      <FooterLink
        href="https://www.bambus.io/impressum/"
        target="_blank"
        style={{ gridArea: 'imprint' }}
      >
        <FormattedMessage id="footer.menu.imprint" defaultMessage="Impressum" />
      </FooterLink>
    </StyledFooter>
  );
};

export default Footer;
