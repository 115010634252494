import en from './en.json';
import de from './de-DE.json';

export type TranslationKeys = {
  en: any;
  'de-DE': any;
};

const translations: TranslationKeys = {
  en,
  'de-DE': de,
};
export default translations;

/**
 * The message translation files are stored as nested JSON objects, but
 * react-intl does not support nesting, so whenever using the
 * translation files they must be flattened via this function and passed
 * in that form to the IntlProvider of react-intl.
 *
 * @param nestedMessages The content of the translation files e.g. de.json
 * @param prefix Optional prefix for the message keys
 */
export function flattenMessages(nestedMessages: any, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: any, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}
