import React, { useEffect, useRef, useState, useContext } from 'react';
import { useQuery } from 'react-query';

import history from 'utils/History';

import {
  Notification,
  ClusteredNotification,
  isNotificationClustered,
} from 'models/Notifications';

import {
  getNotifications,
  archiveNotifications,
  archiveNotification,
  markAllNotificationsAsSeen,
} from 'api/requests';

import NotificationsContext from 'contexts/NotificationsContext';
import routes from 'routes';
import AuthContext from 'contexts/AuthContext';

// Get sorted notifications by time of creation
const getSortedNotificationsByCreationTime = (
  notifications: Notification[]
) => {
  return [...notifications].sort((notification1, notification2) => {
    return (
      new Date(notification2.createdAt).getTime() -
      new Date(notification1.createdAt).getTime()
    );
  });
};

const getCreatedAtForNotification = (
  notification: Notification | ClusteredNotification
): string | undefined => {
  if (isNotificationClustered(notification)) {
    return getSortedNotificationsByCreationTime(notification.clusterList)[0]
      ?.createdAt;
  } else {
    return notification.createdAt;
  }
};

/**
 * Component that deals with managing the Notifications logic & data in the Notifications React context
 */
const NotificationsManager = ({ children }: React.PropsWithChildren<{}>) => {
  const { isAuthenticated } = useContext(AuthContext);

  // notifications are automatically refetched every 5s
  const {
    data: allNotifications,
    isFetched: areNotificationsFetched,
    refetch: refetchNotifications,
  } = useQuery<(Notification | ClusteredNotification)[]>(
    'notifications',
    getNotifications,
    {
      refetchInterval: 5000,
      enabled: isAuthenticated,
    }
  );

  const [numberOfUnseenNotifications, setNumberOfUnseenNotifications] =
    useState<number>(0);

  const [activeNotifications, setActiveNotifications] = useState<
    (Notification | ClusteredNotification)[]
  >([]);

  // Storing all the IDs of the notifications that already have been fetched so we can compare to
  // the notifications after every fetch to identify new ones that we show differently in the UI
  const newNotificationsIdsMapRef = useRef<any | null>(null);

  // The notifications that appear realtime
  const [newNotifications, setNewNotifications] = useState<
    (Notification | ClusteredNotification)[]
  >([]);

  useEffect(() => {
    if (areNotificationsFetched) {
      // -----------------------------------
      // setting number of new notifications
      setNumberOfUnseenNotifications(
        allNotifications?.reduce(
          (currentNrOfNewNotifications, notification) => {
            // if the notification is clustered then go through all inner notifications to see if any one of them has state "unseen"
            // otherwise just look at the state
            if (isNotificationClustered(notification)) {
              if (
                notification.clusterList.some(
                  (notification) => notification.state === 'unseen'
                )
              ) {
                return currentNrOfNewNotifications + 1;
              } else {
                return currentNrOfNewNotifications;
              }
            } else {
              if (notification.state === 'unseen') {
                return currentNrOfNewNotifications + 1;
              } else {
                return currentNrOfNewNotifications;
              }
            }
          },
          0
        ) ?? 0
      );
      // -----------------------------------

      // -----------------------------------
      // setting the notifications to show (filtering and ordering by creation date)
      const filteredAndSortedNotifications =
        allNotifications
          ?.filter((notification) => {
            if (isNotificationClustered(notification)) {
              // if any of the cluster notifications has different state than "archived" then we keep the cluster
              return notification.clusterList.some(
                (notification) => notification.state !== 'archived'
              );
            } else {
              return notification.state !== 'archived';
            }
          })
          .sort((notification1, notification2) => {
            const creationTimeNotification1 =
              getCreatedAtForNotification(notification1);
            const creationTimeNotification2 =
              getCreatedAtForNotification(notification2);

            return (
              new Date(creationTimeNotification2 ?? new Date()).getTime() -
              new Date(creationTimeNotification1 ?? new Date()).getTime()
            );
          }) ?? [];

      setActiveNotifications(filteredAndSortedNotifications);
      // -----------------------------------

      // -----------------------------------
      // see which notifications are new (i.e. present in the current fetch but weren't here before).
      // These are the "real time" notifications! For this we use the map variable (as a ref and not state to not trigger rerendering)
      const newNotifications = filteredAndSortedNotifications.filter(
        (notification) => {
          if (isNotificationClustered(notification)) {
            return notification.clusterList.some(
              (notification) =>
                newNotificationsIdsMapRef.current?.[notification.id] ===
                undefined
            );
          } else {
            return (
              newNotificationsIdsMapRef.current?.[notification.id] === undefined
            );
          }
        }
      );

      // seeing wether there is a new cluster of each type in order to delete the old cluster or
      // single notification
      let isThereANewClusterOfTypeAdded = false;
      let isThereANewClusterOfTypeAvailableDownload = false;
      let isThereANewClusterOfTypeAvailableDownloadAndSign = false;
      let isThereANewClusterOfTypeAccepted = false;
      let isThereANewClusterOfTypeDeclined = false;
      for (const notification of newNotifications) {
        if (isNotificationClustered(notification)) {
          if (notification.clusterName === 'added') {
            isThereANewClusterOfTypeAdded = true;
          }
          if (notification.clusterName === 'available_download') {
            isThereANewClusterOfTypeAvailableDownload = true;
          }
          if (notification.clusterName === 'available_download_and_sign') {
            isThereANewClusterOfTypeAvailableDownloadAndSign = true;
          }
          if (notification.clusterName === 'accepted') {
            isThereANewClusterOfTypeAccepted = true;
          }
          if (notification.clusterName === 'declined') {
            isThereANewClusterOfTypeDeclined = true;
          }
        }
      }

      // if this is the first time we fetched (newNotificationsIdsMap is null) then
      // don't set the notifications as new!
      if (newNotificationsIdsMapRef.current === null) {
        // do nothing just initialize the map, as all the notifications came non-realtime
        newNotificationsIdsMapRef.current = {};
      } else {
        setNewNotifications((currentNewNotifications) => {
          // first of all we're filtering for the elements that are still up to date
          const updatedNewNotificationsWithoutOutdatedNotifications =
            currentNewNotifications.filter((currentNewNotification) => {
              let notificationType;
              if (isNotificationClustered(currentNewNotification)) {
                notificationType = currentNewNotification.clusterName;
              } else {
                notificationType = currentNewNotification.notificationType;
              }
              if (
                notificationType === 'added' &&
                isThereANewClusterOfTypeAdded
              ) {
                return false;
              }
              if (
                notificationType === 'available_download' &&
                isThereANewClusterOfTypeAvailableDownload
              ) {
                return false;
              }
              if (
                notificationType === 'available_download_and_sign' &&
                isThereANewClusterOfTypeAvailableDownloadAndSign
              ) {
                return false;
              }
              if (
                notificationType === 'accepted' &&
                isThereANewClusterOfTypeAccepted
              ) {
                return false;
              }
              if (
                notificationType === 'declined' &&
                isThereANewClusterOfTypeDeclined
              ) {
                return false;
              }
              return true;
            });

          //merge the updated "old-new (realtime)" notifications and the new ones
          return [
            ...newNotifications,
            ...updatedNewNotificationsWithoutOutdatedNotifications,
          ];
        });
      }

      newNotifications.forEach((notification) => {
        if (isNotificationClustered(notification)) {
          notification.clusterList.forEach((notification) => {
            newNotificationsIdsMapRef.current[notification.id] = true;
          });
        } else {
          newNotificationsIdsMapRef.current[notification.id] = true;
        }
      });
    }
    // -----------------------------------
  }, [allNotifications, areNotificationsFetched]);

  const removeNotificationFromNewNotifications = (
    notificationIds: string[]
  ) => {
    if (notificationIds.length > 1) {
      // we have a clustered notification
      setNewNotifications((newNotifications) => {
        return newNotifications.filter((notification) => {
          if (isNotificationClustered(notification)) {
            return !notification.clusterList.some(
              (clusterNotificationChild) =>
                clusterNotificationChild.id === notificationIds[0]
            );
          } else {
            return true;
          }
        });
      });
    } else {
      // simple notifications
      setNewNotifications((newNotifications) => {
        return newNotifications.filter((notification) => {
          if (isNotificationClustered(notification)) {
            return true;
          } else {
            return notification.id !== notificationIds[0];
          }
        });
      });
    }
  };

  const clearAllNewNotifications = () => {
    setNewNotifications([]);
  };

  const onNotificationClick = async (
    documentIds: (string | number)[],
    notificationIds: string[]
  ) => {
    removeNotificationFromNewNotifications(notificationIds);

    if (notificationIds.length === 1) {
      // single notification
      await archiveNotification(notificationIds[0]);
    } else {
      // clustered notification
      await archiveNotifications(notificationIds);
    }
    refetchNotifications();

    history.push(routes.documentsRoute, {
      documentIdsToHiglight: documentIds,
    });
  };

  const onNotificationDismiss = (notificationIds: string[]) => {
    removeNotificationFromNewNotifications(notificationIds);
  };

  const markAllNotificationsSeen = async () => {
    await markAllNotificationsAsSeen();
    refetchNotifications();
  };

  return (
    <NotificationsContext.Provider
      value={{
        allNotifications: allNotifications ?? [],
        activeNotifications,
        newNotifications,
        onNotificationClick,
        onNotificationDismiss,
        markAllNotificationsSeen,
        clearAllNewNotifications,
        numberOfNewNotifications: numberOfUnseenNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsManager;
