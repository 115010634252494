import React from 'react';
import ReactDOM from 'react-dom';
import { trackEvent } from 'analytics/Matomo';
import { trackEventGTM } from 'analytics/GTM';
import { Metric } from 'web-vitals';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

function sendToAnalytics({ name, delta, id, value }: Metric) {
  trackEventGTM({
    event: 'web-vitals',
    webVitalsMeasurement: {
      name,
      id,
      value,
      delta,
      valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
      deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
    },
  });
  trackEvent({
    eventCategory: 'Web Vitals',
    action: name,
    name: `Delta: ${delta}`,
    value: value,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);
