import React from 'react';

import theme from 'styles/theme';

type CircleProps = {
  color?: string;
};

const Circle = ({ color = theme.colors.white }: CircleProps) => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.5" cy="5.4895" r="4.5" fill={color} />
    </svg>
  );
};

export default Circle;
