import React from 'react';
import styled from 'styled-components';
import { Clock } from 'react-feather';

import theme from 'styles/theme';

import FeatherIconHolder from 'atoms/FeatherIconHolder';
import { FormattedMessage } from 'react-intl';

const NoteWrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: ${(p) => p.theme.sizes.extraSmall};

  background-color: ${(p) => p.theme.colors.bambusBlue75};
  border-radius: ${({ theme }) => theme.borderRadii.small};
`;

const Message = styled.span`
  color: ${(p) => p.theme.colors.white};
  font-size: 0.875em;
  line-height: 21px;
  margin-left: ${(p) => p.theme.sizes.small};
`;

const DocumentUploadedSuccessfullyMessage = () => {
  return (
    <NoteWrapper>
      <FeatherIconHolder
        iconFillColor={theme.colors.white}
      >
        <Clock />
      </FeatherIconHolder>
      <Message>
        <FormattedMessage
          id="DocumentUploadedSuccessfullyMessage"
          defaultMessage="Vielen Dank! Ihre Unterlagen wurden hochgeladen und werden nun von unserem Finanzierungsteam geprüft."
        />
      </Message>
    </NoteWrapper>
  );
};

export default DocumentUploadedSuccessfullyMessage;
