import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Formik, Field, Form, FieldProps, FormikProps } from 'formik';
import * as Yup from 'yup';
import { PasswordInput, ValidationMessage } from 'bambus-ui-components';
import theme from 'styles/theme';

import VerticalSpacer from 'atoms/VerticalSpacer';

import { changePassword } from 'api/requests';

const MIN_8_CHARS_ERROR_KEY = 'MIN_8_CHARS_ERROR';
const NOT_ONLY_NUMERIC_ERROR_KEY = 'NOT_ONLY_NUMERIC_ERROR';
const CONTAINS_ALSO_SPECIAL_CHARACTER_ERROR_KEY =
  'CONTAINS_ALSO_SPECIAL_CHARACTER_ERROR';
const PASSWORDS_DONT_MATCH_ERROR_KEY = 'PASSWORDS_DONT_MATCH_ERROR';

const PASSWORD_TOO_COMMON_ERROR_KEY = 'PASSWORD_TOO_COMMON_ERROR';
const PASSWORD_TOO_COMMON_BACKEND_ERROR_MESSAGE =
  'Dieses Passwort ist zu üblich.';

const PASSWORD_AS_USERNAME_ERROR_KEY = 'PASSWORD_AS_USERNAME_ERROR';
const PASSWORD_AS_USERNAME_BACKEND_ERROR_MESSAGE =
  'Das Passwort ist zu ähnlich zu username.';

const CURRENT_PASSWORD_SUPPLIED_IS_NOT_CORRECT_ERROR_KEY =
  'CURRENT_PASSWORD_SUPPLIED_IS_NOT_CORRECT_ERROR';
const CURRENT_PASSWORD_SUPPLIED_IS_NOT_CORRECT_ERROR_MESSAGE =
  'Bad request: wrong current password';

const BACKEND_ERROR_MESSAGES_KEYS_MAP: any = {
  [PASSWORD_TOO_COMMON_BACKEND_ERROR_MESSAGE]: PASSWORD_TOO_COMMON_ERROR_KEY,
  [PASSWORD_AS_USERNAME_BACKEND_ERROR_MESSAGE]: PASSWORD_AS_USERNAME_ERROR_KEY,
  [CURRENT_PASSWORD_SUPPLIED_IS_NOT_CORRECT_ERROR_MESSAGE]:
    CURRENT_PASSWORD_SUPPLIED_IS_NOT_CORRECT_ERROR_KEY,
};

type PasswordChangeFormValues = {
  currentPassword?: string;
  password?: string;
  passwordConfirmation?: string;
};

const ConstraintMessagesHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * Initial state is undefined (before the user touches the password field).
 */
const InitialPasswordValidationState = {
  [MIN_8_CHARS_ERROR_KEY]: undefined,
  [NOT_ONLY_NUMERIC_ERROR_KEY]: undefined,
  [CONTAINS_ALSO_SPECIAL_CHARACTER_ERROR_KEY]: undefined,
  [PASSWORD_TOO_COMMON_ERROR_KEY]: undefined,
  [PASSWORD_AS_USERNAME_ERROR_KEY]: undefined,
  [PASSWORDS_DONT_MATCH_ERROR_KEY]: undefined,
  [CURRENT_PASSWORD_SUPPLIED_IS_NOT_CORRECT_ERROR_KEY]: undefined,
};

export type CustomPasswordInputPropsType = {
  customPasswordInputProps?: object;
};

export type ChildrenArgumentsType = {
  ContraintMessages: React.ReactNode;
  TextPasswordChangedSuccessfully: React.ReactNode;
  GetCurrentPasswordField: (
    props?: CustomPasswordInputPropsType
  ) => React.ReactNode;
  GetPasswordField: (props?: CustomPasswordInputPropsType) => React.ReactNode;
  GetPasswordConfirmationField: (
    props?: CustomPasswordInputPropsType
  ) => React.ReactNode;
  isValid: boolean;
  dirty: boolean;
};

export type PasswordSetFormProps = {
  children: (props: ChildrenArgumentsType) => React.ReactNode;
  onSuccessfulPasswordChange?: () => void;
};

const PasswordSetForm = ({
  children: renderChildren,
  onSuccessfulPasswordChange,
}: PasswordSetFormProps) => {
  const [passwordValidationState, setPasswordValidationState] = useState<any>(
    InitialPasswordValidationState
  );

  const [isPasswordChangedSuccessfully, setIsPasswordChangedSuccessfully] =
    useState<boolean>(false);

  const [wasPasswordConfirmationTouched, setWasPasswordConfirmationTouched] =
    useState<boolean>(false);

  const ContraintMessages = (
    <ConstraintMessagesHolder>
      <ValidationMessage
        isValid={passwordValidationState[MIN_8_CHARS_ERROR_KEY] === false}
        hasError={passwordValidationState[MIN_8_CHARS_ERROR_KEY] === true}
      >
        <FormattedMessage
          id="PasswordSet.PasswordMustBeAtLeast8Characters"
          defaultMessage=" Das Passwort muss mindestens acht Zeichen lang sein."
        />
      </ValidationMessage>
      <VerticalSpacer space={theme.sizes.mini} />
      <ValidationMessage
        isValid={passwordValidationState[NOT_ONLY_NUMERIC_ERROR_KEY] === false}
        hasError={passwordValidationState[NOT_ONLY_NUMERIC_ERROR_KEY] === true}
      >
        <FormattedMessage
          id="PasswordSet.PasswordMustNotBeOnlyNumbers"
          defaultMessage="Das Passwort darf nicht nur aus Zahlen bestehen."
        />
      </ValidationMessage>
      <VerticalSpacer space={theme.sizes.mini} />
      <ValidationMessage
        isValid={
          passwordValidationState[CONTAINS_ALSO_SPECIAL_CHARACTER_ERROR_KEY] ===
          false
        }
        hasError={
          passwordValidationState[CONTAINS_ALSO_SPECIAL_CHARACTER_ERROR_KEY] ===
          true
        }
      >
        <FormattedMessage
          id="PasswordSet.PasswordMustContainASpecialCharacter"
          defaultMessage="Das Passwort muss mindestens ein Sonderzeichen (z.B. *, _, !) beinhalten."
        />
      </ValidationMessage>

      {passwordValidationState[PASSWORDS_DONT_MATCH_ERROR_KEY] === true &&
        wasPasswordConfirmationTouched && (
          <>
            <VerticalSpacer space={theme.sizes.mini} />
            <ValidationMessage hasError>
              <FormattedMessage
                id="PasswordSet.PasswordsMustMatch"
                defaultMessage="Diese Passwörter stimmen nicht überein."
              />
            </ValidationMessage>
          </>
        )}

      {passwordValidationState[PASSWORD_TOO_COMMON_ERROR_KEY] === true && (
        <>
          <VerticalSpacer space={theme.sizes.mini} />
          <ValidationMessage hasError>
            <FormattedMessage
              id="PasswordSet.PasswordTooCommon"
              defaultMessage="Das Passwort entspricht einem häufig verwendeten Wort. Bitte wählen Sie ein anderes aus."
            />
          </ValidationMessage>
        </>
      )}
      {passwordValidationState[PASSWORD_AS_USERNAME_ERROR_KEY] === true && (
        <>
          <VerticalSpacer space={theme.sizes.mini} />
          <ValidationMessage hasError>
            <FormattedMessage
              id="PasswordSet.PasswordSimilarToUsername"
              defaultMessage="Das Passwort beinhaltet Ihren Namen, Email, etc. Bitte wählen Sie ein anderes."
            />
          </ValidationMessage>
        </>
      )}
      {passwordValidationState[
        CURRENT_PASSWORD_SUPPLIED_IS_NOT_CORRECT_ERROR_KEY
      ] === true && (
        <>
          <VerticalSpacer space={theme.sizes.mini} />
          <ValidationMessage hasError>
            <FormattedMessage
              id="PasswordSet.CurrentPasswordNotCorrect"
              defaultMessage="Sie haben ein ungültiges Passwort eingegeben."
            />
          </ValidationMessage>
        </>
      )}
    </ConstraintMessagesHolder>
  );

  const TextPasswordChangedSuccessfully = isPasswordChangedSuccessfully && (
    <>
      <VerticalSpacer space={theme.sizes.mini} />
      <ValidationMessage isValid>
        <FormattedMessage
          id="PasswordSet.PasswordChangedSuccessfully"
          defaultMessage="Ihr Passwort wurde geändert."
        />
      </ValidationMessage>
    </>
  );

  const GetCurrentPasswordField = (props?: CustomPasswordInputPropsType) => (
    <Field type="text" name="currentPassword">
      {({ field }: FieldProps) => (
        <FormattedMessage
          id="Auth.CurrentPassword"
          defaultMessage="Aktuelles Passwort"
        >
          {(placeholder) => (
            <PasswordInput
              {...field}
              onChange={(event: any) => {
                field.onChange(event);
              }}
              placeholder={String(placeholder)}
              {...(props?.customPasswordInputProps || {})}
            />
          )}
        </FormattedMessage>
      )}
    </Field>
  );

  const GetPasswordField = (props?: CustomPasswordInputPropsType) => (
    <Field type="text" name="password">
      {({ field }: FieldProps) => (
        <FormattedMessage id="Auth.NewPassword" defaultMessage="Neues Passwort">
          {(placeholder) => (
            <PasswordInput
              {...field}
              onChange={(event: any) => {
                field.onChange(event);
              }}
              placeholder={String(placeholder)}
              {...(props?.customPasswordInputProps || {})}
            />
          )}
        </FormattedMessage>
      )}
    </Field>
  );

  const GetPasswordConfirmationField = (
    props?: CustomPasswordInputPropsType
  ) => (
    <Field type="text" name="passwordConfirmation">
      {({ field }: FieldProps) => (
        <FormattedMessage
          id="Auth.PasswordConfirmation"
          defaultMessage="Passwort wiederholen"
        >
          {(placeholder) => (
            <PasswordInput
              {...field}
              placeholder={String(placeholder)}
              {...(props?.customPasswordInputProps || {})}
            />
          )}
        </FormattedMessage>
      )}
    </Field>
  );

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
      }}
      onSubmit={async (
        { currentPassword, password, passwordConfirmation },
        { resetForm }
      ) => {
        try {
          setPasswordValidationState(InitialPasswordValidationState);
          await changePassword({
            currentPassword,
            password,
            repeatPassword: passwordConfirmation,
          });
          setIsPasswordChangedSuccessfully(true);
          resetForm();
          onSuccessfulPasswordChange && onSuccessfulPasswordChange();
        } catch (error: any) {
          if (Array.isArray(error.response?.data)) {
            const errors = error.response.data;
            const serverValidationErrors = errors.reduce(
              (prev: object, current: string) => ({
                ...prev,
                [BACKEND_ERROR_MESSAGES_KEYS_MAP[current]]: true,
              }),
              {}
            );
            setPasswordValidationState((passwordValidationState: any) => ({
              ...passwordValidationState,
              ...serverValidationErrors,
            }));
          }
        }
      }}
      validate={(values) => {
        if (values.passwordConfirmation !== '') {
          setWasPasswordConfirmationTouched(true);
        }
        const schema = Yup.object().shape({
          currentPassword: Yup.string().required(),
          password: Yup.string()
            .min(8, MIN_8_CHARS_ERROR_KEY)
            .matches(/(?!^\d+$)^.+$/, NOT_ONLY_NUMERIC_ERROR_KEY)
            .matches(/[^A-Za-z0-9]/, CONTAINS_ALSO_SPECIAL_CHARACTER_ERROR_KEY)
            .required(),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password')], PASSWORDS_DONT_MATCH_ERROR_KEY)
            .required(),
        });
        const noErrorsState = {
          [MIN_8_CHARS_ERROR_KEY]: false,
          [NOT_ONLY_NUMERIC_ERROR_KEY]: false,
          [CONTAINS_ALSO_SPECIAL_CHARACTER_ERROR_KEY]: false,
          [PASSWORDS_DONT_MATCH_ERROR_KEY]: false,
        };
        return schema
          .validate(values, { abortEarly: false })
          .then(() => {
            setPasswordValidationState(noErrorsState);
          })
          .catch((err) => {
            const errorsByKey = err.errors.reduce(
              (prev: object, current: string) => ({ ...prev, [current]: true }),
              {}
            );
            setPasswordValidationState({ ...noErrorsState, ...errorsByKey });
            return err;
          });
      }}
    >
      {({ isValid, dirty }: FormikProps<PasswordChangeFormValues>) => (
        <Form>
          {renderChildren({
            ContraintMessages,
            TextPasswordChangedSuccessfully,
            GetCurrentPasswordField,
            GetPasswordField,
            GetPasswordConfirmationField,
            isValid,
            dirty,
          })}
        </Form>
      )}
    </Formik>
  );
};

export default PasswordSetForm;
