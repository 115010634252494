const endpoints = {
  logout: `${process.env.REACT_APP_API_URL}/api/v1/auth/logout/`,
  changePassword: `${process.env.REACT_APP_API_URL}/api/v1/auth/change-password/`,
  documents: `${process.env.REACT_APP_API_URL}/api/v1/cockpit/documents/`,
  userDetails: `${process.env.REACT_APP_API_URL}/api/v1/cockpit/user-details/`,
  notifications: `${process.env.REACT_APP_API_URL}/api/v1/cockpit/notifications/`,
  onboardingDetails: `${process.env.REACT_APP_API_URL}/api/v2/onboarding/details/`,
};

export const getDocumentDetailsURL = (documentId: any) =>
  `${process.env.REACT_APP_API_URL}/api/v1/cockpit/documents/${documentId}/`;

export const getDocumentFileUploadSignedURL = (documentId: any) =>
  `${process.env.REACT_APP_API_URL}/api/v1/cockpit/documents/${documentId}/user-files-put/`;

export const getDeleteDocumentFileURL = (documentId: string, fileId: string) =>
  `${process.env.REACT_APP_API_URL}/api/v1/cockpit/documents/${documentId}/user-files/${fileId}/`;

export const getDeleteAllDocumentFilesURL = (documentId: string) =>
  `${process.env.REACT_APP_API_URL}/api/v1/cockpit/documents/${documentId}/user-files/all/`;

export const getConcatenationURL = (documentId: string) =>
  `${process.env.REACT_APP_API_URL}/api/v1/cockpit/documents/${documentId}/concatenate/`;

export const getReorderURL = (documentId: string) =>
  `${process.env.REACT_APP_API_URL}/api/v1/cockpit/documents/${documentId}/reorder/`;

export const getConfirmDocumentURL = (documentId: string) =>
  `${process.env.REACT_APP_API_URL}/api/v1/cockpit/documents/${documentId}/upload-confirmation/`;

export const getNotificationURL = (notificationId: string) =>
  `${process.env.REACT_APP_API_URL}/api/v1/cockpit/notifications/${notificationId}/`;

export default endpoints;
