import { History } from 'history';

import { getUserDetails } from 'api/requests';
import routes from 'routes';

type Params = {
  history: History;
  userHasAccessCallback?: () => any;
  redirectURLIfUserHasAccess?: string;
};

/**
 * Checks:
 * 1. Whether the user has a valid session (if not, redirect to login)
 * 2. Whether the user has access to Cockpit (if not, redirect to Onboarding or show waiting screen based on
 *  Onboarding complete)
 *
 * If the user has Cockpit access, the userHasAccessCallback callback is executed and
 * a routing to redirectURLIfUserHasAccess is executed via history.replace
 */
export const checkIfUserHasAccessToCockpit = async ({
  history,
  userHasAccessCallback,
  redirectURLIfUserHasAccess,
}: Params) => {
  try {
    const { cockpitEnabled = false, onboardingComplete = false } =
      await getUserDetails();

    /**
     * If the user doesn't have Cockpit enabled & hasn't finished Onboarding
     * then navigate to Onboarding
     */
    if (!cockpitEnabled && !onboardingComplete) {
      window.location.replace(
        process.env.REACT_APP_ONBOARDING_URL || 'https://onboarding.bambus.io'
      );
      return;
    }

    /**
     * If the user doesn't have Cockpit enabled but has finished Onboarding
     * then show a message
     */
    if (!cockpitEnabled && onboardingComplete) {
      history.replace(routes.applicationBeingProcessedRoute);
    } else {
      redirectURLIfUserHasAccess && history.replace(redirectURLIfUserHasAccess);
    }

    userHasAccessCallback?.();
  } catch {
    window.location.href = `${
      process.env.REACT_APP_AUTH_URL || 'https://auth.bambus.io'
    }`;
  }
};
