import { useEffect, useRef, useState } from 'react';
import history from 'utils/History';

const useRetainScrollPositionInScrollContainerAfterNavigation = ({
  route,
  enabled = false,
}: {
  route: string;
  enabled?: boolean;
}) => {
  const scrollContainerRef = useRef<HTMLElement | undefined>();
  const [wasScrollContainerRefSet, setWasScrollContainerRefSet] =
    useState<boolean>(false);
  const scrollContainerYScrollPosition = useRef<number>(0);
  const needsScrollPositionSetting = useRef<boolean>(false);

  /**
   * Sets a history change listener.
   * Whenever there is a navigation to the route, it sets a flag
   * that tells us that the scroll position needs to be set.
   */
  useEffect(() => {
    const unlisten = history.listen(({ pathname: navigatedToPathname }) => {
      if (navigatedToPathname === route) {
        needsScrollPositionSetting.current = true;
      }
    });
    return unlisten;
  }, [route]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (enabled && scrollContainerRef.current && !wasScrollContainerRefSet) {
      setWasScrollContainerRefSet(true);
    }
  });

  useEffect(() => {
    if (wasScrollContainerRefSet && enabled) {
      const mainElement = scrollContainerRef.current as HTMLElement;
      const scrollListener = () => {
        if (history.location.pathname === route) {
          scrollContainerYScrollPosition.current = mainElement.scrollTop;
        }
      };
      mainElement.addEventListener('scroll', scrollListener);

      const observer = new MutationObserver(() => {
        if (
          mainElement.scrollHeight > 0 &&
          needsScrollPositionSetting.current
        ) {
          mainElement.scrollTo(0, scrollContainerYScrollPosition.current);
          needsScrollPositionSetting.current = false;
        }
      });
      // Only tracking the attribute (like "class") changes
      observer.observe(mainElement, {
        subtree: false,
        childList: true,
        attributes: false,
      });

      return () => {
        mainElement.removeEventListener('scroll', scrollListener);
      };
    }
  }, [wasScrollContainerRefSet, enabled, route]);

  const scrollToTop = () => {
    scrollContainerRef.current?.scrollTo(0, 0);
  };

  return { scrollContainerRef, scrollToTop };
};

export default useRetainScrollPositionInScrollContainerAfterNavigation;
