import { trackEvent } from 'analytics/Matomo';
import { trackEventGTM } from 'analytics/GTM';

// See the README for general analytics information

export const sendFailedRequestAnalytics = ({
  url,
  httpMethod,
  responseCode,
  responseBody,
}: {
  url: string;
  httpMethod: string;
  responseCode: string | number;
  responseBody: string;
}) => {
  const eventValue = `ResponseCode: ${responseCode}\nResponseBody: ${responseBody}`;
  const eventDetails = `${httpMethod} ${url} - ${eventValue}`;
  trackEvent({
    eventCategory: 'HTTPRequest',
    action: `HTTPRequestFailed`,
    name: eventDetails,
  });
  trackEventGTM({
    event: 'http_request_failed',
    name: eventDetails,
  });
};
