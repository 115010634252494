import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type ScrollToTopProps = {
  excludedPaths?: string[];
  elementToScroll?: HTMLElement | Window;
};

function ScrollToTop({
  excludedPaths = [],
  elementToScroll = window,
  history,
}: ScrollToTopProps & RouteComponentProps) {
  useEffect(() => {
    const unlisten = history.listen(({ pathname: navigatedToPathname }) => {
      if (!excludedPaths.includes(navigatedToPathname)) {
        elementToScroll?.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history, excludedPaths, elementToScroll]);

  return null;
}

export default withRouter(ScrollToTop);
