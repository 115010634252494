import React, { useCallback, useEffect, useState } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { QueryClient } from 'react-query';
import { Tooltip, CookieConsent } from 'bambus-ui-components';
import GoogleTagManager from 'react-gtm-module';
import ReactTooltip from 'react-tooltip';

import AllTheProvidersWrapper from './AllTheProvidersWrapper';

import history from 'utils/History';
import { checkIfUserHasAccessToCockpit } from 'utils/Security';

import PageViewAnalytics from 'analytics/PageViewAnalytics';
import JavaScriptErrorsAnalytics from 'analytics/JavaScriptErrorsAnalytics';
import {
  BAMBUS_COOKIE_NO_TRACK,
  BAMBUS_COOKIE_TRACK_LEVEL,
  BAMBUS_COOKIE_TRACK_LEVEL_VALUE_All,
  BAMBUS_COOKIE_TRACK_LEVEL_VALUE_REQUIRED,
} from 'analytics/Config';
import { trackingAllowed } from 'analytics/Tracking';

import 'styles/App.css';
import theme from 'styles/theme';
import GlobalStyles from 'styles/GlobalStyles';

import Mobile from 'atoms/Mobile';
import Desktop from 'atoms/Desktop';

import { TEASER_TOOLTIP_ID } from 'molecules/NavigationMenuItems';

import MobileHeader from 'organisms/MobileHeader';
import MobileMenu from 'organisms/MobileMenu';
import DesktopMenu from 'organisms/DesktopMenu';
import PrivilegedSessionIndicator from 'organisms/PrivilegedSessionIndicator';
import DesktopGlobalNotifications from 'organisms/DesktopGlobalNotifications';

import { Page } from 'pages/PagesList';
import Documents from 'pages/Documents';
import Document from 'pages/Document';
import Settings from 'pages/Settings';
import Profile from 'pages/Profile';
import AdminSessionEntryPoint from 'pages/AdminSessionEntryPoint';
import ApplicationBeingProcessed from 'pages/ApplicationBeingProcessed';

import ScrollToTopAfterNavigation from 'utils/ScrollToTopAfterNavigation';
import useRetainScrollPositionInScrollContainerAfterNavigation from 'utils/hooks/useRetainScrollPositionInScrollContainerAfterNavigation';

import { ADMIN_SESSION_APPLICATION_ID_KEY } from 'constants/storage';

import SetCurrentPageOnNavigation from 'components/SetCurrentPageOnNavigation';

import routes from 'routes';
import DayJSInit from 'components/DayJSInit';

const PRIVILEGED_MODE_HEADER_HEIGHT = '60px';

const LayoutTemplateMobile = css`
  ${() => `
    height: 100vh;
    grid-template-columns: 100%;
    grid-template-areas:
      'header'
      'main';
    grid-template-rows: auto 1fr;
  `}
`;

const LayoutTemplateDesktop = css<{ isPrivilegedMode: boolean }>`
  ${({ isPrivilegedMode }) => `
    height: ${
      isPrivilegedMode
        ? `calc(100vh - ${PRIVILEGED_MODE_HEADER_HEIGHT})`
        : '100vh'
    };
    grid-template-columns: 272px 1fr;
    grid-template-areas:
    'menu main';
  `}
`;

const Layout = styled.div<any>`
  display: grid;
  ${({ isMobile }) =>
    isMobile ? LayoutTemplateMobile : LayoutTemplateDesktop};
`;

const MainDesktopStyles = css`
  ${() => `
    overflow: auto;
  `}
`;

const Main = styled.div<any>`
  grid-area: main;

  ${({ isMobile }) => !isMobile && MainDesktopStyles}
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const locale = 'de-DE';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<Page | null>(null);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  /**
   * Priviliged mode can only be accessed by admins. This lets them
   * view Cockpit from another user's point of view with the user's data.
   */
  const [isPrivilegedMode, setIsPrivilegedMode] = useState(false);

  const onMobileMenuVisibilityToggle = useCallback(() => {
    // Just toggle the state of the menu
    setIsMobileMenuOpen((isOpen) => !isOpen);

    //close all existing tooltips if any
    ReactTooltip.hide();
  }, []);

  const isMobile = useMediaQuery({
    maxWidth: theme.breakpoints.mobileMaxWidthPx,
  });

  /**
   * Effect that runs on app startup and checks whether the user has a valid session
   * and whether the user should stay on Cockpit or be redirected to Onboarding.
   */
  useEffect(() => {
    (async () => {
      if (!window.location.pathname.includes('adminsession')) {
        checkIfUserHasAccessToCockpit({
          history,
          userHasAccessCallback: () => {
            setIsAuthenticated(true);
          },
        });
      }
    })();
  }, []);

  // clear the router state on reload
  useEffect(() => {
    if (history.location.state) {
      history.replace({ ...history.location, state: {} });
    }
  }, []);

  useEffect(() => {
    setIsPrivilegedMode(
      sessionStorage.getItem(ADMIN_SESSION_APPLICATION_ID_KEY) != null
    );
  }, []);

  // Initializing Google Tag Manager (GTM) if tracking allowed
  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID && trackingAllowed) {
      GoogleTagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
        auth: process.env.REACT_APP_GTM_AUTH || '',
        preview: process.env.REACT_APP_GTM_PREVIEW || '',
      });
    }
  }, []);

  /**
   * Retaining the scroll position in the documents overview
   * scroll container (needed only on desktop).
   */
  const {
    scrollContainerRef: mainRef,
    scrollToTop: scrollDocumentsContainerToTop,
  } = useRetainScrollPositionInScrollContainerAfterNavigation({
    route: routes.documentsRoute,
    enabled: !isMobile,
  });

  useEffect(() => {
    const scrollToElementListener = (event: any) => {
      if (event.detail?.elementToScrollTo) {
        const scrollContainer = isMobile ? window : mainRef.current;
        const currentScrollTop = isMobile
          ? window.pageYOffset
          : mainRef.current?.scrollTop ?? 0;
        const elementToScrollTo = event.detail.elementToScrollTo as HTMLElement;
        scrollContainer?.scrollTo({
          left: 0,
          top:
            elementToScrollTo.getBoundingClientRect().top +
            (event.detail.topOffset ?? 0) -
            (isMobile ? theme.sizes.mobile.headerHeightPx : 0) +
            currentScrollTop,
          behavior: 'smooth',
        });
      }
    };
    document.addEventListener(
      'bmbCockpit:ScrollToElement',
      scrollToElementListener
    );
    return () => {
      document.removeEventListener(
        'bmbCockpit:ScrollToElement',
        scrollToElementListener
      );
    };
  }, [isMobile, mainRef]);

  return (
    <AllTheProvidersWrapper
      reactQueryClient={queryClient}
      isAuthenticated={isAuthenticated}
      setIsAuthenticated={setIsAuthenticated}
      isMobile={isMobile}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      locale={locale}
      scrollDocumentsContainerToTop={scrollDocumentsContainerToTop}
    >
      <DayJSInit />
      <PageViewAnalytics />
      <JavaScriptErrorsAnalytics />
      <GlobalStyles
        isMobile={isMobile}
        isScrollingDisabled={isMobileMenuOpen}
      />
      <CookieConsent
        isMobile={isMobile}
        cookieDomain={process.env.REACT_APP_CONSENT_COOKIE_DOMAIN}
        noTrackCookieName={BAMBUS_COOKIE_NO_TRACK}
        trackLevelCookieName={BAMBUS_COOKIE_TRACK_LEVEL}
        trackLevelCookieValueForPartialTracking={
          BAMBUS_COOKIE_TRACK_LEVEL_VALUE_REQUIRED
        }
        trackLevelCookieValueForCompleteTracking={
          BAMBUS_COOKIE_TRACK_LEVEL_VALUE_All
        }
        onAccept={() => {
          window.location.reload();
        }}
        onPartialAccept={() => {
          window.location.reload();
        }}
        onReject={() => {
          window.location.reload();
        }}
      />
      {isPrivilegedMode && isAuthenticated && (
        <PrivilegedSessionIndicator height={PRIVILEGED_MODE_HEADER_HEIGHT} />
      )}
      {!isMobile && <DesktopGlobalNotifications />}

      <Router history={history}>
        <ScrollToTopAfterNavigation
          excludedPaths={[routes.documentsRoute]}
          elementToScroll={isMobile ? window : mainRef.current}
        />
        <SetCurrentPageOnNavigation />
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to={routes.documentsRoute} />}
          ></Route>
          <Route
            exact
            path="/adminsession"
            component={AdminSessionEntryPoint}
          />
          <Route
            exact
            path={routes.applicationBeingProcessedRoute}
            component={ApplicationBeingProcessed}
          />
          {isAuthenticated && (
            <Route
              path={[
                routes.documentsRoute,
                routes.documentRouterPath,
                routes.settingsRoute,
                routes.profilRoute,
              ]}
            >
              <Tooltip id={TEASER_TOOLTIP_ID} effect="solid" maxWidth="20rem">
                <FormattedMessage
                  id="Navigation.PrimaryNavigation.TeaserText"
                  defaultMessage="Der volle Funktionsumfang wird nach dem Dokumentupload freigeschalten"
                />
              </Tooltip>
              <Layout
                isMobile={isMobile}
                isMobileMenuOpen={isMobileMenuOpen}
                isPrivilegedMode={isPrivilegedMode}
              >
                <Mobile>
                  <MobileHeader
                    gridAreaName="header"
                    onHamburgerPress={onMobileMenuVisibilityToggle}
                  />
                  {isMobileMenuOpen && (
                    <MobileMenu
                      onMobileMenuCloseIntention={onMobileMenuVisibilityToggle}
                    />
                  )}
                </Mobile>
                <Desktop>
                  <DesktopMenu gridAreaName="menu" />
                </Desktop>
                <Main isMobile={isMobile} ref={mainRef}>
                  <Route
                    exact
                    path={routes.documentsRoute}
                    component={Documents}
                  ></Route>
                  <Route
                    exact
                    path={routes.documentRouterPath}
                    component={Document}
                  ></Route>
                  <Route
                    exact
                    path={routes.settingsRoute}
                    component={Settings}
                  ></Route>
                  <Route
                    exact
                    path={routes.profilRoute}
                    component={Profile}
                  ></Route>
                </Main>
              </Layout>
            </Route>
          )}
        </Switch>
      </Router>
    </AllTheProvidersWrapper>
  );
};

export default App;
