import { trackEvent } from 'analytics/Matomo';
import { trackEventGTM } from 'analytics/GTM';

// See the README for general analytics information

const eventCategory = 'DocumentsList';

export const sendOnChangeDocumentsOutstandingToggleAnalytics = ({
  showOnlyOutstanding,
}: {
  showOnlyOutstanding: boolean;
}) => {
  const eventDetails = `New value show only outstanding: ${
    showOnlyOutstanding ? 'true' : 'false'
  }.`;
  trackEvent({
    eventCategory,
    action: 'ToggledShowOnlyOutstanding',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'toggled_show_only_outstanding',
    name: eventDetails,
  });
};

export const sendAvailableDocumentsToSubmitAnalytics = ({
  numberOfAvailableDocumentsToSubmit,
}: {
  numberOfAvailableDocumentsToSubmit: number;
}) => {
  // TODO: Extract this to Matomo.ts if possible.
  (window as any)._paq.push([
    function (this: any) {
      // If there is no value then false is returned.
      var matomoNumberOfAvailableDocumentsToSubmit = this.getCustomVariable(
        2,
        'visit'
      );
      // We want to set a value here if it wasn't any before.
      if (matomoNumberOfAvailableDocumentsToSubmit === false) {
        (window as any)._paq.push([
          'setCustomVariable',
          // Index, the number from 1 to 5 where this custom variable name is stored
          2,
          // Name, the name of the variable, for example: Gender, VisitorType
          'NumberOfDocumentsAvailableToSubmitAtBeginningOfSession',
          numberOfAvailableDocumentsToSubmit,
          // Value
          // Scope of the custom variable, "visit" means the custom variable applies to the current visit
          'visit',
        ]);
      }
    },
  ]);
};
