import React from 'react';
import styled from 'styled-components';

import { FadeIn } from 'styles/StyledAnimations';

import LoadingIndicatorCircle from 'atoms/LoadingIndicator';

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 12;
  pointer-events: none;
  background-color: ${({ theme }) => `${theme.colors.bambusBlue2dot5}DD`};

  ${FadeIn};
`;

/**
 * An absolutely positioned loading indicator.
 * Takes up the full height and width of the closest
 * relatively positioned element.
 */
const LoadingIndicator = () => {
  return (
    <LoadingWrapper>
      <LoadingIndicatorCircle />
    </LoadingWrapper>
  );
};

export default LoadingIndicator;
