import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import qs from 'query-string';

import routes from 'routes';

import { ADMIN_SESSION_APPLICATION_ID_KEY } from 'constants/storage';

const AdminSessionEntryPoint = ({ location, history }: RouteComponentProps) => {
  useEffect(() => {
    const applicationId = qs.parse(location.search).application_id;
    const redirectURL = qs.parse(location.search).redirect_url as string | null;
    if (applicationId !== undefined) {
      sessionStorage.setItem(
        ADMIN_SESSION_APPLICATION_ID_KEY,
        applicationId as string
      );

      // Reloading the whole application with applicationId in sessionStorage.
      // This is picked up by Axios at app startup and put in the HTTP headers.
      // See also src/api/index.ts
      window.location.assign(
        (redirectURL && decodeURIComponent(redirectURL)) ||
          routes.documentsRoute
      );
    } else {
      history.push(routes.documentsRoute);
    }
  }, [location, history]);
  return null;
};

export default withRouter(AdminSessionEntryPoint);
