/**
 * Hierarchy of properties: Category, Action, Name, Value.
 */
export const trackEvent = ({
  eventCategory,
  action,
  name,
  value,
}: {
  eventCategory: string;
  action: string;
  name?: string;
  value?: number;
}) => {
  const matomoLayer = (window as any)._paq;
  if (matomoLayer) {
    matomoLayer.push(['trackEvent', eventCategory, action, name, value]);
  }
};
