import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Formik, Field, Form, FieldProps, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  Checkbox,
  TextInput,
  InputWrapper,
  CountryCodeSelect,
} from 'bambus-ui-components';
import IsMobileContext from 'contexts/IsMobileContext';

import theme from 'styles/theme';

import Heading from 'atoms/Heading';
import HorizontalSeparator from 'atoms/HorizontalSeparator';
import Tile from 'atoms/Tile';
import VerticalSpacer from 'atoms/VerticalSpacer';

import { getUserDetails } from 'api/requests';

import PersonalInfoWidgetPlaceholder from './PersonalInfoWidgetPlaceholder';

const PasswordChangeSchema = Yup.object().shape({
  email: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  address: Yup.string().required(),
  zipCode: Yup.string().required(),
  town: Yup.string().required(),
  isSubscribedToNewsletter: Yup.boolean().required(),
  isSubscribedToOffers: Yup.boolean().required(),
  isSubscribedToMonthlyExcerpt: Yup.boolean().required(),
});

type PersonalInfoFormValues = {
  email?: string;
  phoneNumber?: string;
  address?: string;
  zipCode?: string;
  town?: string;
  isSubscribedToNewsletter?: boolean;
  isSubscribedToOffers?: boolean;
  isSubscribedToMonthlyExcerpt?: boolean;
};

const FormInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PersonalInfoInputsWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  @media (min-width: 380px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  gap: ${({ theme }) => theme.sizes.medium};
`;

const CommunicationInfoInputWrapper = styled.section`
  /* For now hide it as it's not for the first release */
  display: grid;
  grid-template-columns: 100%;
  gap: ${({ theme }) => theme.sizes.medium};
`;

const ComingInUpcomingRelease = styled.div`
  display: none;
`;

const PersonalInfoWidget = () => {
  const { data: userDetailsData, isLoading } = useQuery(
    ['userData'],
    getUserDetails
  );
  const intl = useIntl();

  const phoneNumberPrefix =
    userDetailsData?.phoneNumber?.substring(0, 3) || '+49';
  const phoneNumber = userDetailsData?.phoneNumber?.substring(3) || '';

  const isMobile = useContext(IsMobileContext);

  return isLoading ? (
    <PersonalInfoWidgetPlaceholder />
  ) : (
    <Formik
      initialValues={{
        email: userDetailsData?.email || '',
        phoneNumberPrefix: phoneNumberPrefix,
        phoneNumber: phoneNumber,
        address: `${userDetailsData?.streetName || ''} ${
          userDetailsData?.houseNumber || ''
        } ${userDetailsData?.addressLine2 || ''}`,
        zipCode: userDetailsData?.zipcode || '',
        town: userDetailsData?.city || '',
        isSubscribedToNewsletter: false,
        isSubscribedToOffers: false,
        isSubscribedToMonthlyExcerpt: false,
      }}
      onSubmit={(values) => {
        // request(something, 'POST', {
        //   passwordConfirmation: passwordConfirmation(),
        //   password: password,
        // })
        //   .then(() => {
        //     setError(false);
        //   })
        //   .catch((error) => {
        //     setError(true);
        //   });
      }}
      validateOnMount={true}
      validationSchema={PasswordChangeSchema}
    >
      {({ isValid, errors, touched }: FormikProps<PersonalInfoFormValues>) => (
        <Form>
          <Tile smallHorizontalPadding={isMobile}>
            <FormInnerWrapper>
              <PersonalInfoInputsWrapper>
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <TextInput
                      {...field}
                      type="email"
                      label={intl.formatMessage({
                        id: 'EmailAddress',
                        defaultMessage: 'E-Mail Adresse',
                      })}
                      disabled
                    />
                  )}
                </Field>
                <InputWrapper sm={220}>
                  <Field name="phoneNumberPrefix">
                    {({ field }: FieldProps) => (
                      <CountryCodeSelect
                        {...field}
                        // @ts-ignore
                        disabled
                        label={intl.formatMessage({
                          id: 'PhoneNumber',
                          defaultMessage: 'Telefonnummer',
                        })}
                      />
                    )}
                  </Field>
                  <Field name="phoneNumber">
                    {({ field }: FieldProps) => (
                      <TextInput {...field} type="tel" disabled />
                    )}
                  </Field>
                </InputWrapper>
                <Field name="address">
                  {({ field }: FieldProps) => (
                    <TextInput
                      {...field}
                      label={intl.formatMessage({
                        id: 'Pages.Settings.Addresss',
                        defaultMessage: 'Straße, Hausnummer, Stiege, Türe',
                      })}
                      disabled
                    />
                  )}
                </Field>
                <InputWrapper>
                  <Field name="zipCode">
                    {({ field }: FieldProps) => (
                      <TextInput
                        {...field}
                        label={intl.formatMessage({
                          id: 'ZIP',
                          defaultMessage: 'PLZ',
                        })}
                        disabled
                      />
                    )}
                  </Field>
                  <Field name="town">
                    {({ field }: FieldProps) => (
                      <TextInput
                        {...field}
                        label={intl.formatMessage({
                          id: 'Town',
                          defaultMessage: 'Ort',
                        })}
                        disabled
                      />
                    )}
                  </Field>
                </InputWrapper>
              </PersonalInfoInputsWrapper>
              <ComingInUpcomingRelease>
                <HorizontalSeparator
                  marginBottom={theme.sizes.large}
                  marginTop={theme.sizes.large}
                />
                <Heading
                  weight={700}
                  size={9}
                  color={theme.colors.bambusBlue100}
                >
                  <FormattedMessage
                    id="Pages.Settings.WhichCommunicationQuestion"
                    defaultMessage="Welche Informationen möchtest Du von uns erhalten?"
                  />
                </Heading>
                <VerticalSpacer space={theme.sizes.large} />
                <CommunicationInfoInputWrapper>
                  <Field name="isSubscribedToNewsletter">
                    {({ field }: FieldProps) => (
                      <Checkbox {...field} checked={field.value}>
                        <FormattedMessage
                          id="Newsletter"
                          defaultMessage="Newsletter"
                        />
                      </Checkbox>
                    )}
                  </Field>
                  <Field name="isSubscribedToOffers">
                    {({ field }: FieldProps) => (
                      <Checkbox {...field} checked={field.value}>
                        <FormattedMessage
                          id="Deals"
                          defaultMessage="Angebote"
                        />
                      </Checkbox>
                    )}
                  </Field>
                  <Field name="isSubscribedToMonthlyExcerpt">
                    {({ field }: FieldProps) => (
                      <Checkbox {...field} checked={field.value}>
                        <FormattedMessage
                          id="Pages.Settings.MonthlyAccountExcerpt"
                          defaultMessage="Monatliche Kontoauszüge"
                        />
                      </Checkbox>
                    )}
                  </Field>
                </CommunicationInfoInputWrapper>
              </ComingInUpcomingRelease>
            </FormInnerWrapper>
          </Tile>
          <VerticalSpacer space={theme.sizes.small} />
          {/* <Button main disabled={!isValid} type="submit">
            <FormattedMessage id="Save" defaultMessage="Speichern" />
          </Button> */}
        </Form>
      )}
    </Formik>
  );
};

export default PersonalInfoWidget;
