import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { init as dateUtilsInit } from 'utils/Date';

function DayJSInit() {
  const intl = useIntl();

  useEffect(() => {
    dateUtilsInit(intl);
  }, [intl]);

  return null;
}

export default DayJSInit;
