import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Briefcase, Settings, LogOut, Clipboard } from 'react-feather';
import { FormattedMessage } from 'react-intl';

import IsMobileContext from 'contexts/IsMobileContext';
import CurrentPageContext from 'contexts/CurrentPageContext';
import AuthContext from 'contexts/AuthContext';

import theme from 'styles/theme';

import { Page } from 'pages/PagesList';

import PrimaryNavigationLink from 'atoms/PrimaryNavigationLink';
import SecondaryNavigationLink from 'atoms/SecondaryNavigationLink';

import { request } from 'api';
import endpoints from 'api/endpoints';
import routes from 'routes';

export const TEASER_TOOLTIP_ID = 'primary-nav-teaser-tooltip';

const NavigationWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PrimaryNavigationWrapper = styled.section``;

const SecondaryNavigationWrapper = styled.section``;

const TeaserPrimaryNavigationWrapper = styled.section<any>`
  ${({ theme, isMobile }) =>
    isMobile &&
    `
      border: 1px dashed ${theme.colors.bambusBlue50};
      border-radius: ${theme.borderRadii.small};
  `}
`;

const TeaserMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.sizes.small};
`;

const TeaserText = styled.div<any>`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.bambusBlue90};
  padding: ${({ theme }) => theme.sizes.small};
  font-size: ${({ theme }) => theme.fontSizes.text};
  user-select: none;

  ${({ theme, showTopBorder }) =>
    showTopBorder && `border-top: 1px dashed ${theme.colors.bambusBlue50};`}
`;

const getPrimaryNavigationItems = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick?: () => void;
}) => {
  const marginTop = `min(4vh, ${theme.sizes.medium})`;
  const TeaserTooltipControlProps = {
    'data-tip': true,
    'data-for': disabled ? TEASER_TOOLTIP_ID : null,
    'data-place': 'right',
  };
  /**
   * All the PrimaryNavigationLink elements are wrapped in a div
   * so they don't take up the full flex container's width. Thus,
   * the tooltip is right next to them.
   */
  return (
    <>
      <div>
        <PrimaryNavigationLink
          href="/"
          disabled={disabled}
          onClick={onClick}
          {...TeaserTooltipControlProps}
        >
          <FormattedMessage
            id="Navigation.PrimaryNavigation.Dashboard"
            defaultMessage="Dashboard"
          />
        </PrimaryNavigationLink>
      </div>
      <div>
        <PrimaryNavigationLink
          href="/"
          disabled={disabled}
          marginTop={marginTop}
          onClick={onClick}
          {...TeaserTooltipControlProps}
        >
          <FormattedMessage
            id="Navigation.PrimaryNavigation.Applications"
            defaultMessage="Anträge"
          />
        </PrimaryNavigationLink>
      </div>
      {/* <PrimaryNavigationLink
        href="/"
        disabled={disabled}
        marginTop={marginTop}
        {...TeaserTooltipControlProps}
      >
        <FormattedMessage
          id="Navigation.PrimaryNavigation.Properties"
          defaultMessage="Immobilien"
        />
      </PrimaryNavigationLink> */}
      {/* <PrimaryNavigationLink
        href="/"
        disabled={disabled}
        marginTop={marginTop}
        {...TeaserTooltipControlProps}
      >
        <FormattedMessage
          id="Navigation.PrimaryNavigation.Finances"
          defaultMessage="Finanzierung"
        />
      </PrimaryNavigationLink>
      <PrimaryNavigationLink
        href="/"
        disabled={disabled}
        marginTop={marginTop}
        {...TeaserTooltipControlProps}
      >
        <FormattedMessage
          id="Navigation.PrimaryNavigation.Services"
          defaultMessage="Services"
        />
      </PrimaryNavigationLink> */}
    </>
  );
};

export type NavigationMenuItemsProps = {
  isPrimaryNavigationTeaser?: boolean;
  navigationWrapperProps?: object;
  primaryNavigationWrapperProps?: object;
  secondaryNavigationWrapperProps?: object;
  onNavigate?: () => void;
};

/**
 * Renders the Navigation Menu Items (Primary and Secondary).
 * @param {object} props
 * @param {boolean} props.isPrimaryNavigationTeaser - Adds an outline and teaser text to the Primary Nav items.
 * Also hides them on mobile.
 * @param {object} props.navigationWrapperProps - Props to be forwarded to the wrapper (simple <nav> element).
 * @param {object} props.primaryNavigationWrapperProps - Props to be forwarded to the primary navigation section wrapper (simple <section> element).
 * @param {object} props.secondaryNavigationWrapperProps - Props to be forwarded to the secondary navigation section wrapper (simple <section> element).
 */
const NavigationMenuItems = ({
  isPrimaryNavigationTeaser = true,
  // location,
  navigationWrapperProps = {},
  primaryNavigationWrapperProps = {},
  secondaryNavigationWrapperProps = {},
  onNavigate,
}: // history,
NavigationMenuItemsProps & RouteComponentProps) => {
  const { currentPage } = useContext(CurrentPageContext);
  const isMobile = useContext(IsMobileContext);
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  const onLogoutPress = useCallback(
    (event) => {
      //To prevent actual navigation
      event.preventDefault();

      request(endpoints.logout, 'POST');
      setIsAuthenticated(false);
      window.location.href = `${
        process.env.REACT_APP_AUTH_URL || 'https://auth.bambus.io'
      }`;
    },
    [setIsAuthenticated]
  );

  /**
   * The margin gets smaller as the screen gets smaller in height.
   * The maximum margin is 17px, however.
   **/
  const secondaryNavigationMarginTop = 'min(4vh, 17px)';

  return (
    <NavigationWrapper {...navigationWrapperProps}>
      {isPrimaryNavigationTeaser ? (
        <>
          <TeaserPrimaryNavigationWrapper isMobile={isMobile}>
            {!isMobile && (
              <TeaserMenuWrapper>
                {getPrimaryNavigationItems({
                  disabled: true,
                  onClick: onNavigate,
                })}
              </TeaserMenuWrapper>
            )}
            {isMobile && (
              <TeaserText showTopBorder={!isMobile}>
                <FormattedMessage
                  id="Navigation.PrimaryNavigation.TeaserText"
                  defaultMessage="Diese Funktionen sind in Ihrem Antrag nicht verfügbar"
                />
              </TeaserText>
            )}
          </TeaserPrimaryNavigationWrapper>
        </>
      ) : (
        <PrimaryNavigationWrapper {...primaryNavigationWrapperProps}>
          {getPrimaryNavigationItems({ disabled: false, onClick: onNavigate })}
        </PrimaryNavigationWrapper>
      )}
      <SecondaryNavigationWrapper {...secondaryNavigationWrapperProps}>
        {/* <SecondaryNavigationLink
          href="/"
          marginTop={secondaryNavigationMarginTop}
          featherIcon={<HelpCircle />}
          disabled={!isAuthenticated}
        >
          <FormattedMessage
            id="Navigation.SecondaryNavigation.Help"
            defaultMessage="Hilfe"
          />
        </SecondaryNavigationLink> */}
        <SecondaryNavigationLink
          href={routes.profilRoute}
          active={currentPage === Page.PROFILE}
          marginTop={secondaryNavigationMarginTop}
          featherIcon={<Clipboard />}
          disabled={!isAuthenticated}
          onClick={onNavigate}
        >
          <FormattedMessage
            id="Navigation.SecondaryNavigation.Profile"
            defaultMessage="Profil"
          />
        </SecondaryNavigationLink>
        <SecondaryNavigationLink
          href={routes.documentsRoute}
          active={currentPage === Page.DOCUMENTS}
          marginTop={secondaryNavigationMarginTop}
          featherIcon={<Briefcase />}
          disabled={!isAuthenticated}
          onClick={onNavigate}
        >
          <FormattedMessage
            id="Navigation.SecondaryNavigation.Documents"
            defaultMessage="Dokumente"
          />
        </SecondaryNavigationLink>
        <SecondaryNavigationLink
          href={routes.settingsRoute}
          active={currentPage === Page.SETTINGS}
          marginTop={secondaryNavigationMarginTop}
          featherIcon={<Settings />}
          disabled={!isAuthenticated}
          onClick={onNavigate}
        >
          <FormattedMessage id="Settings" defaultMessage="Einstellungen" />
        </SecondaryNavigationLink>
        <SecondaryNavigationLink
          href="/"
          marginTop={secondaryNavigationMarginTop}
          featherIcon={<LogOut />}
          iconStyles={{
            transform: 'rotate(180deg)',
          }}
          disabled={!isAuthenticated}
          onClick={onLogoutPress}
        >
          <FormattedMessage
            id="Navigation.SecondaryNavigation.LogOut"
            defaultMessage="Ausloggen"
          />
        </SecondaryNavigationLink>
      </SecondaryNavigationWrapper>
    </NavigationWrapper>
  );
};

export default withRouter(NavigationMenuItems);
