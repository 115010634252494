import { trackEvent } from 'analytics/Matomo';
import { trackEventGTM } from 'analytics/GTM';

// See the README for general analytics information

const eventCategory = 'Document';

export const sendLandedOnUploadStepAnalytics = ({
  documentId,
}: {
  documentId: string;
}) => {
  trackEvent({
    eventCategory,
    action: 'LandedOnUploadStep',
    name: documentId,
  });
  trackEventGTM({
    event: 'landed_on_upload_step',
    name: documentId,
  });
};

export const sendLandedOnReorderStepAnalytics = ({
  documentId,
}: {
  documentId: string;
}) => {
  trackEvent({
    eventCategory,
    action: 'LandedOnReorderStep',
    name: documentId,
  });
  trackEventGTM({
    event: 'landed_on_reorder_step',
    name: documentId,
  });
};

export const sendLandedOnReviewStepAnalytics = ({
  documentId,
  isDocumentLocked,
}: {
  documentId: string;
  isDocumentLocked: boolean | undefined;
}) => {
  const eventDetails = `Document Id: ${documentId}; Locked: ${
    isDocumentLocked ? 'true' : 'false'
  }`;
  trackEvent({
    eventCategory,
    action: 'LandedOnReviewStep',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'landed_on_review_step',
    name: eventDetails,
  });
};

export const sendDownloadedDocumentToSignAnalytics = ({
  documentId,
}: {
  documentId: string;
}) => {
  trackEvent({
    eventCategory,
    action: 'DownloadedDocumentToSign',
    name: documentId,
  });
  trackEventGTM({
    event: 'downloaded_document_to_sign',
    name: documentId,
  });
};

export const sendCancelDocumentUploadProcessAnalytics = ({
  documentId,
  step,
}: {
  documentId: string;
  step: 'upload' | 'review';
}) => {
  const eventDetails = `Document id: ${documentId}, Step: ${step}`;
  trackEvent({
    eventCategory,
    action: 'CancelledDocumentUploadProcess',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'cancelled_document_upload_process',
    name: eventDetails,
  });
};

export const sendDocumentReviewOpenedOnMobileAnalytics = ({
  documentId,
}: {
  documentId: string;
}) => {
  trackEvent({
    eventCategory,
    action: 'MobileDocumentReviewOpened',
    name: documentId,
  });
  trackEventGTM({
    event: 'mobile_document_review_opened',
    name: documentId,
  });
};

export const sendRestartDocumentUploadProcessAnalytics = ({
  documentId,
  step,
}: {
  documentId: string;
  step: 'reorder' | 'review';
}) => {
  const eventDetails = `Document id: ${documentId}, Step: ${step}`;
  trackEvent({
    eventCategory,
    action: 'RestartedDocumentUploadProcess',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'restarted_document_upload_process',
    name: eventDetails,
  });
};

//Only do this when new document (not double submission)
export const sendSubmitDocumentAnalytics = ({
  documentId,
  shouldSendSubmittedNewDocumentAnalytics,
  shouldSendSubmittedRequiredDocumentAnalytics,
}: {
  documentId: string;
  shouldSendSubmittedNewDocumentAnalytics?: boolean;
  shouldSendSubmittedRequiredDocumentAnalytics?: boolean;
}) => {
  if (shouldSendSubmittedRequiredDocumentAnalytics) {
    // Matomo specific variable setting
    // TODO: Extract this into Matomo.ts if possible.
    (window as any)._paq.push([
      function (this: any) {
        // If there is no value then false is returned.
        // Otherwise, an array of type: ["variableName", "variableValue"] is returned.
        var nrOfDocumentsSubmitted = this.getCustomVariable(1, 'visit');
        (window as any)._paq.push([
          'setCustomVariable',
          // Index, the number from 1 to 5 where this custom variable name is stored
          1,
          // Name, the name of the variable, for example: Gender, VisitorType
          'NumberOfDocumentsSubmitted',
          // Value
          nrOfDocumentsSubmitted !== false
            ? parseInt(nrOfDocumentsSubmitted[1]) + 1
            : 1,
          // Scope of the custom variable, "visit" means the custom variable applies to the current visit
          'visit',
        ]);
      },
    ]);
  }
  if (shouldSendSubmittedNewDocumentAnalytics) {
    trackEvent({
      eventCategory,
      action: 'DocumentSubmitted',
      name: documentId,
    });
    trackEventGTM({
      event: 'document_submitted',
      name: documentId,
    });
  } else {
    trackEvent({
      eventCategory,
      action: 'DocumentResubmitted',
      name: documentId,
    });
    trackEventGTM({
      event: 'document_resubmitted',
      name: documentId,
    });
  }
};

type File = {
  type: string;
  size: string | number;
};

type FileFailedToUpload = File & {
  failureReason: string;
};

export const sendOnBeforeUploadFilesAnalytics = ({
  documentId,
  filesToUpload,
}: {
  documentId: string;
  filesToUpload: File[];
}) => {
  //Sending the analytics event for files that are starting to be uploaded
  const eventValue = filesToUpload.reduce((prev, file) => {
    return `${prev}Type: ${file.type}, Size: ${file.size};\n`;
  }, '');

  const eventDetails = `DocId: ${documentId} - ${eventValue}`;
  trackEvent({
    eventCategory,
    action: 'UploadBegin',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'upload_begin',
    name: eventDetails,
  });
};

export const sendOnAfterUploadFilesAnalytics = ({
  documentId,
  filesUploadedSuccessfully,
  filesUploadedWithError,
}: {
  documentId: string;
  filesUploadedSuccessfully: File[];
  filesUploadedWithError: FileFailedToUpload[];
}) => {
  //Sending the analytics event for successfully uploaded files
  if (
    Array.isArray(filesUploadedSuccessfully) &&
    filesUploadedSuccessfully.length > 0
  ) {
    const successEventValue = filesUploadedSuccessfully.reduce((prev, file) => {
      return `${prev}Type: ${file.type}, Size: ${file.size};\n`;
    }, '');

    const eventDetails = `DocId: ${documentId} - ${successEventValue}`;
    trackEvent({
      eventCategory,
      action: 'UploadFinishedSuccessfully',
      name: eventDetails,
    });
    trackEventGTM({
      event: 'upload_finished_successfully',
      name: eventDetails,
    });
  }
  //Sending the analytics event for failed to upload files
  if (
    Array.isArray(filesUploadedWithError) &&
    filesUploadedWithError.length > 0
  ) {
    const failedToUploadEventValue = filesUploadedWithError.reduce(
      (prev, file) => {
        return `${prev}Type: ${file.type}, Size: ${file.size}, Failure Reason: ${file.failureReason};\n`;
      },
      ''
    );

    const eventDetails = `DocId: ${documentId} - ${failedToUploadEventValue}`;
    trackEvent({
      eventCategory,
      action: 'UploadFailed',
      name: eventDetails,
    });
    trackEventGTM({
      event: 'upload_failed',
      name: eventDetails,
    });
  }
};

export const sendConcatenationStartedAnalytics = ({
  documentId,
}: {
  documentId: string;
}) => {
  trackEvent({
    eventCategory,
    action: 'ConcatenationStarted',
    name: `DocId: ${documentId}`,
  });
  trackEventGTM({
    event: 'concatenation_started',
    name: `DocId: ${documentId}`,
  });
};

export const sendWaitingAfterConcatenationReadyAnalytics = ({
  documentId,
  cyclesWaited,
  durationOfWaitingCycleMs,
}: {
  documentId: string;
  cyclesWaited: number;
  durationOfWaitingCycleMs: number;
}) => {
  const eventValue = `Cycles Waited: ${cyclesWaited} - Wait Duration Per Cycle (ms): ${durationOfWaitingCycleMs} - Total Wait Time Until Now (seconds): ${
    (durationOfWaitingCycleMs * cyclesWaited) / 1000
  }`;
  const eventDetails = `DocId: ${documentId} - ${eventValue}`;
  trackEvent({
    eventCategory,
    action: 'WaitingAfterConcatenation',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'waiting_after_concatenation',
    name: eventDetails,
  });
};

export const sendConcatenationReadyAnalytics = ({
  documentId,
  cyclesWaited,
  durationOfWaitingCycleMs,
}: {
  documentId: string;
  cyclesWaited: number;
  durationOfWaitingCycleMs: number;
}) => {
  const eventValue = `Cycles Waited: ${cyclesWaited} - Wait Duration Per Cycle (ms): ${durationOfWaitingCycleMs} - Total Wait Time (seconds): ${
    (durationOfWaitingCycleMs * cyclesWaited) / 1000
  }`;
  const eventDetails = `DocId: ${documentId} - ${eventValue}`;
  trackEvent({
    eventCategory,
    action: 'ConcatenationFinished',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'concatenation_finished',
    name: eventDetails,
  });
};

export const sendReorderingStartedAnalytics = ({
  documentId,
}: {
  documentId: string;
}) => {
  trackEvent({
    eventCategory,
    action: 'ReorderingBEProcessingStarted',
    name: `DocId: ${documentId}`,
  });
  trackEventGTM({
    event: 'reordering_BE_processing_started',
    name: `DocId: ${documentId}`,
  });
};

export const sendWaitingAfterReorderingReadyAnalytics = ({
  documentId,
  cyclesWaited,
  durationOfWaitingCycleMs,
}: {
  documentId: string;
  cyclesWaited: number;
  durationOfWaitingCycleMs: number;
}) => {
  const eventValue = `Cycles Waited: ${cyclesWaited} - Wait Duration Per Cycle (ms): ${durationOfWaitingCycleMs} - Total Wait Time Until Now (seconds): ${
    (durationOfWaitingCycleMs * cyclesWaited) / 1000
  }`;
  const eventDetails = `DocId: ${documentId} - ${eventValue}`;
  trackEvent({
    eventCategory,
    action: 'WaitingAfterReordering',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'waiting_after_reordering',
    name: eventDetails,
  });
};

export const sendReorderingReadyAnalytics = ({
  documentId,
  cyclesWaited,
  durationOfWaitingCycleMs,
}: {
  documentId: string;
  cyclesWaited: number;
  durationOfWaitingCycleMs: number;
}) => {
  const eventValue = `Cycles Waited: ${cyclesWaited} - Wait Duration Per Cycle (ms): ${durationOfWaitingCycleMs} - Total Wait Time (seconds): ${
    (durationOfWaitingCycleMs * cyclesWaited) / 1000
  }`;
  const eventDetails = `DocId: ${documentId} - ${eventValue}`;
  trackEvent({
    eventCategory,
    action: 'ReorderingBEProcessingFinished',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'reordering_BE_processing_finished',
    name: eventDetails,
  });
};

export const sendFileDeletedAnalytics = ({
  documentId,
  fileId,
}: {
  documentId: string;
  fileId: string;
}) => {
  const eventDetails = `DocId: ${documentId}, FileId: ${fileId}`;
  trackEvent({
    eventCategory,
    action: 'DeletedFile',
    name: eventDetails,
  });
  trackEventGTM({
    event: 'deleted_file',
    name: eventDetails,
  });
};
