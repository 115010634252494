import React, { useContext } from 'react';
import { Button } from 'bambus-ui-components';
import IsMobileContext from 'contexts/IsMobileContext';

import theme from 'styles/theme';
import styled from 'styled-components';

import CaptionText from 'atoms/CaptionText';
import HorizontalSpacer from 'atoms/HorizontalSpacer';
import FeatherIconHolder from 'atoms/FeatherIconHolder';

import { getHumanFileSize } from 'utils/Files';

const FileDetailsWrapper = styled.div<any>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: ${(p) => p.theme.sizes.extraSmall};
  background-color: ${(p) => p.color || p.theme.colors.bambusBlue2dot5};
`;

type UploadedFileProps = {
  typeIcon?: React.ReactNode;
  fileName?: string;
  fileSizeBytes: number;
  closeButton?: React.ReactNode;
  onDelete: () => void;
};

const UploadedFile = ({
  typeIcon,
  fileName,
  fileSizeBytes,
  closeButton,
  onDelete,
}: UploadedFileProps) => {
  const isMobile = useContext(IsMobileContext);

  return (
    <FileDetailsWrapper>
      <FeatherIconHolder iconFillColor={theme.colors.bambusBlue100}>
        {typeIcon}
      </FeatherIconHolder>
      <HorizontalSpacer
        space={isMobile ? theme.sizes.extraSmall : theme.sizes.small}
      />
      <CaptionText
        color={theme.colors.bambusBlue100}
        fontWeight={400}
        small={isMobile}
        style={{
          flexGrow: 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {fileName}
      </CaptionText>
      <HorizontalSpacer space={theme.sizes.small} />
      <CaptionText
        color={theme.colors.bambusBlue50}
        fontWeight={400}
        small={isMobile}
      >
        {getHumanFileSize(fileSizeBytes)}
      </CaptionText>
      <HorizontalSpacer
        space={isMobile ? theme.sizes.mini : theme.sizes.small}
      />
      <Button
        icon={closeButton}
        // @ts-ignore
        style={{
          backgroundColor: theme.colors.bambusBlue2dot5,
          color: theme.colors.bambusBlue100,
        }}
        onClick={onDelete}
      />
    </FileDetailsWrapper>
  );
};

export default UploadedFile;
