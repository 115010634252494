import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import theme from 'styles/theme';

import IsMobileContext from 'contexts/IsMobileContext';

import MainContentWrapper from 'atoms/MainContentWrapper';
import PageHeading from 'atoms/PageHeading';
import Heading from 'atoms/Heading';
import VerticalSpacer from 'atoms/VerticalSpacer';
import SubtitleText from 'atoms/SubtitleText';
import Paragraph from 'atoms/Paragraph';
import ContactSupportButton from 'atoms/ContactSupportButton';

import PasswordAndSecurityWidget from './PasswordAndSecurityWidget';
import PersonalInfoWidget from './PersonalInfoWidget';

const Settings = () => {
  const isMobile = useContext(IsMobileContext);

  return (
    <MainContentWrapper>
      <PageHeading>
        <FormattedMessage id="Settings" defaultMessage="Einstellungen" />
      </PageHeading>
      <VerticalSpacer space={theme.sizes.midLarge} />
      <SubtitleText>
        <FormattedMessage
          id="Pages.Settings.SecurityAndPassword"
          defaultMessage="Sicherheit & Passwort"
        />
      </SubtitleText>
      <VerticalSpacer space={theme.sizes.extraSmall} />
      <PasswordAndSecurityWidget />

      <VerticalSpacer space={theme.sizes.midLarge} />
      <SubtitleText>
        <FormattedMessage
          id="Pages.Settings.Communication"
          defaultMessage="Kommunikation"
        />
      </SubtitleText>
      <VerticalSpacer space={theme.sizes.extraSmall} />
      <PersonalInfoWidget />

      <VerticalSpacer space={theme.sizes.extraLarge} />
      <Heading size={isMobile ? 8 : 7}>
        <FormattedMessage
          id="NeedsChangeQuestion"
          defaultMessage="Änderungswünsche?"
        />
      </Heading>
      <VerticalSpacer space={theme.sizes.small} />
      <Paragraph size={isMobile ? 2 : 0}>
        <FormattedMessage
          id="Pages.Settings.FalseDataInstructions"
          defaultMessage="Möchten Sie Ihre Angaben ändern? Dann kontaktieren Sie bitte unser Support Team."
        />
      </Paragraph>
      <VerticalSpacer space={theme.sizes.small} />
      <ContactSupportButton />
    </MainContentWrapper>
  );
};

export default Settings;
