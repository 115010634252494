import { createGlobalStyle } from 'styled-components';

type GlobalStyleProps = {
  isScrollingDisabled?: boolean | undefined;
  isMobile: boolean;
};

const GlobalStyles = createGlobalStyle<GlobalStyleProps>`
    body {
      /**
       Height set to 100vh as we want to display the background
       color always without being constrained by the actual 
       content height
       */
      height: 100vh;
      overflow: ${(p) => (p.isScrollingDisabled ? 'hidden' : 'auto')};
      font-size: ${({ isMobile }) => (isMobile ? '1.15rem' : '1rem')};
      background-color: ${({ theme }) => theme.colors.bambusBlue5};
  
      /**
        The following are needed for the 'medium-zoom' library
       */
      .medium-zoom-overlay {
          z-index: 50;
      }
      .medium-zoom-image--opened {
          z-index: 51;
      }
    }
  `;

export default GlobalStyles;
