import React from 'react';
import styled from 'styled-components';

import theme from 'styles/theme';

import FeatherIconHolder from 'atoms/FeatherIconHolder';
import CaptionText from 'atoms/CaptionText';
import HorizontalSpacer from 'atoms/HorizontalSpacer';

type IconTextIndicatorProps = {
  featherIcon: React.ReactNode;
  featherIconColor?: string;
  indicatorText?: string;
  indicatorTextColor?: string;
  hideText?: boolean;
  //Allow rest properties:
  [x: string]: any;
};

const StatusIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconTextIndicator = ({
  featherIcon,
  featherIconColor = theme.colors.bambusBlue100,
  indicatorText = '',
  indicatorTextColor = theme.colors.white,
  hideText = false,
  ...rest
}: IconTextIndicatorProps) => {
  return (
    <StatusIndicatorWrapper {...rest}>
      <FeatherIconHolder iconFillColor={featherIconColor}>
        {featherIcon}
      </FeatherIconHolder>
      {!hideText && (
        <>
          <HorizontalSpacer space={theme.sizes.extraSmall} />
          <CaptionText fontWeight={600} color={indicatorTextColor}>
            {indicatorText}
          </CaptionText>
        </>
      )}
    </StatusIndicatorWrapper>
  );
};

export default IconTextIndicator;
