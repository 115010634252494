import React, { useContext, useMemo } from 'react';
import { Activity } from 'react-feather';
import { Button } from 'bambus-ui-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Mobile from 'atoms/Mobile';
import Desktop from 'atoms/Desktop';

import IsMobileContext from 'contexts/IsMobileContext';

const DesktopButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * The Contact Support Button (simple mailto: link).
 * Renders a full width Button on Mobile.
 * Renders a flex-end (right) aligned Button on Desktop.
 */
const ContactSupportButton = () => {
  const isMobile = useContext(IsMobileContext);

  const ContactSupportButton = useMemo(() => {
    let style = {};
    if (isMobile) {
      style = { width: '100%', display: 'flex', justifyContent: 'center' };
    }
    return (
      <Button
        muted
        icon={<Activity />}
        // @ts-ignore
        style={style}
        as="a"
        href="mailto:beratung@bambus.io"
      >
        <FormattedMessage
          id="ContactSupport"
          defaultMessage="Support kontaktieren"
        />
      </Button>
    );
  }, [isMobile]);

  return (
    <>
      <Mobile>{ContactSupportButton}</Mobile>
      <Desktop>
        <DesktopButtonHolder>{ContactSupportButton}</DesktopButtonHolder>
      </Desktop>
    </>
  );
};

export default ContactSupportButton;
