import React from 'react';
import styled from 'styled-components';

import theme from 'styles/theme';

import CaptionText from 'atoms/CaptionText';
import HorizontalSpacer from 'atoms/HorizontalSpacer';
import Circle from 'atoms/Circle';

type StatusIndicatorProps = {
  indicatorColor?: string;
  indicatorText?: string;
  hideText?: boolean;
  hideCircle?: boolean;
  //Allow rest properties:
  [x: string]: any;
};

const StatusIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusIndicator = ({
  indicatorColor = theme.colors.white,
  hideCircle = false,
  indicatorText = '',
  hideText = false,
  ...rest
}: StatusIndicatorProps) => {
  return (
    <StatusIndicatorWrapper {...rest}>
      {!hideCircle && <Circle color={indicatorColor} />}
      {!hideText && (
        <>
          <HorizontalSpacer space={theme.sizes.extraSmall} />
          <CaptionText>{indicatorText}</CaptionText>
        </>
      )}
    </StatusIndicatorWrapper>
  );
};

export default StatusIndicator;
