import React from 'react';
import styled from 'styled-components';

import NavigationMenuItems from 'molecules/NavigationMenuItems';

const Menu = styled.div<any>`
  position: fixed;
  width: 100%;
  top: ${(p) => p.theme.sizes.mobile.headerHeight};
  bottom: 0;

  display: flex;
  flex-direction: column;

  z-index: 13;

  padding: ${(p) => p.theme.sizes.large};

  background-color: ${(p) => p.theme.colors.bambusBlue100};

  /* Box shadow copied from Figma */
  box-shadow: 40px 0px 60px rgba(0, 0, 0, 0.05),
    16.7px 0px 25.1px rgba(0, 0, 0, 0.036), 8.9px 0px 13.4px rgba(0, 0, 0, 0.03),
    5px 0px 7.5px rgba(0, 0, 0, 0.025), 2.7px 0px 4px rgba(0, 0, 0, 0.02),
    1.1px 0px 1.7px rgba(0, 0, 0, 0.014);
`;

export type MobileMenuProps = {
  gridAreaName?: string;
  onMobileMenuCloseIntention?: () => void;
};

const MobileMenu = ({
  gridAreaName = '',
  onMobileMenuCloseIntention,
}: MobileMenuProps) => {
  return (
    <Menu gridAreaName={gridAreaName}>
      <NavigationMenuItems
        navigationWrapperProps={{
          style: {
            flexGrow: 1,
          },
        }}
        onNavigate={onMobileMenuCloseIntention}
      />
    </Menu>
  );
};

export default MobileMenu;
