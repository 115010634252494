/**
 * Converts the camel case input string to snake case.</br>
 * Example: helloThere -> hello_there
 * @param string Input string
 */
export function toSnakeCase(string: string): string {
  return string.replace(/([A-Z]+)/g, (match) => `_${match.toLowerCase()}`);
}

/**
 * Converts the snake case input string to camel case.</br>
 * Example: hello_there -> helloThere
 * @param string Input string
 */
export function toCamelCase(string: string): string {
  return string.replace(/_(\w)/g, (_, p1) => p1.toUpperCase());
}
