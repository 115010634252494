import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, Paragraph } from 'bambus-ui-components';
import { FormattedMessage } from 'react-intl';

import Heading from 'atoms/Heading';
import HorizontalSpacer from 'atoms/HorizontalSpacer';

import IsMobileContext from 'contexts/IsMobileContext';

import { ReactComponent as Image } from 'illustrations/HappyJumpingPeople.svg';
import { ReactComponent as BambusLogo } from 'illustrations/BambusBlueLogoWithName.svg';

import theme from 'styles/theme';

const Centered = styled.div<{ smallPadding?: boolean }>`
  padding: ${({ theme, smallPadding }) =>
    smallPadding ? theme.sizes.small : theme.sizes.medium};
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.sizes.medium};
`;

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledImage = styled(Image)``;

const ApplicationBeingProcessed = () => {
  const isMobile = useContext(IsMobileContext);

  return (
    <Centered smallPadding={isMobile}>
      <Wrapper>
        <a target="_blank" rel="noopener noreferrer" href="https://bambus.io">
          <BambusLogo width="170" height="23" />
        </a>
        <Content>
          {!isMobile && (
            <>
              <StyledImage />
              <HorizontalSpacer space={theme.sizes.small} />
            </>
          )}
          <VerticalLayout>
            <Heading color={theme.colors.bambusBlue100} size={5}>
              <FormattedMessage
                id="Pages.ApplicationBeingProcessed.Title"
                defaultMessage="Wir haben Ihren Antrag erhalten"
              />
            </Heading>
            <Paragraph large>
              <FormattedMessage
                id="Pages.ApplicationBeingProcessed.Text"
                defaultMessage="Unser Finanzierungsteam überprüft gerade Ihre Angaben und bereitet Ihr Angebot vor."
              />
            </Paragraph>
            <Button
              main
              as="a"
              // @ts-ignorep
              href="https://bambus.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="Pages.ApplicationBeingProcessed.ToLandingPage"
                defaultMessage="Zur Startseite"
              />
            </Button>
            {isMobile && (
              <StyledImage
                width="80%"
                style={{
                  position: 'fixed',
                  transform: 'translateY(100px)',
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                }}
              />
            )}
          </VerticalLayout>
        </Content>
      </Wrapper>
    </Centered>
  );
};

export default ApplicationBeingProcessed;
