import React from 'react';
import { Modal, Paragraph } from 'bambus-ui-components';
import { FormattedMessage } from 'react-intl';

type NavigateAwayBlockingModalProps = {
  isOpen: boolean;
  onStayOnDocUpload: () => void;
  onNavigateAway: () => void;
  documentTitle: string;
};

const NavigateAwayBlockingModal = ({
  isOpen,
  onStayOnDocUpload,
  onNavigateAway,
  documentTitle,
}: NavigateAwayBlockingModalProps) => {
  return (
    <Modal.Wrapper isOpen={isOpen} maxWidth={500} red>
      <Modal.CloseButton onClick={onStayOnDocUpload} />
      <Modal.Content>
        <Modal.Header tag="h4" size={4} red>
          <FormattedMessage
            id="Pages.Document.NavigateAway.QuestionWantToCancelUpload"
            defaultMessage="Upload abbrechen?"
          />
        </Modal.Header>
        <Paragraph medium>
          <FormattedMessage
            id="Pages.Document.NavigateAway.QuestionSubtitleWantToCancelUpload"
            defaultMessage="Möchten Sie wirklich den Upload von Dokument {documentTitle} abbrechen?"
            values={{
              documentTitle,
            }}
          />
        </Paragraph>
      </Modal.Content>
      <Modal.ButtonsWrapper>
        <Modal.Button onClick={onNavigateAway}>
          <FormattedMessage id="Cancel" defaultMessage="Abbrechen" />
        </Modal.Button>
        <Modal.Button onClick={onStayOnDocUpload} blue bold>
          <FormattedMessage id="Continue" defaultMessage="Fortfahren" />
        </Modal.Button>
      </Modal.ButtonsWrapper>
    </Modal.Wrapper>
  );
};

export default NavigateAwayBlockingModal;
