import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import theme from 'styles/theme';

import FeatherIconHolder from 'atoms/FeatherIconHolder';
import HorizontalSpacer from 'atoms/HorizontalSpacer';

const InnerContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.sizes.mini};
  border: 1px solid ${({ theme }) => theme.colors.bambusBlue25};
  border-radius: ${({ theme }) => theme.borderRadii.small};

  background-color: ${({ theme }) => theme.colors.bambusBlue5};
  color: ${({ theme }) => theme.colors.bambusBlue50};
  transition: all 0.3s;
`;

const StyledCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border: none;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const ToggleText = styled.span`
  font-size: 0.625em;
  font-weight: 700;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  user-select: none;

  ${StyledCheckbox}:focus ~ ${InnerContentWrapper} {
    background-color: ${({ theme }) => theme.colors.bambusBlue10};
  }

  ${StyledCheckbox}:active ~ ${InnerContentWrapper} {
    background-color: ${({ theme }) => theme.colors.bambusBlue25};
  }
`;

export type ToggleButtonProps = {
  textToggled: string;
  textUntoggled: string;
  featherIconToggled?: React.ReactNode | undefined;
  featherIconUntoggled?: React.ReactNode | undefined;
  onChange?: (newValue: boolean) => void;
  toggledByDefault?: boolean;
};

const ToggleButton = ({
  textToggled = '',
  textUntoggled = '',
  featherIconToggled,
  featherIconUntoggled,
  toggledByDefault,
  onChange = (newValue: boolean) => {},
}: ToggleButtonProps) => {
  const [isToggled, setIsToggled] = useState(!!toggledByDefault);

  useEffect(() => {
    setIsToggled(!!toggledByDefault);
  }, [toggledByDefault]);

  const onChangeCallback = useCallback(
    (event) => {
      if (onChange) {
        onChange(event.target.checked);
      }
      setIsToggled(event.target.checked);
    },
    [onChange]
  );
  return (
    <Wrapper>
      <StyledCheckbox checked={isToggled} onChange={onChangeCallback} />
      <InnerContentWrapper>
        {((isToggled && featherIconToggled) ||
          (!isToggled && featherIconUntoggled)) && (
          <FeatherIconHolder
            iconWidth="12px"
            iconFillColor={theme.colors.bambusBlue50}
          >
            {isToggled ? featherIconToggled : featherIconUntoggled}
          </FeatherIconHolder>
        )}
        <HorizontalSpacer space={theme.sizes.mini} />
        <ToggleText>{isToggled ? textToggled : textUntoggled}</ToggleText>
      </InnerContentWrapper>
    </Wrapper>
  );
};

export default ToggleButton;
