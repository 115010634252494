import React from 'react';

export type ScrollDocumentsContainerToTopContextType = {
  scrollDocumentsContainerToTop: () => any;
};

const ScrollDocumentsContainerToTopContext =
  React.createContext<ScrollDocumentsContainerToTopContextType>({
    scrollDocumentsContainerToTop: () => {},
  });

export default ScrollDocumentsContainerToTopContext;
