import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const DisabledStyle = css<any>`
  color: ${(p) => p.theme.colors.bambusBlue50};
  cursor: not-allowed;
`;

const ActiveStyle = css<any>`
  background-color: ${(p) => p.theme.colors.bambusBlue90};
`;

const StyledLink = styled(Link)<any>`
  display: inline-block;
  color: ${(p) => p.theme.colors.white};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.primaryNavigationText};
  border-radius: ${({ theme }) => theme.borderRadii.normal};
  margin-top: ${(p) => p.$marginTop};

  ${(p) => !p.disabled && p.$active && ActiveStyle}
  ${(p) => p.disabled && DisabledStyle}
`;

export type PrimaryNavigationLinkProps = {
  href: string;
  active?: boolean;
  disabled?: boolean;
  marginTop?: string;
  onClick?: () => void;
};

/**
 * React Component for a Primary Navigation Link
 */
const PrimaryNavigationLink = ({
  children,
  href = '/',
  active = false,
  disabled = false,
  marginTop = '0px',
  onClick: onClickProp,
  ...rest
}: React.PropsWithChildren<PrimaryNavigationLinkProps>) => {
  const onClick = useCallback(
    (event) => {
      if (disabled) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        onClickProp && onClickProp();
      }
    },
    [disabled, onClickProp]
  );

  return (
    <StyledLink
      to={disabled ? '' : href}
      disabled={disabled}
      $active={active}
      $marginTop={marginTop}
      onClick={onClick}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

export default PrimaryNavigationLink;
