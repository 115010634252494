import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modals');
const contentRoot = document.getElementById('root');

class Modal extends React.Component {
  constructor(props: any) {
    super(props);
    const that: any = this;
    that.modal = document.createElement('div');
    that.modal.style.position = 'fixed';
    that.modal.style.top = '0';
    that.modal.style.bottom = '0';
    that.modal.style.left = '0';
    that.modal.style.right = '0';
    that.modal.style.transform = 'translateZ(0)';

    // Prevent the body's overflow and then reset the original (on compnent unmount)
    that.bodyInitialOverflow = document.body.style.overflow;
    document.body.style.overflow = 'none';
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot?.appendChild((this as any).modal);

    /**
     * The contentRoot (all the other content behind the Modal) is set to display "none".
     * This is to solve a bug on mobile where long pressing on Modal items
     * caused the selection of the items that were behind the modal (in "contentRoot").
     */
    contentRoot!.style.display = 'none';
  }

  componentWillUnmount() {
    modalRoot?.removeChild((this as any).modal);
    contentRoot!.style.display = 'block';
    document.body.style.overflow = (this as any).bodyInitialOverflow;
  }

  render() {
    return ReactDOM.createPortal(this.props.children, (this as any).modal);
  }
}

export default Modal;
