import {
  Notification as NotificationAPI,
  ClusteredNotification as ClusteredNotificationAPI,
  NotificationType as NotificationTypeAPI,
} from 'api/requests';

// At the moment we're reusing the API types

type Notification = NotificationAPI;
type ClusteredNotification = ClusteredNotificationAPI;
type NotificationType = NotificationTypeAPI;

export type { Notification };
export type { NotificationType };
export type { ClusteredNotification };

// TypeScript user defined type guard
export function isNotificationClustered(
  notification: Notification | ClusteredNotification
): notification is ClusteredNotification {
  return (notification as ClusteredNotification).clusterName !== undefined;
}

// TODO: Move hardcoded text to i18n files when introducing a second language
export const getNotificationTitleBasedOnType = (
  notificationType: NotificationType
) => {
  switch (notificationType) {
    case 'added':
      return 'Neues Dokument erforderlich';
    case 'available_download':
      return 'Neues Dokument abrufbar';
    case 'available_download_and_sign':
      return 'Neues Dokument abrufbar';
    case 'accepted':
      return 'Dokument angenommen';
    case 'declined':
      return 'Dokument abgelehnt';
    default:
      return '-';
  }
};

/**
 * Function that gets the right document ID:
 * if the notification is regarding a simple document (not part of a cluster), return the document ID,
 * If the notification is regarding a cluster "child" document (part of a cluster), return the cluster ID
 */
export const getUsableDocumentID = (notification: Notification) => {
  // we have a notification that is for a clustered document
  if (notification.clusterId) {
    return notification.clusterId;
  } else {
    return notification.document;
  }
};

/**
 * Function that gets the right document title:
 * if the notification is regarding a simple document (not part of a cluster), return the document title,
 * If the notification is regarding a cluster "child" document (part of a cluster), return the cluster title concatenated with the document title
 */
export const getUsableDocumentTitle = (notification: Notification) => {
  // we have a notification that is for a clustered document
  if (notification.clusterId) {
    return `${notification.clusterTitle}-${notification.documentTitle}`;
  } else {
    return notification.documentTitle;
  }
};

export const getNotificationDescriptionBasedOnType = (
  notificationType: NotificationType,
  documentTitle: string
) => {
  switch (notificationType) {
    case 'added':
      return `${documentTitle} wird benötigt`;
    case 'available_download':
      return `${documentTitle} ist jetzt verfügbar`;
    case 'available_download_and_sign':
      return `${documentTitle} steht zur Signatur bereit`;
    case 'accepted':
      return `${documentTitle} wurde bearbeitet und bestätigt`;
    case 'declined':
      return `${documentTitle} korrigieren`;
    default:
      return '-';
  }
};

export const getClusterNotificationTitleBasedOnType = (
  notificationType: NotificationType
) => {
  switch (notificationType) {
    case 'added':
      return 'Neue Dokumente erforderlich';
    case 'available_download_and_sign':
      return 'Neue Dokumente abrufbar';
    case 'accepted':
      return 'Dokumente angenommen';
    case 'declined':
      return 'Dokumente abgelehnt';
    default:
      return '-';
  }
};

export const getClusterNotificationDescriptionBasedOnType = (
  notificationType: NotificationType,
  firstDocumentTitle: string,
  restNumberOfDocuments: number
) => {
  switch (notificationType) {
    case 'added':
      return `${firstDocumentTitle} und ${restNumberOfDocuments} weitere werden benötigt`;
    case 'available_download_and_sign':
      return 'Finanzierungsanfrage und Persönliches Angebot stehen zur Signatur bereit';
    case 'accepted':
      return `${firstDocumentTitle} und ${restNumberOfDocuments} weitere wurden bearbeitet und bestätigt`;
    case 'declined':
      return `${firstDocumentTitle} und ${restNumberOfDocuments} weitere korrigieren`;
    default:
      return '-';
  }
};
