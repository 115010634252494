import React from 'react';
import styled from 'styled-components';

const Text = styled.span<any>`
  color: ${({ color, theme }) => color || theme.colors.bambusBlue100};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${(p) => (p.small ? '0.75em' : '0.875em')};
`;

export type CaptionTextProps = {
  small?: boolean;
  color?: string;
  fontWeight?: number;
  [x: string]: any;
};

const CaptionText = ({
  children,
  small = false,
  color,
  fontWeight,
  ...rest
}: React.PropsWithChildren<CaptionTextProps>) => {
  return (
    <Text small={small} color={color} fontWeight={fontWeight} {...rest}>
      {children}
    </Text>
  );
};

export default CaptionText;
