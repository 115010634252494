import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from 'bambus-ui-components';
import theme from 'styles/theme';

import IsMobileContext from 'contexts/IsMobileContext';

import Heading from 'atoms/Heading';
import Tile from 'atoms/Tile';
import VerticalSpacer from 'atoms/VerticalSpacer';

import PasswordSetForm from 'molecules/PasswordSetForm';

const FormInnerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-template-areas:
    'current-password'
    'password'
    'password-confirmation';
  @media (min-width: 600px) {
    grid-template-areas:
      'current-password .'
      'password password-confirmation';
  }
  gap: ${({ theme }) => theme.sizes.medium};
`;

const PasswordAndSecurityWidget = () => {
  const isMobile = useContext(IsMobileContext);

  return (
    <PasswordSetForm>
      {({
        ContraintMessages,
        TextPasswordChangedSuccessfully,
        GetCurrentPasswordField,
        GetPasswordField,
        GetPasswordConfirmationField,
        isValid,
        dirty,
      }) => (
        <div>
          <Tile smallHorizontalPadding={isMobile}>
            <Heading weight={700} size={9} color={theme.colors.bambusBlue100}>
              <FormattedMessage
                id="Pages.Settings.ChangePassword"
                defaultMessage="Passwort ändern"
              />
            </Heading>
            <VerticalSpacer space={theme.sizes.small} />
            {ContraintMessages}
            {TextPasswordChangedSuccessfully}
            <VerticalSpacer space={theme.sizes.large} />
            <FormInnerWrapper>
              <div
                style={{
                  gridArea: 'current-password',
                }}
              >
                {GetCurrentPasswordField()}
              </div>
              <div
                style={{
                  gridArea: 'password',
                }}
              >
                {GetPasswordField()}
              </div>
              <div
                style={{
                  gridArea: 'password-confirmation',
                }}
              >
                {GetPasswordConfirmationField()}
              </div>
            </FormInnerWrapper>
          </Tile>
          <VerticalSpacer space={theme.sizes.small} />
          <Button main disabled={!isValid || !dirty} type="submit">
            <FormattedMessage id="Save" defaultMessage="Speichern" />
          </Button>
        </div>
      )}
    </PasswordSetForm>
  );
};

export default PasswordAndSecurityWidget;
