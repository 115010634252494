import React, { useContext } from 'react';
import styled from 'styled-components';

import theme from 'styles/theme';

import IsMobileContext from 'contexts/IsMobileContext';

import SubtitleText from 'atoms/SubtitleText';
import VerticalSpacer from 'atoms/VerticalSpacer';

import Document, { DocumentStatus } from 'molecules/Document';
import { GetDocumentsAPIResponseDocument } from 'api/requests';

const DocumentsGroupWrapper = styled.div``;

export type DocumentsGroupProps = {
  groupName: string;
  documents: GetDocumentsAPIResponseDocument[];
  showOnlyOutstanding: boolean;
  insertVerticalSpacerBelow?: boolean;
};

/**
 * 
 * if at least one document has been declined, then group status is declined
  else if at least one document is processing, then the group status is processing
  else if at least one document is open, then the group status is open
  if all documents are accepted, then the group status is accepted
 */
const calculateStatusForCluster = (childDocumentStatuses: DocumentStatus[]) => {
  let atLeastOneDeclined = false;
  let atLeastOneProcessing = false;
  let atLeastOneOpen = false;

  for (const docStatus of childDocumentStatuses) {
    if (docStatus === DocumentStatus.Declined) {
      atLeastOneDeclined = true;
      break;
    }
    if (
      docStatus === DocumentStatus.Processing ||
      docStatus === DocumentStatus.GraceEditable
    ) {
      atLeastOneProcessing = true;
    }
    if (docStatus === DocumentStatus.Open) {
      atLeastOneOpen = true;
    }
  }

  if (atLeastOneDeclined) {
    return DocumentStatus.Declined;
  }
  if (atLeastOneProcessing) {
    return DocumentStatus.Processing;
  }
  if (atLeastOneOpen) {
    return DocumentStatus.Open;
  }
  return DocumentStatus.Accepted;
};

/**
 * Renders a new group of documents (with a name) and the documents themselves.
 * Outstanding Doc: Document that needs attention (in Open or Declined state).
 */
const DocumentsGroup = ({
  groupName = '',
  documents = [],
  showOnlyOutstanding,
  insertVerticalSpacerBelow,
}: DocumentsGroupProps) => {
  const isMobile = useContext(IsMobileContext);

  let documentsToShow = showOnlyOutstanding
    ? documents.filter(
        ({ status: documentStatus, documents: childDocuments }) => {
          //check for a clustered doc
          if (Array.isArray(childDocuments)) {
            return childDocuments.some(
              ({ status: childDocumentStatus }) =>
                childDocumentStatus === DocumentStatus.Declined ||
                childDocumentStatus === DocumentStatus.Open
            );
          } else {
            //unclustered doc
            return (
              documentStatus === DocumentStatus.Declined ||
              documentStatus === DocumentStatus.Open
            );
          }
        }
      )
    : documents;

  if (documentsToShow.length === 0) return null;

  return (
    <DocumentsGroupWrapper>
      <SubtitleText strong={true} large={!isMobile}>
        {groupName}
      </SubtitleText>
      <VerticalSpacer space={theme.sizes.small} />
      {documentsToShow.map(
        (
          {
            id,
            isOptional,
            title,
            description,
            availableAt,
            period,
            association,
            note,
            status,
            dim,
            documents,
          },
          index
        ) => (
          <React.Fragment key={id}>
            {index > 0 && <VerticalSpacer space={theme.sizes.medium} />}
            {/* See whether we have a clustered document */}
            {Array.isArray(documents) ? (
              <Document
                id={id}
                type="cluster"
                title={title}
                description={description}
                availableAt={availableAt}
                period={period}
                isOptional={isOptional}
                association={association}
                note={note}
                status={calculateStatusForCluster(
                  documents.map((doc) => doc.status)
                )}
                clusterDocuments={documents}
                dim={dim}
              />
            ) : (
              <Document
                id={id}
                type="single"
                title={title}
                description={description}
                availableAt={availableAt}
                period={period}
                isOptional={isOptional}
                association={association}
                note={note}
                status={status}
                dim={dim}
              />
            )}
          </React.Fragment>
        )
      )}
      {insertVerticalSpacerBelow && (
        <VerticalSpacer space={theme.sizes.extraLarge} />
      )}
    </DocumentsGroupWrapper>
  );
};

export default DocumentsGroup;
