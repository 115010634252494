import React from 'react';
import styled from 'styled-components';
import { BookOpen } from 'react-feather';

import theme from 'styles/theme';

import FeatherIconHolder from 'atoms/FeatherIconHolder';

const NoteWrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: ${(p) => p.theme.sizes.extraSmall};

  background-color: ${(p) => p.theme.colors.bambusBlue50};
  border-radius: ${({ theme }) => theme.borderRadii.small};
`;

const Message = styled.span`
  color: ${(p) => p.theme.colors.white};
  font-size: 0.875em;
  line-height: 21px;
  margin-left: ${(p) => p.theme.sizes.small};
`;

export type NoteProps = {
  note: string;
};

const DocumentNote = ({ note = '' }: NoteProps) => {
  return (
    <NoteWrapper>
      <FeatherIconHolder
        style={{ alignSelf: 'baseline' }}
        iconFillColor={theme.colors.white}
      >
        <BookOpen />
      </FeatherIconHolder>
      <Message>{note}</Message>
    </NoteWrapper>
  );
};

export default DocumentNote;
