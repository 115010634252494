import React, { useContext } from 'react';
import styled from 'styled-components';
import ReactPlaceholder from 'react-placeholder';
import { TextBlock } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

import IsMobileContext from 'contexts/IsMobileContext';

import theme from 'styles/theme';

import Tile from 'atoms/Tile';
import VerticalSeparator from 'atoms/VerticalSeparator';
import HorizontalSpacer from 'atoms/HorizontalSpacer';
import HorizontalSeparator from 'atoms/HorizontalSeparator';

const ProfileWrapper = styled.div<any>`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ProfileInfoWrapper = styled.div<any>`
  ${({ width }) => width && `width: ${width};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
`;

const ProfileInfo = () => {
  const isMobile = useContext(IsMobileContext);

  return (
    <ProfileInfoWrapper
      width={!isMobile ? '45%' : '100%'}
      marginTop="10px"
      marginBottom="10px"
    >
      <TextBlock rows={4} lineSpacing="21px" color={theme.colors.bambusBlue5} />
    </ProfileInfoWrapper>
  );
};

const profilePlaceholder = (
  <Tile>
    <ProfileWrapper>
      <ProfileInfo />
      <VerticalSeparator marginLeft="5%" marginRight="4%" />
      <ProfileInfo />
      <HorizontalSeparator width="100%" marginTop="20px" marginBottom="20px" />
      <ProfileInfo />
      <HorizontalSpacer space="9%" />
      <ProfileInfo />
    </ProfileWrapper>
  </Tile>
);

const ProfilePlaceholder = () => {
  return (
    <ReactPlaceholder
      customPlaceholder={profilePlaceholder}
      showLoadingAnimation
      ready={false}
      children={null}
    />
  );
};

export default ProfilePlaceholder;
