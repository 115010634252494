import React, { useCallback, useRef, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { ArrowLeft, ArrowRight, ArrowUp, ArrowDown, Eye } from 'react-feather';
import MediumZoom, { Zoom } from 'medium-zoom';
import { IntlShape, useIntl } from 'react-intl';

import IsMobileContext from 'contexts/IsMobileContext';

import theme from 'styles/theme';

import CaptionText from 'atoms/CaptionText';
import VerticalSpacer from 'atoms/VerticalSpacer';

export const MOBILE_ACTIVE_SCALE: number = 1.4;

const PageWrapper = styled.article`
  position: relative;
  background-color: transparent;
  &,
  & * {
    user-select: none;
    -webkit-touch-callout: none;
  }
  z-index: 10;
`;

const PageContent = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform ${theme.animationDurations.normal};

  ${({ isMobile, active, isCurrentlyDragged }) =>
    isMobile &&
    active &&
    !isCurrentlyDragged &&
    `
      transform: scale(${MOBILE_ACTIVE_SCALE});
    `}
  ${({ isMobile, isBeforeActive }) =>
    isMobile &&
    isBeforeActive &&
    `
      transform: translateY(-20%);
    `}
  ${({ isMobile, isAfterActive }) =>
    isMobile &&
    isAfterActive &&
    `
      transform: translateY(20%);
    `}

  ${({ isMobile, isCurrentlyDragged }) =>
    isMobile &&
    isCurrentlyDragged &&
    `
      animation: StartDragAnimation 0.3s forwards;
    `}

  @keyframes StartDragAnimation {
    0% {
      transform: rotate(0deg) scale(1);
    }
    33% {
      transform: rotate(5deg) scale(1);
    }
    66% {
      transform: rotate(-5deg) scale(1);
    }
    100% {
      transform: rotate(5deg) scale(1.1);
    }
  }
`;

const ImageWrapper = styled.div<any>`
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadii.small};
  ${({ active, theme }) =>
    active && `border: 1px solid ${theme.colors.bambusBlue100};`};
`;

const Image = styled.img<any>`
  display: block;
  border-radius: ${({ theme }) => theme.borderRadii.small};
  max-width: 100%;
  ${({ isMobile }) =>
    isMobile &&
    `
      width: 50vw;
      max-width: 200px;
    `}

  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.05);
  &:not(.medium-zoom-image--opened) {
    cursor: pointer;
  }
  pointer-events: none;
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.actionBlue};
  cursor: pointer;
  ${({ hidden }) => hidden && 'visibility:hidden;'}
`;

const ButtonsHolderMobileStyles = css`
  ${() => `
    flex-direction: column;
    width: 30px;
    bottom: 20px;
    right: -15px;
  `}
`;

const ButtonsHolderDesktopStyles = css`
  ${() => `
    left: -16px;
    right: -16px;
    bottom: 20px;
    height: 30px;
    justify-content: space-between;
    `}
`;

const ButtonsHolder = styled.div<any>`
  position: absolute;
  display: flex;
  z-index: 2;
  ${({ isMobile }) =>
    isMobile ? ButtonsHolderMobileStyles : ButtonsHolderDesktopStyles};
`;

const CaptionTextHolderMobilStyles = css`
  ${({ theme }) => `
    position: absolute;
    top: 20px;
    left: 5px;
    
    padding: 0.2rem 0.7rem;
    background-color: ${theme.colors.bambusBlue5};
    border-radius: ${theme.borderRadii.small};
  `}
`;

const CaptionTextHolder = styled.div<any>`
  ${({ isMobile }) => isMobile && CaptionTextHolderMobilStyles}
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DragHandle = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const ButtonIconSize = '18';

export type PageProps = {
  imgURL: string;
  highResolutionImgURL?: string;
  pageIndex: number;
  onClick: () => void;
  dragHandle: React.FunctionComponent;
  active: boolean;
  isBeforeActive: boolean;
  isAfterActive: boolean;
  isCurrentlyDragged: boolean;
  hideLeftArrow: boolean;
  hideRightArrow: boolean;
  onMoveLeft: (pageIndex: number) => void;
  onMoveRight: (pageIndex: number) => void;
  onImageLoadFinished: () => void;
};

const Page = React.memo(
  ({
    imgURL = '',
    highResolutionImgURL = '',
    pageIndex,
    onClick,
    onImageLoadFinished,
    dragHandle: DragHandle,
    active,
    isBeforeActive,
    isAfterActive,
    isCurrentlyDragged,
    hideLeftArrow,
    hideRightArrow,
    onMoveLeft,
    onMoveRight,
  }: PageProps) => {
    const image = useRef<HTMLElement>();
    const mediumZoom = useRef<Zoom>();
    const intl: IntlShape = useIntl();

    const onPreviewClick = useCallback(() => {
      mediumZoom.current?.open();
    }, []);

    useEffect(() => {
      mediumZoom.current = MediumZoom(image.current);
    }, []);

    const onMoveLeftPress = useCallback(
      (event) => {
        event.stopPropagation();
        onMoveLeft(pageIndex);
      },
      [onMoveLeft, pageIndex]
    );

    const onMoveRightPress = useCallback(
      (event) => {
        event.stopPropagation();
        onMoveRight(pageIndex);
      },
      [onMoveRight, pageIndex]
    );

    const isMobile = useContext(IsMobileContext);

    return (
      <PageWrapper onClick={onClick} data-page-index={pageIndex}>
        <PageContent
          isMobile={isMobile}
          active={active}
          isCurrentlyDragged={isCurrentlyDragged}
          isAfterActive={isAfterActive}
          isBeforeActive={isBeforeActive}
        >
          <CaptionTextHolder isMobile={isMobile}>
            <CaptionText
              fontWeight={700}
              small
              color={
                isMobile ? theme.colors.bambusBlue50 : theme.colors.bambusBlue25
              }
            >
              {pageIndex + 1}
            </CaptionText>
          </CaptionTextHolder>
          <VerticalSpacer space={theme.sizes.extraSmall} />
          <DragHandle />
          <ImageWrapper active={active}>
            <Image
              src={imgURL}
              data-zoom-src={highResolutionImgURL || imgURL}
              ref={image}
              onLoad={onImageLoadFinished}
              isMobile={isMobile}
            ></Image>
          </ImageWrapper>
          {active && !isCurrentlyDragged && (
            <ButtonsHolder isMobile={isMobile}>
              <Button
                hidden={hideLeftArrow}
                onClick={onMoveLeftPress}
                title={intl.formatMessage({
                  id: 'Left',
                  defaultMessage: 'Links',
                })}
              >
                {isMobile ? (
                  <ArrowUp size={ButtonIconSize} color={theme.colors.white} />
                ) : (
                  <ArrowLeft size={ButtonIconSize} color={theme.colors.white} />
                )}
              </Button>
              {!isMobile && (
                <Button
                  onClick={onPreviewClick}
                  title={intl.formatMessage({
                    id: 'Preview',
                    defaultMessage: 'Vorschau',
                  })}
                >
                  <Eye size={ButtonIconSize} color={theme.colors.white} />
                </Button>
              )}
              {isMobile && <VerticalSpacer space={theme.sizes.small} />}
              <Button
                hidden={hideRightArrow}
                onClick={onMoveRightPress}
                title={intl.formatMessage({
                  id: 'Right',
                  defaultMessage: 'Rechts',
                })}
              >
                {isMobile ? (
                  <ArrowDown size={ButtonIconSize} color={theme.colors.white} />
                ) : (
                  <ArrowRight
                    size={ButtonIconSize}
                    color={theme.colors.white}
                  />
                )}
              </Button>
            </ButtonsHolder>
          )}
        </PageContent>
      </PageWrapper>
    );
  }
);

export default Page;
