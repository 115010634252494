import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import {
  isNotificationClustered,
  getNotificationTitleBasedOnType,
  getNotificationDescriptionBasedOnType,
  getClusterNotificationTitleBasedOnType,
  getClusterNotificationDescriptionBasedOnType,
  getUsableDocumentID,
  getUsableDocumentTitle,
} from 'models/Notifications';

import { NOTIFICATIONS_TOOLTIP_ID } from 'atoms/NotificationsTooltip';
import NotificationsTooltip from 'atoms/NotificationsTooltip';

import MobileGlobalNotificationsTooltip, {
  REALTIME_NOTIFICATIONS_TOOLTIP_ID,
} from 'organisms/MobileGlobalNotificationsTooltip';

import IsMobileContext from 'contexts/IsMobileContext';
import NotificationsContext from 'contexts/NotificationsContext';

import { getTimeElapsed } from 'utils/Date';

interface IconButtonProps {
  icon: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Parent = styled.div`
  position: relative;
`;

const InvisibleElementMobileRealtimeNotificationsTrigger = styled.div<any>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledButtonIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button<any>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 6px;

  background-color: transparent;

  border-color: transparent;
  border: none;

  cursor: pointer;

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.purple};
    outline-offset: 4px;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

const StyledBadge = styled.span`
  position: absolute;
  top: -5px;
  right: -2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;

  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
`;

const NotificationsButtonWithTooltip = ({
  icon,
  ...props
}: IconButtonProps) => {
  const buttonRef = useRef<HTMLElement | null>(null);
  const mobileRealtimeTooltipTriggerRef = useRef<HTMLElement | null>(null);

  const [buttonMiddlePositionX, setButtonMiddlePositionX] = useState<
    number | null
  >(null);

  const isMobile = useContext(IsMobileContext);

  const {
    activeNotifications,
    numberOfNewNotifications,
    onNotificationClick,
    onNotificationDismiss,
    markAllNotificationsSeen,
    newNotifications,
    clearAllNewNotifications,
  } = useContext(NotificationsContext);

  useEffect(() => {
    if (isMobile && buttonRef.current !== null) {
      // Calculating the center of the Notifications button, as the Tooltip's arrow should appear there
      const rect = buttonRef.current.getBoundingClientRect();
      // 10 is a "magic" number that accounts for the size of the arrow
      setButtonMiddlePositionX(rect.left + rect.width / 2 - 10);
    } else {
      setButtonMiddlePositionX(null);
    }
  }, [isMobile]);

  useEffect(() => {
    ReactTooltip.rebuild();

    const isNotificationsListOpen = document
      .getElementById('notifications-tooltip')
      ?.classList.contains('show');

    if (newNotifications.length > 0 && !isNotificationsListOpen) {
      mobileRealtimeTooltipTriggerRef.current &&
        ReactTooltip.show(mobileRealtimeTooltipTriggerRef.current);
    }
  }, [newNotifications]);

  const hideMobileRealtimeNotificationsTooltip = () => {
    // we need this hacky custom event here to tell the
    // handler to remove some thing form an internal react-tooltip ref
    // in order to programatically close the modal... See:
    // https://github.com/wwayne/react-tooltip/issues/449
    document.dispatchEvent(
      new Event('cockpit:HACKYcloseMobileGlobalNotificationsTooltip')
    );
    mobileRealtimeTooltipTriggerRef.current &&
      ReactTooltip.hide(mobileRealtimeTooltipTriggerRef.current);
  };

  const hideNotificationsTooltip = () => {
    // we need this hacky custom event here to tell the
    // handler to remove some thing form an internal react-tooltip ref
    // in order to programatically close the modal... See:
    // https://github.com/wwayne/react-tooltip/issues/449
    document.dispatchEvent(new Event('cockpit:HACKYcloseNotificationsTooltip'));
    buttonRef.current && ReactTooltip.hide(buttonRef.current);
  };

  const onOpenNotificationsTooltip = () => {
    markAllNotificationsSeen();
    hideMobileRealtimeNotificationsTooltip();
    clearAllNewNotifications();
  };

  // intercepting the click event so we can close the tooltip
  const _onNotificationClick = (
    documentsIds: (string | number)[],
    notificationsIds: string[]
  ) => {
    hideMobileRealtimeNotificationsTooltip();
    hideNotificationsTooltip();
    onNotificationClick(documentsIds, notificationsIds);
  };

  const _onMobileRealtimeNotificationClick = (
    documentsIds: (string | number)[],
    notificationsIds: string[]
  ) => {
    clearAllNewNotifications();
    _onNotificationClick(documentsIds, notificationsIds);
  };

  // intercepting the dismiss event so we can close the tooltip
  const _onNotificationDismiss = (notificationsIds: string[]) => {
    hideMobileRealtimeNotificationsTooltip();
    hideNotificationsTooltip();
    onNotificationDismiss(notificationsIds);
  };

  return (
    <Parent>
      {isMobile && (
        <InvisibleElementMobileRealtimeNotificationsTrigger
          ref={mobileRealtimeTooltipTriggerRef}
          data-tip
          data-place="bottom"
          data-effect="solid"
          data-event="click"
          data-for={REALTIME_NOTIFICATIONS_TOOLTIP_ID}
          data-scroll-hide={false}
        />
      )}
      <StyledButton
        ref={buttonRef}
        data-tip
        data-place="bottom"
        data-effect="solid"
        data-event="click"
        data-for={NOTIFICATIONS_TOOLTIP_ID}
        data-scroll-hide={false}
        {...props}
      >
        <StyledButtonIcon>{icon}</StyledButtonIcon>
        {numberOfNewNotifications > 0 && (
          <StyledBadge>{numberOfNewNotifications}</StyledBadge>
        )}
      </StyledButton>
      <NotificationsTooltip
        notifications={activeNotifications.map((notification) => {
          if (isNotificationClustered(notification)) {
            return {
              notificationIds: notification.clusterList.map(
                (notification) => notification.id
              ),
              id: notification.clusterList[0]?.id,
              title: getClusterNotificationTitleBasedOnType(
                notification.clusterName
              ),
              description: getClusterNotificationDescriptionBasedOnType(
                notification.clusterName,
                notification.clusterList[0]
                  ? getUsableDocumentTitle(notification.clusterList[0])
                  : '',
                notification.clusterList.length - 1
              ),
              documentIds: notification.clusterList.reduce<(string | number)[]>(
                (documentIds, notification) => {
                  return [...documentIds, getUsableDocumentID(notification)];
                },
                []
              ),
              notificationType: notification.clusterName,
              // for a cluster's time elapsed value we're taking the creation time of the newest notification
              timeElapsedText: getTimeElapsed(
                new Date(notification.clusterList[0]?.createdAt ?? new Date())
              ),
            };
          } else {
            return {
              notificationIds: [notification.id],
              id: notification.id,
              title: getNotificationTitleBasedOnType(
                notification.notificationType
              ),
              description: getNotificationDescriptionBasedOnType(
                notification.notificationType,
                getUsableDocumentTitle(notification)
              ),
              documentIds: [getUsableDocumentID(notification)],
              notificationType: notification.notificationType,
              timeElapsedText: getTimeElapsed(new Date(notification.createdAt)),
            };
          }
        })}
        onNotificationClick={_onNotificationClick}
        {...(isMobile && {
          arrowPosLeft: `${buttonMiddlePositionX}px`,
        })}
        onOpen={onOpenNotificationsTooltip}
      />
      {isMobile && (
        <MobileGlobalNotificationsTooltip
          notifications={newNotifications.map((notification) => {
            if (isNotificationClustered(notification)) {
              return {
                notificationIds: notification.clusterList.map(
                  (notification) => notification.id
                ),
                id: notification.clusterList[0]?.id,
                title: getClusterNotificationTitleBasedOnType(
                  notification.clusterName
                ),
                description: getClusterNotificationDescriptionBasedOnType(
                  notification.clusterName,
                  notification.clusterList[0]
                    ? getUsableDocumentTitle(notification.clusterList[0])
                    : '',
                  notification.clusterList.length - 1
                ),
                documentIds: notification.clusterList.reduce<
                  (string | number)[]
                >((documentIds, notification) => {
                  return [...documentIds, getUsableDocumentID(notification)];
                }, []),
                notificationType: notification.clusterName,
                // for a cluster's time elapsed value we're taking the creation time of the newest notification
                timeElapsedText: getTimeElapsed(
                  new Date(notification.clusterList[0]?.createdAt ?? new Date())
                ),
              };
            } else {
              return {
                notificationIds: [notification.id],
                id: notification.id,
                title: getNotificationTitleBasedOnType(
                  notification.notificationType
                ),
                description: getNotificationDescriptionBasedOnType(
                  notification.notificationType,
                  getUsableDocumentTitle(notification)
                ),
                documentIds: [getUsableDocumentID(notification)],
                notificationType: notification.notificationType,
                timeElapsedText: getTimeElapsed(
                  new Date(notification.createdAt)
                ),
              };
            }
          })}
          onNotificationClick={_onMobileRealtimeNotificationClick}
          {...(isMobile && {
            arrowPosLeft: `${buttonMiddlePositionX}px`,
          })}
          onNotificationDismiss={_onNotificationDismiss}
        />
      )}
    </Parent>
  );
};

export default NotificationsButtonWithTooltip;
