import React from 'react';
import { useMediaQuery } from 'react-responsive';

import theme from 'styles/theme';

export type DesktopProps = {};

/**
 * React Wrapper that renders the children only on mobile.
 */
const Desktop = ({ children }: React.PropsWithChildren<DesktopProps>) => {
  const isDesktop = useMediaQuery({
    minWidth: theme.breakpoints.desktopMinWidthPx,
  });
  return isDesktop ? <>{children}</> : null;
};

export default Desktop;
