const routes = {
  applicationBeingProcessedRoute: '/application-being-processed',
  profilRoute: '/profile',
  documentsRoute: '/documents',
  settingsRoute: '/settings',
  documentRouterPath: '/documents/:id',
};

export const getDocumentRoute = (id: string) => `/documents/${id}`;

export default routes;

// Note: We have the SetCurrentPageOnNavigation component which still relies on hardcoded values
