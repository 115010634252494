import { css } from 'styled-components';

export const FadeIn = css`
  animation: FadeIn ${({ theme }) => theme.animationDurations.normal};
  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const FadeOut = css`
  animation: FadeOut ${({ theme }) => theme.animationDurations.normal};
  @keyframes FadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const FadeAndSlideIn = css`
  animation: FadeAndSlideIn ${({ theme }) => theme.animationDurations.normal};
  @keyframes FadeAndSlideIn {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
`;
