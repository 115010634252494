import React, { useContext } from 'react';
import { Menu } from 'react-feather';
import styled from 'styled-components';
import { IntlShape, useIntl } from 'react-intl';
import { Bell } from 'react-feather';

import theme from 'styles/theme';

import { Page } from 'pages/PagesList';

import CurrentPageContext from 'contexts/CurrentPageContext';

import HorizontalSpacer from 'atoms/HorizontalSpacer';
import IconHolder from 'atoms/SVGIconHolder';
import FeatherIconHolder from 'atoms/FeatherIconHolder';
import NotificationsButtonWithTooltip from 'atoms/NotificationsButtonWithTooltip';

import { ReactComponent as BambusLogo } from 'illustrations/BambusWhiteLogoWithName.svg';

const Header = styled.header<any>`
  position: sticky;
  top: 0;
  grid-area: ${(p) => p.gridAreaName};
  height: ${(p) => p.theme.sizes.mobile.headerHeight};
  z-index: ${({ theme }) => theme.zIndexes.mobileHeader};

  display: flex;
  justify-content: space-between;

  padding: ${(p) => p.theme.sizes.small};
  background-color: ${(p) => p.theme.colors.bambusBlue100};
`;

const TitleAndMenuButtonHolder = styled.div`
  display: flex;
  align-items: center;
`;

const PageTitle = styled.span`
  font-size: 1em;
  line-height: 1em;
  color: ${({ theme }) => theme.colors.white};
`;

const LogoAndNotificationsHolder = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Function to get the visual name of a page.
 * @param page - Current Page
 * @param intl - Internationalization Message Getter
 */
const getPageName = (page: Page | null, intl: IntlShape) => {
  switch (page) {
    case Page.DOCUMENTS:
      return intl.formatMessage({
        id: 'PageNames.Documents',
        defaultMessage: 'Dokumente',
      });
    case Page.PROFILE:
      return intl.formatMessage({
        id: 'PageNames.Profile',
        defaultMessage: 'Profil',
      });
    case Page.SETTINGS:
      return intl.formatMessage({
        id: 'PageNames.Settings',
        defaultMessage: 'Einstellungen',
      });
    default:
      return '';
  }
};

export type MobileHeaderProps = {
  gridAreaName?: string;
  onHamburgerPress?: () => void;
};

const MobileHeader = ({
  gridAreaName = '',
  onHamburgerPress = () => {},
}: MobileHeaderProps) => {
  const { currentPage } = useContext(CurrentPageContext);
  const intl = useIntl();

  return (
    <Header gridAreaName={gridAreaName}>
      <LogoAndNotificationsHolder>
        <IconHolder iconWidth="72px">
          <BambusLogo />
        </IconHolder>
        <HorizontalSpacer space={'5px'} />
        <NotificationsButtonWithTooltip
          icon={<Bell color={theme.colors.bambusBlue50} size={22} />}
        />
      </LogoAndNotificationsHolder>
      <TitleAndMenuButtonHolder onClick={onHamburgerPress} role="button">
        <PageTitle>{getPageName(currentPage, intl)}</PageTitle>
        <HorizontalSpacer space={theme.sizes.extraSmall} />
        <FeatherIconHolder
          iconWidth="18px"
          padding="0.2em"
          iconFillColor={theme.colors.white}
        >
          <Menu />
        </FeatherIconHolder>
      </TitleAndMenuButtonHolder>
    </Header>
  );
};

export default MobileHeader;
