import { useEffect } from 'react';

import history from 'utils/History';
import { trackEventGTM } from 'analytics/GTM';

const Analytics = () => {
  /**
   * Set up tracking of page views for Matomo and GTM
   */
  useEffect(() => {
    const unlisten = history.listen(() => {
      // Matomo
      const _paq: any = (window as any)._paq;
      if (_paq) {
        _paq.push(['setCustomUrl', window.location]);
        _paq.push(['setDocumentTitle', document.title]);
        _paq.push(['trackPageView']);
      }

      setTimeout(() => {
        // GTM
        trackEventGTM({ event: 'pageView', pagePath: window.location.href });
      }, 50);
    });

    return () => {
      unlisten();
    };
  }, []);

  // GTM also needs this pageView event on app startup
  useEffect(() => {
    setTimeout(() => {
      // GTM
      trackEventGTM({ event: 'pageView', pagePath: window.location.href });
    }, 50);
  }, []);

  return null;
};

export default Analytics;
