import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.6);
  div {
    transform-origin: 40px 40px;
    animation: lds-spinner 0.8s linear infinite;
  }
  div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 30%;
    background: ${({ theme }) => theme.colors.bambusBlue100};
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.8s;
  }
  div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -0.7s;
  }
  div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.6s;
  }
  div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.5s;
  }
  div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.4s;
  }
  div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.3s;
  }
  div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: -0.1s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const LoadingIndicator = () => {
  return (
    <Wrapper>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  );
};

export default LoadingIndicator;
