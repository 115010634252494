import React from 'react';
import styled from 'styled-components';
import { AlertCircle } from 'react-feather';

import theme from 'styles/theme';

import FeatherIconHolder from 'atoms/FeatherIconHolder';

const ValidationWrapper = styled.section<any>`
  display: flex;
  justify-content: center;
  padding: ${(p) => p.theme.sizes.extraSmall};

  background-color: ${(p) => p.theme.colors.red};
  border-radius: ${({ theme }) => theme.borderRadii.small};
`;

const Message = styled.span`
  color: ${(p) => p.theme.colors.white};
  font-size: 0.875em;
  line-height: 21px;
  margin-left: ${(p) => p.theme.sizes.small};
`;

export type ValidationMessageProps = {
  message: string;
};

const DocumentValidationMessage = ({
  message = '',
}: ValidationMessageProps) => {
  return (
    <ValidationWrapper>
      <FeatherIconHolder
        style={{ alignSelf: 'baseline' }}
        iconFillColor={theme.colors.white}
      >
        <AlertCircle />
      </FeatherIconHolder>
      <Message>{message}</Message>
    </ValidationWrapper>
  );
};

export default DocumentValidationMessage;
