import React from 'react';
import styled from 'styled-components';
import { Check, Upload, Clock, AlertTriangle } from 'react-feather';
import { useIntl } from 'react-intl';

import theme from 'styles/theme';

import SubtitleText from 'atoms/SubtitleText';

import IconTextIndicator from 'molecules/IconTextIndicator';

const StatusesWrapper = styled.div<any>`
  display: flex;
  flex-wrap: wrap;

  padding-top: ${(p) => p.theme.sizes.extraSmall};

  margin-bottom: calc(-1 * ${(p) => p.theme.sizes.extraSmall});
  margin-left: calc(-1 * ${(p) => p.theme.sizes.small});
  & > * {
    margin-bottom: ${(p) => p.theme.sizes.extraSmall};
    margin-left: ${(p) => p.theme.sizes.small};
  }
`;

/**
 * Renders a visual reference (legend) for the statuses a document can be in.
 */
const DocumentStatusReference = () => {
  const intl = useIntl();

  return (
    <div>
      <SubtitleText>Upload Status</SubtitleText>
      <StatusesWrapper>
        <IconTextIndicator
          featherIcon={<Upload />}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.Open',
            defaultMessage: 'Zu erledigen',
          })}
          indicatorTextColor={theme.colors.bambusBlue50}
        ></IconTextIndicator>
        <IconTextIndicator
          featherIcon={<Clock />}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.InProcessing',
            defaultMessage: 'In Bearbeitung',
          })}
          indicatorTextColor={theme.colors.purple}
        ></IconTextIndicator>
        <IconTextIndicator
          featherIcon={<Check />}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.Accepted',
            defaultMessage: 'Akzeptiert',
          })}
          indicatorTextColor={theme.colors.green}
        ></IconTextIndicator>
        <IconTextIndicator
          featherIcon={<AlertTriangle />}
          indicatorText={intl.formatMessage({
            id: 'Document.Status.Declined',
            defaultMessage: 'Abgelehnt',
          })}
          indicatorTextColor={theme.colors.red}
        ></IconTextIndicator>
      </StatusesWrapper>
    </div>
  );
};

export default DocumentStatusReference;
