import React, { useContext, useCallback, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Eye as EyeIcon,
  Download as DownloadIcon,
  Repeat as OrderIcon,
} from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import IsMobileContext from 'contexts/IsMobileContext';

import { Button, Paragraph } from 'bambus-ui-components';
import theme from 'styles/theme';

import Heading from 'atoms/Heading';
import TextArea from 'atoms/TextArea';
import VerticalSpacer from 'atoms/VerticalSpacer';
import Mobile from 'atoms/Mobile';
import Desktop from 'atoms/Desktop';
import HorizontalSpacer from 'atoms/HorizontalSpacer';

import { DocumentStatus } from 'molecules/Document';

import PDFDocumentViewer from 'organisms/PDFDocumentViewer';

import MobileReviewWrapper from './MobileReviewWrapper';

import { confirmDocument } from 'api/requests';

import {
  sendLandedOnReviewStepAnalytics,
  sendSubmitDocumentAnalytics,
  sendDocumentReviewOpenedOnMobileAnalytics,
} from 'analytics/DocumentAnalytics';
import routes from 'routes';

const PDFReviewHolder = styled.div`
  width: 100%;

  height: 40rem;
  height: min(40rem, 75vh);

  font-family: inherit;
`;

const ButtonsHolderMobileStyles = css`
  ${({ theme }) => `
    grid-template-columns: 1fr;
    grid-template-rows: auto ${theme.sizes.mini} auto;
    grid-template-areas: 
      'upload-submit-button upload-submit-button upload-submit-button'
      '. . .'
      'upload-cancel-button upload-cancel-button upload-cancel-button';
`}
`;

const ButtonsHolderDesktopStyles = css`
  ${({ theme }) => `
    grid-template-columns: auto ${theme.sizes.small} auto 1fr;
    grid-template-areas: 
      'upload-submit-button . upload-cancel-button .';
`}
`;

const ButtonsHolder = styled.div<any>`
  display: grid;

  ${({ isMobile }) =>
    isMobile ? ButtonsHolderMobileStyles : ButtonsHolderDesktopStyles};
`;

const ActionButtonsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

// this is hacky and should be gone after we adjust our Paragraph component
// (at the moment it doesn't accept the style prop)
const RemoveMarginFromParagraph = styled.div`
  & > p {
    margin: 0;
  }
`;

export type ReviewScreenProps = {
  isLocked?: boolean;
  documentURL: string;
  documentId: string;
  documentStatus: DocumentStatus | undefined;
  customerNote?: string;
  onReorderPagesIntention: () => void;
  autoOpenMobileReviewScreen: boolean;
  onSubmitDocument: () => void;
};

/**
 *
 * @param props.documentURL The URL to the final PDF document.
 * @param props.isLocked Whether the document is in locked state.
 * @param props.documentId The ID of the document.
 * @param props.documentStatus The status of the document. This is mainly needed for Analytics.
 * Locked State: The upload can't be restarted, only the final document can be downloaded. Note can't be modified.
 */
const Review = ({
  isLocked,
  documentURL,
  documentId,
  documentStatus,
  onReorderPagesIntention,
  onSubmitDocument,
  autoOpenMobileReviewScreen,
  customerNote = '',
  history,
}: RouteComponentProps & ReviewScreenProps) => {
  const isMobile = useContext(IsMobileContext);
  const intl = useIntl();

  const [isMobileReviewShown, setIsMobileReviewShown] = useState<boolean>(
    autoOpenMobileReviewScreen
  );

  const [isConfirmingInProgress, setIsConfirmingInProgress] =
    useState<boolean>(false);

  const [note, setNote] = useState<string>(customerNote);

  useEffect(() => {
    if (documentId) {
      sendLandedOnReviewStepAnalytics({
        documentId,
        isDocumentLocked: isLocked,
      });
    }
  }, [documentId, isLocked]);

  const onMobileReviewPress = useCallback(() => {
    sendDocumentReviewOpenedOnMobileAnalytics({ documentId });
    setIsMobileReviewShown(true);
  }, [documentId]);

  const onMobileReviewClose = useCallback(() => {
    setIsMobileReviewShown(false);
  }, []);

  const onNoteChange = useCallback((event) => {
    setNote(event.target.value);
  }, []);

  const onSubmitOrGoToOverviewButtonPress = useCallback(async () => {
    /**
     * If the document is not in locked state (i.e. user can submit it)
     * then submit (confirm) the document and send analytics.
     */
    if (!isLocked) {
      setIsConfirmingInProgress(true);
      onSubmitDocument && onSubmitDocument();
      await confirmDocument({ documentId, note });
      setIsConfirmingInProgress(false);
      /**
       * Tracks 2 things:
       * 1. A newly submitted or resubmitted document (all document statuses)
       * 2. A newly submitted document (status only Open and Declined)
       */
      sendSubmitDocumentAnalytics({
        documentId,
        shouldSendSubmittedRequiredDocumentAnalytics:
          documentStatus === DocumentStatus.Open ||
          documentStatus === DocumentStatus.Declined,
        shouldSendSubmittedNewDocumentAnalytics:
          documentStatus === DocumentStatus.Open,
      });
    }
    history.push(routes.documentsRoute);
  }, [documentId, isLocked, history, documentStatus, note, onSubmitDocument]);

  return (
    <div>
      {isLocked ? (
        documentURL !== '' && (
          <Button
            as="a"
            outline
            // @ts-ignore
            href={documentURL}
            target="_blank"
            rel="noreferrer noopener"
            icon={<DownloadIcon />}
            style={{ width: isMobile ? '100%' : 'auto' }}
          >
            <FormattedMessage
              id="DownloadFile"
              defaultMessage="Datei herunterladen"
            />
          </Button>
        )
      ) : (
        <ActionButtonsHolder>
          <Desktop>
            <Button
              muted
              icon={<OrderIcon />}
              // @ts-ignore
              style={{
                width: isMobile ? '100%' : 'auto',
                marginLeft: '0',
              }}
              onClick={onReorderPagesIntention}
            >
              <FormattedMessage
                id="Pages.Document.Review.ReorderPages"
                defaultMessage="Seiten anordnen"
              />
            </Button>
            <HorizontalSpacer space={theme.sizes.small} />
          </Desktop>
        </ActionButtonsHolder>
      )}
      <Mobile>
        <VerticalSpacer space={theme.sizes.extraSmall} />
        <Button
          muted
          icon={<EyeIcon />}
          // @ts-ignore
          style={{
            width: '100%',
            marginLeft: '0',
          }}
          onClick={onMobileReviewPress}
        >
          <FormattedMessage
            id="Pages.Document.Review.OpenPreview"
            defaultMessage="Vorschau öffnen"
          />
        </Button>
      </Mobile>
      <Desktop>
        {(!isLocked || documentURL !== '') && (
          <VerticalSpacer space={theme.sizes.medium} />
        )}
        {documentURL !== '' ? (
          <PDFReviewHolder>
            <PDFDocumentViewer url={documentURL} />
          </PDFReviewHolder>
        ) : documentStatus === DocumentStatus.Accepted ? (
          <RemoveMarginFromParagraph>
            {/* @ts-ignore */}
            <Paragraph large style={{ padding: 0, margin: 0 }}>
              <FormattedMessage
                id="Pages.Document.Review.NoFileButDocumentAlreadyAccepted"
                defaultMessage="Dieses Dokument wurde bereits übermittelt und akzeptiert."
              />
            </Paragraph>
          </RemoveMarginFromParagraph>
        ) : documentStatus === DocumentStatus.Processing ? (
          // @ts-ignore
          <Paragraph large style={{ padding: 0, margin: 0 }}>
            <FormattedMessage
              id="Pages.Document.Review.NoFileButDocumentProcessing"
              defaultMessage="Dieses Dokument wurde bereits übermittelt und wird bearbeitet."
            />
          </Paragraph>
        ) : (
          <Paragraph></Paragraph>
        )}
      </Desktop>
      <Heading size={10} color={theme.colors.bambusBlue100} weight={700}>
        <VerticalSpacer space={theme.sizes.medium} />
        <FormattedMessage
          id="Pages.Document.Review.NoteToFile"
          defaultMessage="Notiz zur Datei"
        />
      </Heading>
      <VerticalSpacer space={theme.sizes.extraSmall} />
      <TextArea
        placeholder={intl.formatMessage({
          id: 'Pages.Document.Review.YourMessageHere',
          defaultMessage: 'Ihre Nachricht hier...',
        })}
        rows={10}
        style={{
          width: '100%',
        }}
        disabled={isLocked}
        value={note}
        onChange={onNoteChange}
      />
      <VerticalSpacer space={theme.sizes.large} />
      <ButtonsHolder isMobile={isMobile}>
        <Button
          main
          // @ts-ignore
          style={{
            width: isMobile ? '100%' : 'auto',
            gridArea: 'upload-submit-button',
          }}
          disabled={isConfirmingInProgress}
          onClick={onSubmitOrGoToOverviewButtonPress}
        >
          {isLocked ? (
            <FormattedMessage id="ToOverview" defaultMessage="zur Übersicht" />
          ) : (
            <FormattedMessage
              id="UploadSaveChanges"
              defaultMessage="Änderungen speichern"
            />
          )}
        </Button>
      </ButtonsHolder>
      <Mobile>
        {isMobileReviewShown && (
          <MobileReviewWrapper>
            <PDFDocumentViewer
              url={documentURL}
              onMobileReviewClose={onMobileReviewClose}
              onMobileReorderPagesIntention={onReorderPagesIntention}
            />
          </MobileReviewWrapper>
        )}
      </Mobile>
    </div>
  );
};

export default withRouter(Review);
