import styled from 'styled-components';

type HorizontalSeparatorProps = {
  marginTop?: string;
  marginBottom?: string;
  width?: string;
};

const HorizontalSeparator = styled.div<HorizontalSeparatorProps>`
  height: 1px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ width }) => width && `width: ${width};`}
  
  background-color: ${({ theme }) => theme.colors.bambusBlue10};
`;

export default HorizontalSeparator;
