import { useEffect } from 'react';
import { trackEvent } from 'analytics/Matomo';
import { trackEventGTM } from 'analytics/GTM';

// See the README for general analytics information

const Analytics = () => {
  useEffect(() => {
    const errorHandler = ({ message }: ErrorEvent) => {
      const eventDetails = `Message: "${message}" - Location: ${window.location.pathname}${window.location.search}`;
      trackEvent({
        eventCategory: 'Error',
        action: 'JavaScriptError',
        name: eventDetails,
      });
      trackEventGTM({
        event: 'javascript_error',
        name: eventDetails,
      });
    };
    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);
  return null;
};

export default Analytics;
