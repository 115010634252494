import React from 'react';
import { Notification, ClusteredNotification } from 'models/Notifications';

export interface NotificationsContextContent {
  allNotifications: (Notification | ClusteredNotification)[];
  activeNotifications: (Notification | ClusteredNotification)[];
  newNotifications: (Notification | ClusteredNotification)[];
  numberOfNewNotifications: number;
  onNotificationClick: (
    documentIds: (string | number)[],
    notificationIds: string[]
  ) => any;
  onNotificationDismiss: (notificationIds: string[]) => any;
  markAllNotificationsSeen: () => any;
  clearAllNewNotifications: () => any;
}

const NotificationsContext = React.createContext<NotificationsContextContent>({
  allNotifications: [],
  activeNotifications: [],
  newNotifications: [],
  numberOfNewNotifications: 0,
  onNotificationClick: () => {},
  markAllNotificationsSeen: () => {},
  onNotificationDismiss: (notificationIds) => {},
  clearAllNewNotifications: () => {},
});

export default NotificationsContext;
