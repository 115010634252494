import React from 'react';
import styled from 'styled-components';
import { Bell } from 'react-feather';

import { ReactComponent as BambusLogo } from 'illustrations/BambusWhiteLogoWithName.svg';

import theme from 'styles/theme';

import VerticalSpacer from 'atoms/VerticalSpacer';
import SVGIconHolder from 'atoms/SVGIconHolder';
import NotificationsButtonWithTooltip from 'atoms/NotificationsButtonWithTooltip';

import NavigationMenuItems from 'molecules/NavigationMenuItems';

const Menu = styled.div<any>`
  grid-area: ${({ gridAreaName }) => gridAreaName};

  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.sizes.small};

  background-color: ${(p) => p.theme.colors.bambusBlue100};

  /* This is needed so the box shadow is visible */
  z-index: 2;

  /* Box shadow copied from Figma */
  box-shadow: 40px 0px 60px rgba(0, 0, 0, 0.05),
    16.7px 0px 25.1px rgba(0, 0, 0, 0.036), 8.9px 0px 13.4px rgba(0, 0, 0, 0.03),
    5px 0px 7.5px rgba(0, 0, 0, 0.025), 2.7px 0px 4px rgba(0, 0, 0, 0.02),
    1.1px 0px 1.7px rgba(0, 0, 0, 0.014);

  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type DesktopMenuProps = {
  gridAreaName?: string;
};

/**
 * Renders the Desktop Menu.
 */
const DesktopMenu = ({ gridAreaName = '' }: DesktopMenuProps) => {
  return (
    <Menu gridAreaName={gridAreaName}>
      <Header>
        <SVGIconHolder iconWidth="100px">
          <BambusLogo />
        </SVGIconHolder>
        <NotificationsButtonWithTooltip
          icon={<Bell color={theme.colors.bambusBlue50} size={22} />}
        />
      </Header>
      <VerticalSpacer space={theme.sizes.large} />
      <NavigationMenuItems
        navigationWrapperProps={{
          style: {
            flexGrow: 1,
          },
        }}
        secondaryNavigationWrapperProps={{
          style: {
            padding: theme.sizes.extraSmall,
          },
        }}
      />
    </Menu>
  );
};

export default DesktopMenu;
