import React, { PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';

import theme from 'styles/theme';

export type MobileProps = {};

/**
 * React Wrapper that renders the children only on mobile.
 */
const Mobile = ({ children }: PropsWithChildren<MobileProps>) => {
  const isMobile = useMediaQuery({
    maxWidth: theme.breakpoints.mobileMaxWidthPx,
  });
  return isMobile ? <>{children}</> : null;
};

export default Mobile;
