import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import {
  Upload as UploadIcon,
  File as FileIcon,
  CheckCircle as CheckCircleIcon,
  XCircle as XCircleIcon,
  X as XIcon,
} from 'react-feather';

import VerticalSeparator from 'atoms/VerticalSeparator';

import theme from 'styles/theme';
import { FadeAndSlideIn } from 'styles/StyledAnimations';

import { NotificationType } from 'models/Notifications';

const Notification = styled.article`
  display: grid;
  position: relative;
  align-items: center;
  grid-template-columns: auto auto 1fr;
  grid-template-areas: 'icon separator text';
  gap: 15px;
  padding: 20px;

  background-color: ${({ theme }) => theme.colors.bambusBlue2dot5};
  border-radius: ${({ theme }) => theme.borderRadii.small};

  cursor: pointer;

  ${FadeAndSlideIn};
`;

const TextHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 5px auto;
  grid-template-areas:
    'title dismiss-button'
    '. .'
    'description description';
`;

const NotificationTitle = styled.h1`
  font-weight: 700;
  font-size: 14px;
  line-height: 17.57px;
  color: ${({ theme }) => theme.colors.bambusBlue100};
`;

const NotificationDescription = styled.p`
  max-width: 300px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.57px;
  color: ${({ theme }) => theme.colors.bambusBlue100};
`;

const DismissButton = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
  padding-right: 0;
`;

export type NotificationProps = {
  notificationIds: string[];
  title: string;
  description: string;
  documentIds: (string | number)[];
  notificationType: NotificationType;
  onNotificationClick: (
    documentIds: (string | number)[],
    notificationIds: string[]
  ) => any;
  onDismiss: (notificationIds: string[]) => any;
};

const getIconBasedOnNotificationType = (
  notificationType: NotificationType
): React.ReactNode => {
  const ICON_SIZE = 18;
  switch (notificationType) {
    case 'added':
      return <UploadIcon color={theme.colors.bambusBlue100} size={ICON_SIZE} />;
    case 'available_download':
      return <FileIcon color={theme.colors.purple} size={ICON_SIZE} />;
    case 'available_download_and_sign':
      return <FileIcon color={theme.colors.purple} size={ICON_SIZE} />;
    case 'accepted':
      return <CheckCircleIcon color={theme.colors.green} size={ICON_SIZE} />;
    case 'declined':
      return <XCircleIcon color={theme.colors.red} size={ICON_SIZE} />;
  }
};

const DesktopRealtimeNotification = ({
  notificationIds,
  title,
  description,
  documentIds,
  notificationType: NotificationType,
  onDismiss,
  onNotificationClick,
}: NotificationProps) => {
  const onDismissButtonClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onDismiss(notificationIds);
  };
  return (
    <Notification
      onClick={() => {
        onNotificationClick(documentIds, notificationIds);
      }}
    >
      <div
        style={{
          gridArea: 'icon',
        }}
      >
        {getIconBasedOnNotificationType(NotificationType)}
      </div>
      <VerticalSeparator
        style={{
          gridArea: 'separator',
          height: '100%',
        }}
      />
      <TextHolder
        style={{
          gridArea: 'text',
        }}
      >
        <NotificationTitle
          style={{
            gridArea: 'title',
            alignSelf: 'center',
          }}
        >
          {title}
        </NotificationTitle>
        <DismissButton onClick={onDismissButtonClick}>
          <XIcon
            color={theme.colors.bambusBlue50}
            size={12}
            style={{
              gridArea: 'dismiss-button',
              alignSelf: 'start',
            }}
          />
        </DismissButton>
        <NotificationDescription
          style={{
            gridArea: 'description',
            alignSelf: 'center',
          }}
        >
          {description}
        </NotificationDescription>
      </TextHolder>
    </Notification>
  );
};

export default DesktopRealtimeNotification;
