import React from 'react';

import theme from 'styles/theme';
import VerticalSpacer from 'atoms/VerticalSpacer';

import ReactPlaceholder from 'react-placeholder';
import { TextRow } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css'; //for animation

const documentPlaceholder = (
  <div>
    <TextRow
      color={theme.colors.bambusBlue10}
      style={{ width: 100, borderRadius: theme.borderRadii.small }}
    />
    <VerticalSpacer space={theme.sizes.medium} />

    <TextRow
      color={theme.colors.bambusBlue10}
      style={{
        width: 150,
        borderRadius: theme.borderRadii.small,
      }}
    />
    <VerticalSpacer space={theme.sizes.small} />
    <TextRow
      color={theme.colors.bambusBlue10}
      style={{
        borderRadius: theme.borderRadii.small,
      }}
    />
    <VerticalSpacer space={theme.sizes.small} />
    <TextRow
      color={theme.colors.bambusBlue10}
      style={{
        borderRadius: theme.borderRadii.small,
      }}
    />
  </div>
);

const UploadScreenPlaceholder = () => {
  return (
    <ReactPlaceholder
      showLoadingAnimation
      customPlaceholder={documentPlaceholder}
      ready={false}
      children={null}
    />
  );
};

export default UploadScreenPlaceholder;
