import React, { useContext, useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Activity as ActivityIcon, Repeat as OrderIcon } from 'react-feather';
import styled, { css } from 'styled-components';
import { arrayMoveImmutable } from 'array-move';
import { Button } from 'bambus-ui-components';
import IsMobileContext from 'contexts/IsMobileContext';

import theme from 'styles/theme';

import Desktop from 'atoms/Desktop';
import Mobile from 'atoms/Mobile';
import VerticalSpacer from 'atoms/VerticalSpacer';

import DocumentReordererWidget, {
  OnIndexChangeCallback,
} from 'organisms/DocumentReorderer';

import { DocumentPageType } from 'pages/Document';

import MobileReorderWrapper from './MobileReorderWrapper';

import {
  sendLandedOnReorderStepAnalytics,
  sendRestartDocumentUploadProcessAnalytics,
  sendReorderingStartedAnalytics,
  sendWaitingAfterReorderingReadyAnalytics,
  sendReorderingReadyAnalytics,
} from 'analytics/DocumentAnalytics';

import { requestReordering, getReorderingStatus } from 'api/requests';

import wait from 'utils/WaitAsPromised';

const ButtonsHolderMobileStyles = css`
  ${({ theme }) => `
    grid-template-columns: 1fr;
    grid-template-rows: auto ${theme.sizes.mini} auto;
    grid-template-areas: 
      'upload-check-button upload-check-button upload-check-button'
      '. . .'
      'upload-cancel-button upload-cancel-button upload-cancel-button';
`}
`;

const ButtonsHolderDesktopStyles = css`
  ${({ theme }) => `
    grid-template-columns: auto ${theme.sizes.small} auto 1fr;
    grid-template-areas: 
      'upload-check-button . upload-cancel-button .';
`}
`;

const ButtonsHolder = styled.div<any>`
  display: grid;

  ${({ isMobile }) =>
    isMobile ? ButtonsHolderMobileStyles : ButtonsHolderDesktopStyles};
`;

const REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS = 150;

export type ReorderScreenProps = {
  documentId: string;
  initialDocumentPages: DocumentPageType[];
  onFinishReorder: () => void;
  onCancelPress: () => void;
  onRestartDocumentUploadIntention: () => void;
};

const Reorder = ({
  documentId,
  initialDocumentPages = [],
  onFinishReorder,
  onCancelPress,
  onRestartDocumentUploadIntention,
}: ReorderScreenProps) => {
  const isMobile = useContext(IsMobileContext);
  const [isMobileReordererShown, setIsMobileReordererShown] =
    useState<boolean>(true);
  const [documentPages, setDocumentPages] = useState<DocumentPageType[]>([]);
  const [didReorderHappen, setDidReorderHappen] = useState<boolean>(false);

  const [isServerReorderingInProgress, setIsServerReorderingInProgress] =
    useState(false);

  useEffect(() => {
    sendLandedOnReorderStepAnalytics({ documentId });
  }, [documentId]);

  useEffect(() => {
    setDocumentPages(initialDocumentPages);
  }, [initialDocumentPages]);

  const onMobileReorderPress = useCallback(() => {
    setIsMobileReordererShown(true);
  }, []);

  const onRestartDocumentUpload = useCallback(() => {
    sendRestartDocumentUploadProcessAnalytics({ documentId, step: 'reorder' });
    onRestartDocumentUploadIntention();
  }, [documentId, onRestartDocumentUploadIntention]);

  const onDocumentReorderIndexChange: OnIndexChangeCallback = useCallback(
    ({ oldIndex, newIndex }) => {
      setDocumentPages((documentPages) =>
        arrayMoveImmutable(documentPages, oldIndex, newIndex)
      );
      setDidReorderHappen(true);
    },
    []
  );

  const onReorderPress = useCallback(async () => {
    if (didReorderHappen) {
      setIsServerReorderingInProgress(true);
      /**
       * Requesting reordering and waiting for it to be ready (polling)
       */
      sendReorderingStartedAnalytics({ documentId });
      await requestReordering({
        documentId: documentId || '',
        newOrder: documentPages.map((docPage) => docPage.index),
      });
      let numberOfWaitCycles: number = 0;
      let inbetweenAnalytics: any = {};
      do {
        //Waiting before checking for reordering status
        await wait(REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS);
        numberOfWaitCycles++;
        const { status } = await getReorderingStatus({
          documentId,
        });
        if (status === 'done') {
          sendReorderingReadyAnalytics({
            documentId,
            cyclesWaited: numberOfWaitCycles,
            durationOfWaitingCycleMs: REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS,
          });
          break;
        }
        /**
         * Sending wait analytics at: 30s, 60s, 2mins, 5mins, 10mins of wait time
         */
        if (
          !inbetweenAnalytics['30000'] &&
          numberOfWaitCycles * REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS >= 30000
        ) {
          //waited more than 30s
          sendWaitingAfterReorderingReadyAnalytics({
            documentId,
            cyclesWaited: numberOfWaitCycles,
            durationOfWaitingCycleMs: REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS,
          });
          inbetweenAnalytics['30000'] = true;
        }
        if (
          !inbetweenAnalytics['60000'] &&
          numberOfWaitCycles * REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS >= 60000
        ) {
          //waited more than 60s
          sendWaitingAfterReorderingReadyAnalytics({
            documentId,
            cyclesWaited: numberOfWaitCycles,
            durationOfWaitingCycleMs: REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS,
          });
          inbetweenAnalytics['60000'] = true;
        }
        if (
          !inbetweenAnalytics['120000'] &&
          numberOfWaitCycles * REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS >= 120000
        ) {
          //waited more than 2 mins
          sendWaitingAfterReorderingReadyAnalytics({
            documentId,
            cyclesWaited: numberOfWaitCycles,
            durationOfWaitingCycleMs: REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS,
          });
          inbetweenAnalytics['120000'] = true;
        }
        if (
          !inbetweenAnalytics['300000'] &&
          numberOfWaitCycles * REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS >= 300000
        ) {
          //waited more than 5 mins
          sendWaitingAfterReorderingReadyAnalytics({
            documentId,
            cyclesWaited: numberOfWaitCycles,
            durationOfWaitingCycleMs: REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS,
          });
          inbetweenAnalytics['300000'] = true;
        }
        if (
          !inbetweenAnalytics['600000'] &&
          numberOfWaitCycles * REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS >= 600000
        ) {
          //waited more than 10 mins
          sendWaitingAfterReorderingReadyAnalytics({
            documentId,
            cyclesWaited: numberOfWaitCycles,
            durationOfWaitingCycleMs: REORDER_DONE_PERIODIC_CHECK_INTERVAL_MS,
          });
          inbetweenAnalytics['600000'] = true;
        }
      } while (true);
      setIsServerReorderingInProgress(false);
    }
    onFinishReorder();
  }, [onFinishReorder, documentId, documentPages, didReorderHappen]);

  const DocumentReorderer = (
    <DocumentReordererWidget
      documentPages={documentPages}
      onIndexChange={onDocumentReorderIndexChange}
      onMobileReordererConfirm={onReorderPress}
      showLoadingIndicator={isServerReorderingInProgress}
    />
  );

  return (
    <div>
      <Button
        outline
        icon={<ActivityIcon />}
        // @ts-ignore
        style={{
          width: isMobile ? '100%' : 'auto',
        }}
        onClick={onRestartDocumentUpload}
      >
        <FormattedMessage
          id="RestartUpload"
          defaultMessage="Upload neustarten"
        />
      </Button>
      <Mobile>
        <VerticalSpacer space={theme.sizes.extraSmall} />
        <Button
          muted
          icon={<OrderIcon />}
          // @ts-ignore
          style={{
            width: '100%',
            marginLeft: '0',
          }}
          onClick={onMobileReorderPress}
        >
          <FormattedMessage
            id="Pages.Document.Reorder.ArrangePages"
            defaultMessage="Seiten anordnen"
          />
        </Button>
      </Mobile>
      <VerticalSpacer space={theme.sizes.small} />
      <Desktop>{DocumentReorderer}</Desktop>
      <VerticalSpacer space={theme.sizes.large} />
      <ButtonsHolder isMobile={isMobile}>
        <Button
          main
          // @ts-ignore
          style={{
            width: isMobile ? '100%' : 'auto',
            gridArea: 'upload-check-button',
          }}
          disabled={isServerReorderingInProgress}
          onClick={onReorderPress}
        >
          <FormattedMessage
            id="OrderConfirm"
            defaultMessage="Anordnung bestätigen"
          />
        </Button>
        <Button
          basic
          // @ts-ignore
          style={{
            color: theme.colors.bambusBlue50,
            gridArea: 'upload-cancel-button',
          }}
          onClick={onCancelPress}
        >
          <FormattedMessage id="Cancel" defaultMessage="Abbrechen" />
        </Button>
      </ButtonsHolder>
      <Mobile>
        {isMobileReordererShown && (
          <MobileReorderWrapper>{DocumentReorderer}</MobileReorderWrapper>
        )}
      </Mobile>
    </div>
  );
};

export default Reorder;
