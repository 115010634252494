export function transformKeys(
  obj: any,
  keyTransformerFunc: (inputString: string) => string
): any {
  const newObject: any = {};
  Object.keys(obj).forEach((key, index, array, value = obj[key]) => {
    newObject[keyTransformerFunc(key)] = value;
  });
  return newObject;
}

export function deepTransformKeys(
  obj: any,
  keyTransformerFunc: (inputString: string) => string
): any {
  if (!obj) return obj;
  if (typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    return obj.map((element) => deepTransformKeys(element, keyTransformerFunc));
  }
  const newObject: any = {};
  Object.keys(obj).forEach((key, index, array, value = obj[key]) => {
    newObject[keyTransformerFunc(key)] = deepTransformKeys(
      value,
      keyTransformerFunc
    );
  });
  return newObject;
}
