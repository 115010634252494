import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Eye, Filter } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { ValidationMessage } from 'bambus-ui-components';
import { getDocuments, GetDocumentsAPIResponseDocument } from 'api/requests';

import theme from 'styles/theme';

import MainContentWrapper from 'atoms/MainContentWrapper';
import Heading from 'atoms/Heading';
import PageHeading from 'atoms/PageHeading';
import Paragraph from 'atoms/Paragraph';
import VerticalSpacer from 'atoms/VerticalSpacer';
import Mobile from 'atoms/Mobile';
import ToggleButton from 'atoms/ToggleButton';
import ContactSupportButton from 'atoms/ContactSupportButton';

import DocumentCategoryPlaceholder from 'molecules/DocumentCategoryPlaceholder';
import { DocumentStatus, DIM_ANIMATION_SECONDS } from 'molecules/Document';

import StatusBar from 'organisms/StatusBar';
import DocumentStatusReference from 'organisms/DocumentStatusReference';
import DocumentsGroup from 'organisms/DocumentsGroup';

import IsMobileContext from 'contexts/IsMobileContext';

import {
  sendOnChangeDocumentsOutstandingToggleAnalytics,
  sendAvailableDocumentsToSubmitAnalytics,
} from 'analytics/DocumentsListAnalytics';

const DocumentsWrapper = styled.div``;

const ToggleButtonHolder = styled.div`
  display: flex;
`;

const SEE_ONLY_OUTSTANDING_DOCS_PREFERENCE_LS_KEY =
  'seeOnlyOutstandingDocsPreference';

const Documents = ({
  showStatusBar = true,
  history,
  location,
}: { showStatusBar?: boolean } & RouteComponentProps<
  any,
  any,
  {
    documentIdsToHiglight?: (string | number)[];
  }
>) => {
  const intl = useIntl();

  const isMobile = useContext(IsMobileContext);

  const {
    data: documentCategories,
    isLoading: areDocumentsLoading,
    error: documentsFetchError,
    isFetched: areDocumentsFetched,
  } = useQuery('documents', getDocuments);

  // An outstanding document is either in the Open or Declined status
  // As the preference is also saved in localStorage, try and see whether
  // we already have a preference stored there.
  const [seeOnlyOutstandingDocs, setSeeOnlyOutstandingDocs] = useState<boolean>(
    () => {
      const localStoragePreference = localStorage.getItem(
        SEE_ONLY_OUTSTANDING_DOCS_PREFERENCE_LS_KEY
      );
      if (localStoragePreference != null) {
        return localStoragePreference === 'true';
      } else {
        // Defaulting to true: only showing outstanding documents
        return true;
      }
    }
  );

  const [documentIdsToHighlight, setDocumentIdsToHighlight] = useState<
    (string | number)[]
  >([]);

  const [nrOfDocumentsByViewType, setNrOfDocumentsByViewType] = useState<{
    nrOfDocsAllDocsView: number;
    nrOfDocsFilteredView: number;
  }>({
    nrOfDocsAllDocsView: 0,
    nrOfDocsFilteredView: 0,
  });

  useEffect(() => {
    let nrOfDocsAllDocsView = 0;
    let nrOfDocsFilteredView = 0;
    documentCategories?.forEach((documentCategory) => {
      documentCategory.documents.forEach((document) => {
        if (Array.isArray(document.documents)) {
          document.documents.forEach((innerDocument) => {
            nrOfDocsAllDocsView++;
            if (
              innerDocument.status === DocumentStatus.Declined ||
              innerDocument.status === DocumentStatus.Open
            ) {
              nrOfDocsFilteredView++;
            }
          });
        } else {
          nrOfDocsAllDocsView++;
          if (
            document.status === DocumentStatus.Declined ||
            document.status === DocumentStatus.Open
          ) {
            nrOfDocsFilteredView++;
          }
        }
      });
    });
    setNrOfDocumentsByViewType({
      nrOfDocsAllDocsView,
      nrOfDocsFilteredView,
    });
  }, [documentCategories]);

  // Also saving the preference in localStorage
  const onSeeOnlyOutstandingDocsPreferenceChange = useCallback(
    (seeOnlyOutstandingDocs, sendAnalytics = true) => {
      setSeeOnlyOutstandingDocs(seeOnlyOutstandingDocs);
      localStorage.setItem(
        SEE_ONLY_OUTSTANDING_DOCS_PREFERENCE_LS_KEY,
        seeOnlyOutstandingDocs
      );
      if (sendAnalytics) {
        sendOnChangeDocumentsOutstandingToggleAnalytics({
          showOnlyOutstanding: seeOnlyOutstandingDocs,
        });
      }
    },
    []
  );

  const MemoToggleButton = useMemo(() => {
    return (
      <ToggleButtonHolder>
        <ToggleButton
          textUntoggled={intl.formatMessage({
            id: 'Pages.DocumentsOverview.SeeOnlyOutstandingDocuments',
            defaultMessage: 'Nur ausstehende anzeigen',
          })}
          textToggled={intl.formatMessage({
            id: 'Pages.DocumentsOverview.SeeAllDocuments',
            defaultMessage: 'Alle anzeigen',
          })}
          featherIconToggled={<Eye />}
          featherIconUntoggled={<Filter />}
          toggledByDefault={seeOnlyOutstandingDocs}
          onChange={onSeeOnlyOutstandingDocsPreferenceChange}
        />
      </ToggleButtonHolder>
    );
  }, [intl, onSeeOnlyOutstandingDocsPreferenceChange, seeOnlyOutstandingDocs]);

  // Sends the number of documents to submit to our Analytics services.
  useEffect(() => {
    if (!areDocumentsLoading && documentCategories) {
      // Get all documents that are submittable (all that are Open or Declined).
      // We ignore the grace period documents as they have already been submitted.
      let documents: GetDocumentsAPIResponseDocument[] = [];
      documentCategories.forEach((documentCategory) => {
        documents = [...documents, ...documentCategory.documents];
      });
      documents = documents.filter(
        (document) =>
          document.status === DocumentStatus.Open ||
          document.status === DocumentStatus.Declined
      );
      sendAvailableDocumentsToSubmitAnalytics({
        numberOfAvailableDocumentsToSubmit: documents.length,
      });
    }
  }, [documentCategories, areDocumentsLoading]);

  useEffect(() => {
    if (location.state?.documentIdsToHiglight) {
      // switching to unfiltered document view to show all documents
      onSeeOnlyOutstandingDocsPreferenceChange(false, false);

      setDocumentIdsToHighlight(location.state.documentIdsToHiglight);
      if (location.state) {
        // clearing the state
        let state = { ...history.location.state };
        delete state.documentIdsToHiglight;
        history.replace({ ...history.location, state });
      }
    }
  }, [history, location, onSeeOnlyOutstandingDocsPreferenceChange]);

  /**
   * When documentIdsToHighlight is populated, we (after DIM_ANIMATION_SECONDS seconds) set the value to []
   * so no other documents are highlighted when rerendering this component.
   *
   * DIM_ANIMATION_SECONDS seconds comes from the CSS animation defined in molecules/Document.tsx
   *
   * We also trigger a scroll to the first document that is highlighted
   */
  useEffect(() => {
    let timer: number | null = null;
    if (documentIdsToHighlight.length > 0 && areDocumentsFetched) {
      const firstHighlightedDocumentToScrollTo = document.querySelector(
        '[data-dimmed="false"]'
      );
      if (firstHighlightedDocumentToScrollTo) {
        document.dispatchEvent(
          new CustomEvent('bmbCockpit:ScrollToElement', {
            detail: {
              elementToScrollTo: firstHighlightedDocumentToScrollTo,
              topOffset: -20,
            },
          })
        );
      }

      timer = window.setTimeout(() => {
        setDocumentIdsToHighlight([]);
      }, DIM_ANIMATION_SECONDS * 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [documentIdsToHighlight, areDocumentsFetched]);

  return (
    <MainContentWrapper>
      {showStatusBar && (
        <StatusBar
          text={intl.formatMessage({
            id: 'Pages.DocumentsOverview.SubmitDocuments',
            defaultMessage: 'Dokumente einreichen',
          })}
        />
      )}
      <VerticalSpacer space={theme.sizes.large} />
      <PageHeading>
        <FormattedMessage
          id="Pages.DocumentsOverview.Title"
          defaultMessage="Ihre Antragsunterlagen"
        />
      </PageHeading>
      <VerticalSpacer space={theme.sizes.small} />
      <Paragraph size={isMobile ? 2 : 0}>
        <FormattedMessage
          id="Pages.DocumentsOverview.Description"
          defaultMessage="Laden Sie hier bequem Ihre Unterlagen hoch. Hier haben Sie auch jederzeit Einsicht in den Status Ihrer Unterlagenprüfung durch unsere Experten."
        />
      </Paragraph>
      <Mobile>
        <VerticalSpacer space={theme.sizes.small} />
        <DocumentStatusReference />
      </Mobile>
      <VerticalSpacer space={theme.sizes.large} />
      {MemoToggleButton}
      <VerticalSpacer space={theme.sizes.small} />
      {documentsFetchError && (
        <ValidationMessage hasError>
          <FormattedMessage
            id="GeneralFailurePleaseContactSupport"
            defaultMessage="Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support."
          />
        </ValidationMessage>
      )}
      <DocumentsWrapper>
        {!areDocumentsLoading &&
          documentCategories?.map((documentCategory) => (
            <React.Fragment
              key={`${documentCategory.groupTitle}-${Math.random()}`}
            >
              <DocumentsGroup
                groupName={documentCategory.groupTitle}
                documents={
                  documentIdsToHighlight.length > 0
                    ? documentCategory.documents.map((document) => ({
                        ...document,
                        dim: !documentIdsToHighlight.some(
                          (docIdToHighlight) => docIdToHighlight === document.id
                        ),
                      }))
                    : documentCategory.documents
                }
                showOnlyOutstanding={seeOnlyOutstandingDocs}
                insertVerticalSpacerBelow
              />
            </React.Fragment>
          ))}
        {areDocumentsLoading && <DocumentCategoryPlaceholder />}
      </DocumentsWrapper>
      {!areDocumentsLoading &&
        nrOfDocumentsByViewType.nrOfDocsAllDocsView === 0 && (
          <Paragraph size={isMobile ? 2 : 0}>
            <FormattedMessage
              id="Pages.DocumentsOverview.NoDocumentsYetAvailable"
              defaultMessage="Unser Finanzierungsteam hat noch keine Unterlagen für Sie freigeschaltet."
            />
          </Paragraph>
        )}
      {!areDocumentsLoading &&
        nrOfDocumentsByViewType.nrOfDocsAllDocsView > 0 &&
        nrOfDocumentsByViewType.nrOfDocsFilteredView === 0 &&
        seeOnlyOutstandingDocs && (
          <Paragraph size={isMobile ? 2 : 0}>
            <FormattedMessage
              id="Pages.DocumentsOverview.NoDocumentsAvailableInFilteredView"
              defaultMessage="Es gibt derzeit keine ausstehende Unterlagen."
            />
          </Paragraph>
        )}
      <VerticalSpacer space={theme.sizes.large} />
      <Heading size={isMobile ? 6 : 4}>
        <FormattedMessage
          id="Pages.DocumentsOverview.DoesUserHaveProblemTitle"
          defaultMessage="Fragen zu den Unterlagen?"
        />
      </Heading>
      <VerticalSpacer space={theme.sizes.small} />
      <Paragraph size={isMobile ? 2 : 0}>
        <FormattedMessage
          id="Pages.DocumentsOverview.DoesUserHaveProblemText"
          defaultMessage="Bei Fragen können Sie sich wie gewohnt jederzeit an Ihren Finanzierungsberater oder unser Support Team wenden."
        />
      </Paragraph>
      <VerticalSpacer space={theme.sizes.small} />
      <ContactSupportButton />
    </MainContentWrapper>
  );
};

export default withRouter(Documents);
