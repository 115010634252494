import React, { PropsWithChildren, useContext } from 'react';

import Heading from 'atoms/Heading';

import IsMobileContext from 'contexts/IsMobileContext';

const PageHeading = ({ children }: PropsWithChildren<any>) => {
  const isMobile = useContext(IsMobileContext);
  return <Heading size={isMobile ? 6 : 4}>{children}</Heading>;
};

export default PageHeading;
