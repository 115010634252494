import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import IsMobileContext from 'contexts/IsMobileContext';

import theme from 'styles/theme';

import Heading from 'atoms/Heading';
import Paragraph from 'atoms/Paragraph';
import MainContentWrapper from 'atoms/MainContentWrapper';
import PageHeading from 'atoms/PageHeading';
import VerticalSpacer from 'atoms/VerticalSpacer';
import HorizontalSpacer from 'atoms/HorizontalSpacer';
import HorizontalSeparator from 'atoms/HorizontalSeparator';
import VerticalSeparator from 'atoms/VerticalSeparator';
import Tile from 'atoms/Tile';
import ContactSupportButton from 'atoms/ContactSupportButton';

import {
  getUserDetails,
  FamilyStatus,
  EmploymentType,
  Salutation,
} from 'api/requests';

import ProfilePlaceholder from './ProfilePlaceholder';

const Text = styled.span<any>`
  flex: 1 1 ${({ flexBasis }) => flexBasis || 'auto'};

  color: ${({ color, theme }) => color || theme.colors.bambusBlue100};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${({ fontSize, theme }) => fontSize || theme.fontSizes.text};
  line-height: ${({ theme }) => theme.lineHeights.heading};
`;

export type ProfileInfoProps = {
  fontSize?: string;
  color?: string;
  fontWeight?: number;
  lineHeight?: number;
  flexBasis?: string;
  [x: string]: any;
};

const mapSalutationToLocalizedString = (salutation: Salutation) => {
  switch (salutation) {
    case Salutation.Mr:
      return <FormattedMessage id="Salutation.Mr" defaultMessage="Herr" />;
    case Salutation.Ms:
      return <FormattedMessage id="Salutation.Ms" defaultMessage="Frau" />;
  }
};

const mapFamilyStatusToLocalizedString = (familyStatus: FamilyStatus) => {
  switch (familyStatus) {
    case FamilyStatus.Single:
      return (
        <FormattedMessage id="FamilyStatus.Single" defaultMessage="Ledig" />
      );
    case FamilyStatus.Married:
      return (
        <FormattedMessage
          id="FamilyStatus.Married"
          defaultMessage="Verheiratet"
        />
      );
    case FamilyStatus.Widowed:
      return (
        <FormattedMessage
          id="FamilyStatus.Widowed"
          defaultMessage="Verwitwet"
        />
      );
    case FamilyStatus.Divorced:
      return (
        <FormattedMessage
          id="FamilyStatus.Divorced"
          defaultMessage="Geschieden"
        />
      );
    case FamilyStatus.Separated:
      return (
        <FormattedMessage
          id="FamilyStatus.Separated"
          defaultMessage="Getrennt"
        />
      );
    case FamilyStatus.RegisteredCivilPartnership:
      return (
        <FormattedMessage
          id="FamilyStatus.RegisteredCivilPartnership"
          defaultMessage="Eingetragene Lebenspartnerschaft"
        />
      );
  }
};

const mapEmploymentTypeToLocalizedString = (employmentType: EmploymentType) => {
  switch (employmentType) {
    case EmploymentType.Employed:
      return (
        <FormattedMessage
          id="EmploymentType.Employed"
          defaultMessage="Angestellt"
        />
      );
    case EmploymentType.Worker:
      return (
        <FormattedMessage
          id="EmploymentType.Worker"
          defaultMessage="Arbeiter"
        />
      );
    case EmploymentType.Unemployed:
      return (
        <FormattedMessage
          id="EmploymentType.Unemployed"
          defaultMessage="Arbeitslos"
        />
      );
    case EmploymentType.Official:
      return (
        <FormattedMessage
          id="EmploymentType.Official"
          defaultMessage="Beamte"
        />
      );
    case EmploymentType.ParentalLeave:
      return (
        <FormattedMessage
          id="EmploymentType.ParentalLeave"
          defaultMessage="Elternzeit"
        />
      );
    case EmploymentType.Freelancer:
      return (
        <FormattedMessage
          id="EmploymentType.Freelancer"
          defaultMessage="Freiberufler"
        />
      );
    case EmploymentType.MarginallyEmployed:
      return (
        <FormattedMessage
          id="EmploymentType.MarginallyEmployed"
          defaultMessage="Geringfügig Beschäftigter"
        />
      );
    case EmploymentType.ManagingPartner:
      return (
        <FormattedMessage
          id="EmploymentType.ManagingPartner"
          defaultMessage="Geschäftsführender Gesellschafter"
        />
      );
    case EmploymentType.HouseHusbandOrWife:
      return (
        <FormattedMessage
          id="EmploymentType.HouseHusbandOrWife"
          defaultMessage="Hausmann, -frau"
        />
      );
    case EmploymentType.PersonOfIndependentMeans:
      return (
        <FormattedMessage
          id="EmploymentType.PersonOfIndependentMeans"
          defaultMessage="Privatier"
        />
      );
    case EmploymentType.Pensioner:
      return (
        <FormattedMessage
          id="EmploymentType.Pensioner"
          defaultMessage="Rentner"
        />
      );
    case EmploymentType.SelfEmployedOnTheBalanceSheet:
      return (
        <FormattedMessage
          id="EmploymentType.SelfEmployedOnTheBalanceSheet"
          defaultMessage="Selbstständig (bilanzierend)"
        />
      );
    case EmploymentType.SelfEmployedNotOnTheBalanceSheet:
      return (
        <FormattedMessage
          id="EmploymentType.SelfEmployedNotOnTheBalanceSheet"
          defaultMessage="Selbstständig (nicht bilanzierend)"
        />
      );
    case EmploymentType.Soldier:
      return (
        <FormattedMessage id="EmploymentType.Soldier" defaultMessage="Soldat" />
      );
    case EmploymentType.Student:
      return (
        <FormattedMessage
          id="EmploymentType.Student"
          defaultMessage="Student"
        />
      );
    case EmploymentType.Executive:
      return (
        <FormattedMessage
          id="EmploymentType.Executive"
          defaultMessage="Vorstand"
        />
      );
  }
};

const ProfileInfo = ({
  children,
  color,
  fontWeight,
  fontSize,
  lineHeight,
  padding,
  flexBasis,
  ...rest
}: React.PropsWithChildren<ProfileInfoProps>) => {
  return (
    <Text
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      flexBasis={flexBasis}
      {...rest}
    >
      {children}
    </Text>
  );
};

const ProfileInfoField = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const ProfileInfoFieldHolder = styled.div`
  min-width: 230px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 100%;
  flex: 1;
`;

const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Profile = () => {
  const { data: userProfileData, isLoading } = useQuery(
    ['userData'],
    getUserDetails
  );

  const isMobile = useContext(IsMobileContext);

  return (
    <MainContentWrapper>
      <PageHeading>
        <FormattedMessage id="Profile" defaultMessage="Profil" />
      </PageHeading>

      <VerticalSpacer space={theme.sizes.midLarge} />

      {isLoading ? (
        <ProfilePlaceholder />
      ) : (
        <Tile smallHorizontalPadding={isMobile}>
          <ProfileInfoWrapper>
            <ProfileInfoFieldHolder>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '30%' : '40%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.FirstName"
                    defaultMessage="Vorname"
                  />
                </ProfileInfo>
                <ProfileInfo
                  fontSize={
                    !isMobile ? theme.fontSizes.paragraph : theme.fontSizes.text
                  }
                  fontWeight={700}
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '70%' : '60%'}
                >
                  {userProfileData?.firstName || ''}
                </ProfileInfo>
              </ProfileInfoField>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '30%' : '40%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.LastName"
                    defaultMessage="Nachname"
                  />
                </ProfileInfo>
                <ProfileInfo
                  fontSize={
                    !isMobile ? theme.fontSizes.paragraph : theme.fontSizes.text
                  }
                  fontWeight={700}
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '70%' : '60%'}
                >
                  {userProfileData?.lastName || ''}
                </ProfileInfo>
              </ProfileInfoField>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '30%' : '40%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.Salutation"
                    defaultMessage="Anrede"
                  />
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '70%' : '60%'}
                >
                  {(userProfileData?.salutation &&
                    mapSalutationToLocalizedString(
                      userProfileData.salutation as Salutation
                    )) ||
                    ''}
                </ProfileInfo>
              </ProfileInfoField>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '30%' : '40%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.Title"
                    defaultMessage="Titel"
                  />
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '70%' : '60%'}
                >
                  {userProfileData?.title || ''}
                </ProfileInfo>
              </ProfileInfoField>
            </ProfileInfoFieldHolder>

            {!isMobile && (
              <VerticalSeparator
                height="100px"
                marginLeft="35px"
                marginRight="35px"
              />
            )}

            <ProfileInfoFieldHolder>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '30%' : '40%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.Email"
                    defaultMessage="E-mail"
                  />
                </ProfileInfo>
                <ProfileInfo
                  fontSize={
                    !isMobile ? theme.fontSizes.paragraph : theme.fontSizes.text
                  }
                  fontWeight={700}
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '70%' : '60%'}
                >
                  {userProfileData?.email || ''}
                </ProfileInfo>
              </ProfileInfoField>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '30%' : '40%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.Phone"
                    defaultMessage="Telefon"
                  />
                </ProfileInfo>
                <ProfileInfo
                  fontSize={
                    !isMobile ? theme.fontSizes.paragraph : theme.fontSizes.text
                  }
                  fontWeight={700}
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '70%' : '60%'}
                >
                  {userProfileData?.phoneNumber || ''}
                </ProfileInfo>
              </ProfileInfoField>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '30%' : '40%'}
                >
                  <FormattedMessage
                    id="Pags.Profile.Address"
                    defaultMessage="Anschrift"
                  />
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '70%' : '60%'}
                >
                  {`${userProfileData?.city || ''} ${
                    userProfileData?.streetName || ''
                  } ${userProfileData?.houseNumber || ''} ${
                    userProfileData?.addressLine2 || ''
                  }`}
                </ProfileInfo>
              </ProfileInfoField>
            </ProfileInfoFieldHolder>

            <HorizontalSeparator
              width="100%"
              marginTop="20px"
              marginBottom="20px"
            />

            <ProfileInfoFieldHolder>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '50%' : '70%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.Birthday"
                    defaultMessage="Geburtsdatum"
                  />
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '50%' : '30%'}
                >
                  {userProfileData?.birthdate || ''}
                </ProfileInfo>
              </ProfileInfoField>
              {/* <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '50%' : '70%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.BirthPlace"
                    defaultMessage="Geburtsland"
                  />
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '50%' : '30%'}
                >
                  -
                </ProfileInfo>
              </ProfileInfoField> */}
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '50%' : '70%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.Nationality"
                    defaultMessage="Nationalität"
                  />
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '50%' : '30%'}
                >
                  {userProfileData?.nationality || ''}
                </ProfileInfo>
              </ProfileInfoField>
              {userProfileData?.nationality2 && (
                <ProfileInfoField>
                  <ProfileInfo
                    color={theme.colors.bambusBlue50}
                    flexBasis={!isMobile ? '50%' : '70%'}
                  >
                    <FormattedMessage
                      id="Pages.Profile.SecondNationality"
                      defaultMessage="Zweite Nationalität"
                    />
                  </ProfileInfo>
                  <ProfileInfo
                    color={theme.colors.bambusBlue100}
                    flexBasis={!isMobile ? '50%' : '30%'}
                  >
                    {userProfileData?.nationality2}
                  </ProfileInfo>
                </ProfileInfoField>
              )}
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '50%' : '70%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.MaritalStatus"
                    defaultMessage="Familienstand"
                  />
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '50%' : '30%'}
                >
                  {(userProfileData?.familyStatus &&
                    mapFamilyStatusToLocalizedString(
                      userProfileData.familyStatus
                    )) ||
                    ''}
                </ProfileInfo>
              </ProfileInfoField>
            </ProfileInfoFieldHolder>

            {!isMobile && (
              <HorizontalSpacer space="81px" /> //the same size as the VerticalSeparator used upper
            )}

            <ProfileInfoFieldHolder>
              <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '60%' : '70%'}
                >
                  <FormattedMessage
                    id="Pages.Profile.JobTitle"
                    defaultMessage="Berufsbezeichnung"
                  />
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '40%' : '30%'}
                >
                  {(userProfileData?.employmentType &&
                    mapEmploymentTypeToLocalizedString(
                      userProfileData.employmentType
                    )) ||
                    ''}
                </ProfileInfo>
              </ProfileInfoField>
              {/* <ProfileInfoField>
                <ProfileInfo
                  color={theme.colors.bambusBlue50}
                  flexBasis={!isMobile ? '60%' : '70%'}
                >
                  Arbeitgeber
                </ProfileInfo>
                <ProfileInfo
                  color={theme.colors.bambusBlue100}
                  flexBasis={!isMobile ? '40%' : '30%'}
                >
                  -
                </ProfileInfo>
              </ProfileInfoField> */}
            </ProfileInfoFieldHolder>
          </ProfileInfoWrapper>
        </Tile>
      )}
      <VerticalSpacer space={theme.sizes.extraLarge} />
      <Heading size={isMobile ? 8 : 7}>
        <FormattedMessage
          id="NeedsChangeQuestion"
          defaultMessage="Änderungswünsche?"
        />
      </Heading>
      <VerticalSpacer space={theme.sizes.small} />
      <Paragraph size={isMobile ? 2 : 0}>
        <FormattedMessage
          id="Pages.Profile.NeedsChangeInstructions"
          defaultMessage="Möchten Sie Profildaten ändern? Dann kontaktieren Sie bitte unser Support Team."
        />
      </Paragraph>
      <VerticalSpacer space={theme.sizes.small} />
      <ContactSupportButton />
    </MainContentWrapper>
  );
};

export default Profile;
