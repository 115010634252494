import React from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as BambusUIComponentsThemeProvider } from 'bambus-ui-components';

import theme from 'styles/theme';

import translations, { flattenMessages, TranslationKeys } from 'translations';

import { Page } from 'pages/PagesList';

import IsMobileContext from 'contexts/IsMobileContext';
import CurrentPageContext from 'contexts/CurrentPageContext';
import AuthContext from 'contexts/AuthContext';

import NotificationsManager from 'components/NotificationsManager';
import ScrollDocumentsContainerToTopContext from 'contexts/ScrollDocumentsContainerToTopContext';

export type AllTheProvidersProps = {
  reactQueryClient: QueryClient;
  isAuthenticated: boolean;
  setIsAuthenticated: (newValueIsAuthenticated: boolean) => void;
  isMobile: boolean;
  currentPage: Page | null;
  setCurrentPage: (newValueCurrentPage: Page | null) => void;
  scrollDocumentsContainerToTop: () => any;
  locale: keyof TranslationKeys;
};

const AllTheProvidersWrapper = ({
  reactQueryClient,
  isAuthenticated,
  setIsAuthenticated,
  isMobile,
  currentPage,
  setCurrentPage,
  locale,
  children,
  scrollDocumentsContainerToTop,
}: React.PropsWithChildren<AllTheProvidersProps>) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BambusUIComponentsThemeProvider>
          <ScrollDocumentsContainerToTopContext.Provider
            value={{ scrollDocumentsContainerToTop }}
          >
            <QueryClientProvider client={reactQueryClient}>
              <AuthContext.Provider
                value={{ isAuthenticated, setIsAuthenticated }}
              >
                <IsMobileContext.Provider value={isMobile}>
                  <CurrentPageContext.Provider
                    value={{ currentPage, setCurrentPage }}
                  >
                    <IntlProvider
                      locale={locale}
                      messages={flattenMessages(translations[locale])}
                    >
                      <NotificationsManager>{children}</NotificationsManager>
                    </IntlProvider>
                  </CurrentPageContext.Provider>
                </IsMobileContext.Provider>
              </AuthContext.Provider>
            </QueryClientProvider>
          </ScrollDocumentsContainerToTopContext.Provider>
        </BambusUIComponentsThemeProvider>
      </ThemeProvider>
    </>
  );
};

export default AllTheProvidersWrapper;
