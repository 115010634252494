import React from 'react';
import styled from 'styled-components';

import theme from 'styles/theme';

import { FormattedMessage } from 'react-intl';
import VerticalSpacer from 'atoms/VerticalSpacer';

const NoteWrapper = styled.section`
  display: grid;
  // 37px = the sum of the icon width (18px) and the space between the icon and the title of the document
  grid-template-columns: 37px auto 1fr;
  grid-template-areas: '. content .';

  padding: ${(p) => p.theme.sizes.extraSmall} ${(p) => p.theme.sizes.small};

  border-bottom-left-radius: ${(p) => p.theme.borderRadii.normal};
  border-bottom-right-radius: ${(p) => p.theme.borderRadii.normal};
  background-color: ${(p) => p.theme.colors.bambusBlue10};
`;

const Message = styled.span`
  color: ${(p) => p.theme.colors.bambusBlue100};
  font-size: 0.875em;
  line-height: 21px;
`;

const Period = styled.span`
  color: ${(p) => p.theme.colors.bambusBlue100};
  font-size: 0.875em;
  line-height: 21px;
`;

const Content = styled.div`
  grid-area: content;
`;

export type NoteProps = {
  note?: string | null;
  period?: string | null;
  hidePeriod?: boolean;
};

const Note = ({ note, period, hidePeriod = false }: NoteProps) => {
  return (
    <NoteWrapper>
      <Content>
        {note && <Message>{note}</Message>}
        {note && period && !hidePeriod && (
          <VerticalSpacer space={theme.sizes.mini} />
        )}
        {period && !hidePeriod && (
          <Period>
            <FormattedMessage id="Period" defaultMessage="Zeitraum" />
            {`: ${period}`}
          </Period>
        )}
      </Content>
    </NoteWrapper>
  );
};

export default Note;
