import React, { useContext } from 'react';
import IsMobileContext from 'contexts/IsMobileContext';

import styled from 'styled-components';
import theme from 'styles/theme';
import VerticalSpacer from 'atoms/VerticalSpacer';

import ReactPlaceholder from 'react-placeholder';
import { TextRow, RoundShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css'; //for animation

const WrapperPLaceholder = styled.article`
  /* Browser compatibility should be fine (except for IE of course) */
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.05));
`;

const PlaceholderInfoWrapper = styled.section<any>`
  position: relative;
  display: grid;
  grid-template-areas:
    'icon . title . status-indicator'
    '. . description description status-indicator'
    '. . message . . ';
  /* That 19px serves as a spacer */
  /* We need the minmax(0, 1fr) here so the title does not blow out */
  grid-template-columns: auto 19px minmax(0, 1fr) 10px auto;

  align-items: center;

  padding: ${(p) => p.theme.sizes.small};

  border-radius: ${(p) => p.theme.borderRadii.normal};

  background-color: ${(p) => p.theme.colors.white};
`;

const DocumentPlaceholder = () => {
  const isMobile = useContext(IsMobileContext);

  return (
    <PlaceholderInfoWrapper>
      <RoundShape
        color={theme.colors.bambusBlue5}
        style={{
          width: isMobile ? 15 : 18,
          height: isMobile ? 15 : 18,
          gridArea: 'icon',
        }}
      />
      <TextRow
        color={theme.colors.bambusBlue5}
        style={{
          gridArea: 'title',
          width: isMobile ? 100 : 200,
          height: isMobile ? 12 : 14,
          borderRadius: theme.borderRadii.small,
        }}
      />
      {!isMobile && (
        <TextRow
          color={theme.colors.bambusBlue5}
          style={{
            gridArea: 'description',
            width: 150,
            height: 14,
            borderRadius: theme.borderRadii.small,
          }}
        />
      )}
      {!isMobile && (
        <TextRow
          color={theme.colors.bambusBlue5}
          style={{
            gridArea: 'status-indicator',
            width: 100,
            height: 14,
            borderRadius: theme.borderRadii.small,
          }}
        />
      )}
      <TextRow
        color={theme.colors.bambusBlue5}
        style={{
          gridArea: 'message',
          height: isMobile ? 12 : 14,
          borderRadius: theme.borderRadii.small,
        }}
      />
    </PlaceholderInfoWrapper>
  );
};

const documentCategoryPlaceholder = (
  <WrapperPLaceholder>
    <TextRow
      color={theme.colors.bambusBlue5}
      style={{ width: 200, height: 14, borderRadius: theme.borderRadii.small }}
    />
    <VerticalSpacer space={theme.sizes.medium} />
    <DocumentPlaceholder />
    <VerticalSpacer space={theme.sizes.medium} />
    <DocumentPlaceholder />
    <VerticalSpacer space={theme.sizes.medium} />
    <DocumentPlaceholder />
    <VerticalSpacer space={theme.sizes.medium} />
    <DocumentPlaceholder />
    <VerticalSpacer space={theme.sizes.medium} />
    <DocumentPlaceholder />
  </WrapperPLaceholder>
);

const DocumentCategoryPlaceholder = () => {
  return (
    <ReactPlaceholder
      showLoadingAnimation
      customPlaceholder={documentCategoryPlaceholder}
      ready={false}
      children={null}
    />
  );
};

export default DocumentCategoryPlaceholder;
