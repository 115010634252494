import { request } from './index';
import endpoints, {
  getDeleteDocumentFileURL,
  getDocumentDetailsURL,
  getDocumentFileUploadSignedURL,
  getConcatenationURL,
  getReorderURL,
  getDeleteAllDocumentFilesURL,
  getConfirmDocumentURL,
  getNotificationURL,
} from './endpoints';

import { DocumentStatus } from 'molecules/Document';

import OnboardingDetailsFormData from './OnboardingDetailsFormData';

type ChangePasswordArguments =
  | {
      password: string;
      repeatPassword: string;
      confirmationToken: string;
      currentPassword?: string;
    }
  | {
      password: string;
      repeatPassword: string;
      currentPassword: string;
      confirmationToken?: string;
    };
/**
 * In addition to the password and repeatPassword fields, it requires either:
 * 1. The current password
 * 2. A token
 */
export const changePassword = ({
  password,
  repeatPassword,
  currentPassword,
  confirmationToken,
}: ChangePasswordArguments) => {
  return request(endpoints.changePassword, 'POST', {
    new_password: password,
    repeat_new_password: repeatPassword,
    ...(confirmationToken && {
      confirmation_token: confirmationToken,
    }),
    ...(currentPassword && {
      current_password: currentPassword,
    }),
  });
};

export type GetDocumentsAPIResponseClusterChildDocument = {
  id: string;
  title: string;
  description?: string;
  association?: string;
  isOptional?: boolean;
  note?: string;
  validationMessage?: string;
  status: DocumentStatus;
  parent: Omit<GetDocumentsAPIResponseDocument, 'documents'>;
  [x: string]: any;
};

export type GetDocumentsAPIResponseDocument = {
  id: string;
  title: string;
  description?: string;
  association?: string;
  isOptional?: boolean;
  note?: string;
  validationMessage?: string;
  status: DocumentStatus;
  documents?: GetDocumentsAPIResponseClusterChildDocument[];
  [x: string]: any;
};

export type GetDocumentsAPIResponse = {
  groupTitle: string;
  documents: GetDocumentsAPIResponseDocument[];
};

export const getDocuments = (): Promise<GetDocumentsAPIResponse[]> => {
  return request(endpoints.documents, 'GET');
};

export type PageType = {
  index: number;
  smallPreview: string;
  fullPreview: string;
};

export type FileToSign = {
  id: number | string;
  index: number;
  name: string;
  url: string;
};

export type GetDocumentDetailsAPIResponse = {
  id: string;
  title: string | null;
  description: string | null;
  application: number | null;
  userFiles?: any[];
  filesForSigning: FileToSign[];
  status: DocumentStatus;
  association: string | null;
  availableAt: string | null;
  category: string | null;
  period: string | null;
  note: string | null;
  validationMessage: string | null;
  pages: PageType[];
  concatenatedFile: {
    id: number;
    name: string;
    url: string;
  };
  customerNote?: string;
  parent: Omit<GetDocumentsAPIResponseDocument, 'documents'>;
  [x: string]: any;
};

export const getDocumentDetails = ({
  documentId,
}: any): Promise<GetDocumentDetailsAPIResponse> => {
  return request(getDocumentDetailsURL(documentId), 'GET');
};

export const notifyFileForSigningDownloaded = ({
  documentId,
}: {
  documentId: string;
}) => {
  return request(getDocumentDetailsURL(documentId), 'POST', {
    downloaded: true,
  });
};

export type GetDocumentFilePresignedUploadURLParams = {
  documentId: string;
  name: string;
  type: string;
  size: number;
};

export const getDocumentFilePresignedUploadURL = ({
  documentId,
  name,
  type,
  size,
}: GetDocumentFilePresignedUploadURLParams) => {
  return request(getDocumentFileUploadSignedURL(documentId), 'POST', {
    name,
    type,
    size,
  });
};

export type DeleteDocumentFileParams = {
  documentId: string;
  fileId: string;
};

export const deleteDocumentFile = ({
  documentId,
  fileId,
}: DeleteDocumentFileParams) => {
  return request(getDeleteDocumentFileURL(documentId, fileId), 'DELETE');
};

export const requestConcatenation = ({
  documentId,
}: {
  documentId: string;
}) => {
  return request(getConcatenationURL(documentId), 'POST');
};

type GetConcatenationStatusAPIResponse = {
  status: string;
};

export const getConcatenationStatus = ({
  documentId,
}: {
  documentId: string;
}): Promise<GetConcatenationStatusAPIResponse> => {
  return request(getConcatenationURL(documentId), 'GET');
};

export const requestReordering = ({
  documentId,
  newOrder,
}: {
  documentId: string;
  newOrder: number[];
}) => {
  return request(getReorderURL(documentId), 'POST', { newOrder });
};

type GetReorderingStatusAPIResponse = {
  status: string;
};

export const getReorderingStatus = ({
  documentId,
}: {
  documentId: string;
}): Promise<GetReorderingStatusAPIResponse> => {
  return request(getReorderURL(documentId), 'GET');
};

/**
 * The below enums and respective mappings were taken from:
 * https://docs.google.com/spreadsheets/d/1-xjRjprD-mk7XohoPhr6ygOHg3oHWoIDNBV7Q2yMNqA/edit#gid=2031700128.
 */

export enum Salutation {
  Mr = 'mr',
  Ms = 'ms',
}

export enum FamilyStatus {
  Single = 'single',
  Married = 'married',
  Widowed = 'widowed',
  Divorced = 'divorced',
  Separated = 'separated',
  RegisteredCivilPartnership = 'registered_civil_partnership',
}

export enum EmploymentType {
  Employed = 'employed',
  Worker = 'worker',
  Unemployed = 'unemployed',
  Official = 'official',
  ParentalLeave = 'parental_leave',
  Freelancer = 'freelancer',
  MarginallyEmployed = 'marginally_employed',
  ManagingPartner = 'managing_partner',
  HouseHusbandOrWife = 'house_husband_or_wife',
  PersonOfIndependentMeans = 'person_of_independent_means',
  Pensioner = 'pensioner',
  SelfEmployedOnTheBalanceSheet = 'self_employed_on_the_balance_sheet',
  SelfEmployedNotOnTheBalanceSheet = 'self_employed_not_on_the_balance_sheet',
  Soldier = 'soldier',
  Student = 'student',
  Executive = 'executive',
}

export type GetUserDetailsAPIResponse = {
  email?: string;
  firstName?: string;
  lastName?: string;
  salutation?: string;
  title?: string;
  birthdate?: string;
  nationality?: string;
  nationality2?: string;
  familyStatus?: FamilyStatus;
  employmentType?: EmploymentType;
  phoneNumber?: string;
  streetName?: string;
  houseNumber?: string;
  addressLine2?: string;
  country?: string;
  city?: string;
  zipcode?: string;
  cockpitEnabled?: boolean;
  onboardingComplete?: boolean;
};

export const getUserDetails = (): Promise<GetUserDetailsAPIResponse> => {
  return request(endpoints.userDetails, 'GET');
};

export const deleteAllDocumentFiles = ({
  documentId,
}: {
  documentId: string;
}): Promise<any> => {
  return request(getDeleteAllDocumentFilesURL(documentId), 'DELETE');
};

export const confirmDocument = ({
  documentId,
  note,
}: {
  documentId: string;
  note: string | undefined | null;
}) => {
  return request(
    getConfirmDocumentURL(documentId),
    'POST',
    note === null || note === undefined ? {} : { note }
  );
};

// -----------------------------------------
// Notifications related endpoints and interfaces START

export type NotificationType =
  | 'added'
  | 'available_download'
  | 'available_download_and_sign'
  | 'accepted'
  | 'declined';

export interface Notification {
  id: string;
  createdAt: string;
  state: 'unseen' | 'seen' | 'archived';
  notificationType: NotificationType;
  application: number;
  document: string;
  documentTitle: string;
  clusterId?: string;
  clusterTitle?: string;
}

export interface ClusteredNotification {
  clusterName: NotificationType;
  clusterList: Notification[];
}

export interface GetNotificationsAPIResponse {
  notificationsList: (Notification | ClusteredNotification)[];
}

export const getNotifications = (): Promise<
  (Notification | ClusteredNotification)[]
> => {
  return request(endpoints.notifications, 'GET').then(
    (result: GetNotificationsAPIResponse) => result.notificationsList
  );
};

export const markNotificationAsSeen = (notificationId: string) => {
  return request(getNotificationURL(notificationId), 'POST', {
    markSeen: true,
  });
};

export const markAllNotificationsAsSeen = () => {
  return request(endpoints.notifications, 'POST', {
    markAllSeen: true,
  });
};

export const archiveNotification = (notificationId: string) => {
  return request(getNotificationURL(notificationId), 'POST', {
    archive: true,
  });
};

export const archiveNotifications = (notificationIds: string[]) => {
  return request(endpoints.notifications, 'POST', {
    archiveCluster: true,
    clusterList: notificationIds.map((notificationId) => ({
      id: notificationId,
    })),
  });
};

// -----------------------------------------

export const getOnboardingDetails = (): Promise<OnboardingDetailsFormData> => {
  return request(endpoints.onboardingDetails, 'GET', null, true);
};
