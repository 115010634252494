import React, { useContext } from 'react';
import styled from 'styled-components';

import DesktopRealtimeNotification from 'atoms/DesktopRealtimeNotification';

import NotificationsContext from 'contexts/NotificationsContext';

import {
  isNotificationClustered,
  getNotificationTitleBasedOnType,
  getNotificationDescriptionBasedOnType,
  getClusterNotificationTitleBasedOnType,
  getClusterNotificationDescriptionBasedOnType,
  getUsableDocumentID,
  getUsableDocumentTitle,
} from 'models/Notifications';

const DesktopNotificationsHolder = styled.div`
  position: fixed;
  padding-top: 20px;
  right: 20px;

  max-height: 100vh;
  overflow-y: auto;

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  z-index: 100;
`;

const DesktopGlobalNotifications = () => {
  const { newNotifications, onNotificationClick, onNotificationDismiss } =
    useContext(NotificationsContext);

  return (
    <DesktopNotificationsHolder>
      {newNotifications.map((notification) => {
        if (isNotificationClustered(notification)) {
          return (
            // building a key that has all the notification IDs
            <DesktopRealtimeNotification
              key={notification.clusterList.reduce(
                (idString, notification) => `${idString}${notification.id}`,
                ''
              )}
              notificationIds={notification.clusterList.map(
                (notification) => notification.id
              )}
              title={getClusterNotificationTitleBasedOnType(
                notification.clusterName
              )}
              description={getClusterNotificationDescriptionBasedOnType(
                notification.clusterName,
                notification.clusterList[0]
                  ? getUsableDocumentTitle(notification.clusterList[0])
                  : '',
                notification.clusterList.length - 1
              )}
              notificationType={notification.clusterName}
              documentIds={notification.clusterList.reduce<string[]>(
                (documentIdsArray, notification) => {
                  return [
                    ...documentIdsArray,
                    getUsableDocumentID(notification),
                  ];
                },
                []
              )}
              onDismiss={onNotificationDismiss}
              onNotificationClick={onNotificationClick}
            />
          );
        } else {
          return (
            <DesktopRealtimeNotification
              key={notification.id}
              notificationIds={[notification.id]}
              title={getNotificationTitleBasedOnType(
                notification.notificationType
              )}
              description={getNotificationDescriptionBasedOnType(
                notification.notificationType,
                getUsableDocumentTitle(notification)
              )}
              notificationType={notification.notificationType}
              documentIds={[getUsableDocumentID(notification)]}
              onDismiss={onNotificationDismiss}
              onNotificationClick={onNotificationClick}
            />
          );
        }
      })}
    </DesktopNotificationsHolder>
  );
};

export default DesktopGlobalNotifications;
