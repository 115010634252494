import React from 'react';
import styled from 'styled-components';

type StyledHeadingProps = {
  size?: number;
  color?: string;
  weight?: number;
};

export type HeadingProps = StyledHeadingProps & {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const StyledHeading = styled.h1<StyledHeadingProps>`
  /* The larger the size the smaller the text */
  font-size: calc(${(p) => p.theme.fontSizes.heading} - ${(p) => p.size}px);
  font-weight: ${({ weight, theme }) => weight || theme.fontWeights.heading};

  line-height: ${({ theme }) => theme.lineHeights.heading};

  /* Use the theme color if no explicit color is set */
  color: ${(p) => p.color || p.theme.colors.actionBlue};
`;

/**
 * Heading component, adapted to be used in bambus-components.
 * As the "size" prop grows, the text size decreases.
 */
const Heading = ({
  children,
  color,
  tag = 'h1',
  size = 0,
  weight,
  ...rest
}: React.PropsWithChildren<HeadingProps>) => {
  return (
    <StyledHeading as={tag} size={size} color={color} weight={weight} {...rest}>
      {children}
    </StyledHeading>
  );
};

export default Heading;
