import React from 'react';
import styled from 'styled-components';
import Modal from 'components/Modal';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndexes.mobileReorderHeader};
`;

const MobileReorderWrapper = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Modal>
      <Wrapper>{children}</Wrapper>
    </Modal>
  );
};

export default MobileReorderWrapper;
