import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import gsap from 'gsap';
import { X as ExitIcon, Check } from 'react-feather';
import { Button } from 'bambus-ui-components';
import wait from 'utils/WaitAsPromised';
import theme from 'styles/theme';
import IsMobileContext from 'contexts/IsMobileContext';

import Mobile from 'atoms/Mobile';
import CaptionText from 'atoms/CaptionText';
import VerticalSpacer from 'atoms/VerticalSpacer';

import LoadingIndicator from 'molecules/LoadingIndicator';

import Page, { DragHandle } from './Page';

/**
 * 200 ms is the recommended press delay for touch devices
 */
const MOBILE_PRESS_DELAY: number = 200;

const DesktopWrapper = styled.div`
  position: relative;
  max-width: 60vw;
  width: 64rem;
  // Create new stacking context for the pages
  isolation: isolate;

  background-color: ${({ theme }) => theme.colors.bambusBlue2dot5};
`;

const MobileWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.bambusBlue2dot5};
`;

const MobileHeaderStyles = css`
  ${() => `
    position: sticky;
    top: 0;
    z-index: 99;
  `}
`;

const DesktopHeaderStyles = css`
  ${() => `
    border-radius: ${({ theme }: { theme: DefaultTheme }) =>
      theme.borderRadii.small};
  `}
`;

type HeaderProps = {
  isMobile: boolean;
};

const Header = styled.header<HeaderProps>`
  position: relative;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.sizes.extraSmall};
  background-color: ${({ theme }) => theme.colors.bambusBlue10};
  user-select: none;

  ${({ isMobile }) => (isMobile ? MobileHeaderStyles : DesktopHeaderStyles)};
`;

const ExitButton = styled.button`
  position: absolute;
  right: ${({ theme }) => theme.sizes.mini};
  background-color: transparent;
  top: 0;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
`;

type MainWrapperProps = {
  isMobile: boolean;
};

const DesktopMainStyles = css`
  ${({ theme }) => `
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 30px;

    //This is a fallback to the "min" variant.
    max-height: 60rem;
    
    // Using the "min" CSS function here to be sure that the 
    // reorderer does not take up a lot of screen
    // real estate that then has to be scrolled.
    max-height: min(60rem, 75vh);

    overflow: auto;
    border: 1px dashed ${theme.colors.bambusBlue50};
    border-radius: ${theme.borderRadii.small};
  `}
`;

const MobileMainStyles = css`
  ${() => `
    padding-top: 4rem;
    grid-template-columns: 1fr;
    gap: 10px;
    justify-items: center;
  `}
`;

const Main = styled.main<MainWrapperProps>`
  /**
    Overflow Anchor OFF is needed here in order to not jump to the top of the page
    in some page reorganization cases. 
  */
  overflow-anchor: none;
  display: grid;
  padding: ${({ theme }) => theme.sizes.medium};

  ${({ isMobile }) => (isMobile ? MobileMainStyles : DesktopMainStyles)}
`;

const ConfirmButtonHolder = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
  user-select: none;
`;

export type DocumentPage = {
  index: number;
  smallPreview: string;
  fullPreview: string;
};

/**
 * We need a separate Handle, so we can have buttons with their
 * own onClick events in the Page component.
 */
const Handle = SortableHandle(DragHandle);

const SortableDocumentPage = SortableElement((props: any) => (
  <Page dragHandle={Handle} {...props} />
));

type SortableDocumentsProps = {
  documentPages: DocumentPage[];
  activePageIndex: number | null;
  currentlyDraggedPageIndex: number | null;
  onMoveLeft: (pageIndex: number) => void;
  onMoveRight: (pageIndex: number) => void;
  containerRef: any;
  onPageClickGenerator: (index: number) => () => void;
  onImageLoadFinished: () => void;
};

const SortableDocuments = SortableContainer(
  ({
    documentPages,
    activePageIndex,
    currentlyDraggedPageIndex,
    onMoveLeft,
    onMoveRight,
    containerRef,
    isMobile,
    onPageClickGenerator,
    onImageLoadFinished,
  }: SortableDocumentsProps & MainWrapperProps) => {
    return (
      <Main ref={containerRef} isMobile={isMobile}>
        {documentPages.map(({ smallPreview, fullPreview }, index) => {
          return (
            <SortableDocumentPage
              key={smallPreview}
              index={index}
              pageIndex={index}
              imgURL={smallPreview}
              highResolutionImgURL={fullPreview || smallPreview}
              active={activePageIndex === index}
              isBeforeActive={index < (activePageIndex ?? 0)}
              isAfterActive={
                activePageIndex != null ? index > activePageIndex : false
              }
              isCurrentlyDragged={currentlyDraggedPageIndex === index}
              hideLeftArrow={index === 0}
              hideRightArrow={index === documentPages.length - 1}
              onMoveLeft={onMoveLeft}
              onMoveRight={onMoveRight}
              onClick={onPageClickGenerator(index)}
              onImageLoadFinished={onImageLoadFinished}
            />
          );
        })}
      </Main>
    );
  }
);

type OnIndexChangeParameters = {
  oldIndex: number;
  newIndex: number;
};

export type OnIndexChangeCallback = (params: OnIndexChangeParameters) => void;

export type DocumentReordererProps = {
  documentPages: DocumentPage[];
  onIndexChange: OnIndexChangeCallback;
  onMobileReordererClose?: () => void;
  onMobileReordererConfirm?: () => void;
  showLoadingIndicator?: boolean;
};

type VisuallySwapPagesParams = {
  pageIndex1: number;
  pageIndex2: number;
};

/**
 * Animates 2 pages and visually swaps them.
 * Returns a Promise that resolves when the animation ends.
 */
const visuallySwapPages = ({
  pageIndex1,
  pageIndex2,
}: VisuallySwapPagesParams): Promise<void> => {
  return new Promise((resolve, reject) => {
    const element1 = document.querySelector(
      `[data-page-index="${pageIndex1}"]`
    );
    const element2 = document.querySelector(
      `[data-page-index="${pageIndex2}"]`
    );
    if (element1 && element2) {
      const element1BoundingClientRect = element1.getBoundingClientRect();
      const element2BoundingClientRect = element2.getBoundingClientRect();

      const element1XCoord = element1BoundingClientRect.x;
      const element2XCoord = element2BoundingClientRect.x;

      const distanceX = Math.abs(element1XCoord - element2XCoord);
      const [elementOnTheLeft, elementOnTheRight] =
        element1XCoord < element2XCoord
          ? [element1, element2]
          : [element2, element1];

      const areElementsOnTheSameLine =
        element1BoundingClientRect.y === element2BoundingClientRect.y;

      /**
       * If elements are on the same line, we must move them on the X axis only.
       * If not, we have to also move them on the Y axis (see "else" branch).
       */
      if (areElementsOnTheSameLine) {
        const timeline = gsap.timeline({
          onComplete: () => {
            //To reset the animation
            timeline.pause(0);
            //Resolve the Promise => the animation has finished
            resolve();
          },
        });
        timeline
          .to(
            elementOnTheRight,
            {
              x: -distanceX,
              duration: 0.3,
            },
            0
          )
          .to(
            elementOnTheLeft,
            {
              x: distanceX,
              duration: 0.3,
            },
            0
          );
      } else {
        const element1YCoord = element1.getBoundingClientRect().y;
        const element2YCoord = element2.getBoundingClientRect().y;
        const distanceY = Math.abs(element1YCoord - element2YCoord);

        const [elementAbove, elementBelow] =
          element2YCoord > element1YCoord
            ? [element1, element2]
            : [element2, element1];

        const timeline = gsap.timeline({
          onComplete: () => {
            //To reset the animation
            timeline.pause(0);
            //Resolve the Promise => the animation has finished
            resolve();
          },
        });

        timeline
          .to(
            elementOnTheRight,
            {
              x: -distanceX,
              duration: 0.3,
            },
            0
          )
          .to(
            elementOnTheLeft,
            {
              x: distanceX,
              duration: 0.3,
            },
            0
          )
          .to(
            elementBelow,
            {
              y: -distanceY,
              duration: 0.3,
            },
            0
          )
          .to(
            elementAbove,
            {
              y: distanceY,
              duration: 0.3,
            },
            0
          );
      }
    } else {
      reject('Elements not found.');
    }
  });
};

const DocumentReorderer = ({
  documentPages = [],
  onIndexChange,
  onMobileReordererConfirm,
  showLoadingIndicator,
}: DocumentReordererProps) => {
  const [activePageIndex, setActivePageIndex] = useState<number | null>(0);
  const [currentlyDraggedPageIndex, setCurrentlyDraggedPageIndex] = useState<
    number | null
  >(null);
  const [numberOfLoadedImages, setNumberOfLoadedImages] = useState<number>(0);
  const [havePageImagesLoaded, setHavePageImagesLoaded] =
    useState<boolean>(false);

  const isMobile = useContext(IsMobileContext);

  useEffect(() => {}, []);

  const containerRef = useRef<HTMLElement>();

  const onSortStart = useCallback(() => {
    document.body.style.cursor = 'grabbing';
  }, []);

  const onBeforeSortStart = useCallback(({ index }) => {
    /**
     * The currently dragged element index has to be set here,
     * before the sorting happens, so that the sortable
     * elements have time to rerender themselves
     * as the sorting library creates a visual copy of the element.
     */
    setCurrentlyDraggedPageIndex(index);
    setActivePageIndex(null);
  }, []);

  /**
   * This only gets executed on mobile.
   * Used for tapping on a page and selecting it.
   *
   * On Desktop this happens only on "onSortEnd" as there is no
   * sort start delay (see "pressDelay" on the SortableDocuments container),
   * thus no click event is fired but the "onSortEnd" handler is always fired
   * (even when there was no actual "sorting" done but just a click).
   */
  const getOnPageClickCallback = useCallback((index) => {
    return () => {
      setActivePageIndex(index);
    };
  }, []);

  const onPageImageLoadCallback = useCallback(() => {
    setNumberOfLoadedImages((number) => number + 1);
  }, []);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setActivePageIndex(newIndex);
      document.body.style.cursor = 'default';
      setCurrentlyDraggedPageIndex(null);

      if (oldIndex !== newIndex) {
        onIndexChange({ oldIndex, newIndex });
      }
    },
    [onIndexChange]
  );

  const onPageMoveLeft = useCallback(
    async (pageIndex) => {
      /**
       * On Mobile we have scaling for the currently selected page.
       * Aniimating (swapping) 2 pages does not work when one is scaled.
       * Thus, we first have to deselect the page and wait until the
       * downscale animation happens. Only swap afterwards.
       */
      if (isMobile) {
        setActivePageIndex(null);
        //We wait for the scale down animation to happen
        await wait(400);
      }
      try {
        await visuallySwapPages({
          pageIndex1: pageIndex,
          pageIndex2: pageIndex - 1,
        });
        onIndexChange({ oldIndex: pageIndex, newIndex: pageIndex - 1 });
        setActivePageIndex(pageIndex - 1);
      } catch (error) {
        console.error(
          `Something went wrong when reordering manually: ${error} `
        );
      }
    },
    [onIndexChange, isMobile]
  );

  const onPageMoveRight = useCallback(
    async (pageIndex) => {
      /**
       * On Mobile we have scaling for the currently selected page.
       * Aniimating (swapping) 2 pages does not work when one is scaled.
       * Thus, we first have to deselect the page and wait until the
       * downscale animation happens. Only swap afterwards.
       */
      if (isMobile) {
        setActivePageIndex(null);
        //We wait for the scale down animation to happen
        await wait(400);
      }
      try {
        await visuallySwapPages({
          pageIndex1: pageIndex,
          pageIndex2: pageIndex + 1,
        });
        onIndexChange({ oldIndex: pageIndex, newIndex: pageIndex + 1 });
        setActivePageIndex(pageIndex + 1);
      } catch (error) {
        console.error(
          `Something went wrong when reordering manually: ${error} `
        );
      }
    },
    [onIndexChange, isMobile]
  );

  useEffect(() => {
    if (numberOfLoadedImages === documentPages.length) {
      setHavePageImagesLoaded(true);
    }
  }, [numberOfLoadedImages, documentPages]);

  const Wrapper = isMobile ? MobileWrapper : DesktopWrapper;

  return (
    <Wrapper>
      <Header isMobile={isMobile}>
        <CaptionText as="h1">
          <FormattedMessage
            id="Pages.Document.Reorder.ArrangePages"
            defaultMessage="Seiten anordnen"
          />
        </CaptionText>
        <Mobile>
          <ExitButton onClick={onMobileReordererConfirm}>
            <ExitIcon color={theme.colors.bambusBlue50} />
          </ExitButton>
        </Mobile>
      </Header>
      <SortableDocuments
        containerRef={containerRef}
        isMobile={isMobile}
        useDragHandle
        axis={isMobile ? 'y' : 'xy'}
        documentPages={documentPages}
        activePageIndex={activePageIndex}
        currentlyDraggedPageIndex={currentlyDraggedPageIndex}
        onSortStart={onSortStart}
        onSortEnd={onSortEnd}
        updateBeforeSortStart={onBeforeSortStart}
        onMoveLeft={onPageMoveLeft}
        onMoveRight={onPageMoveRight}
        pressDelay={isMobile ? MOBILE_PRESS_DELAY : 0}
        onPageClickGenerator={getOnPageClickCallback}
        onImageLoadFinished={onPageImageLoadCallback}
      />
      {isMobile && (
        <>
          <VerticalSpacer space={'6rem'} />
          <ConfirmButtonHolder>
            <Button
              onClick={onMobileReordererConfirm}
              icon={<Check />}
              normal
              // @ts-ignore
              style={{
                backgroundColor: theme.colors.green,
                minWidth: '80vw',
              }}
            >
              <FormattedMessage id="Confirm" defaultMessage="Bestätigen" />
            </Button>
          </ConfirmButtonHolder>
        </>
      )}
      {(!havePageImagesLoaded || showLoadingIndicator) && <LoadingIndicator />}
    </Wrapper>
  );
};

export default DocumentReorderer;
